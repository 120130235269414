import type { Ref } from 'vue';
import type { FontPickerProps } from '../types';
import type { TypographyFontOption, TypographyFamily } from '@gem/common';

import { computed, inject } from 'vue';
import { FONT_CATEGORIES } from '../constants';
import { createFontList, mergeFonts } from '../helpers';

type Props = {
  props: FontPickerProps;
  searchKeyword: Ref<string>;
  filteredFonts: Ref<TypographyFontOption[]>;
  filteredUploadFonts: Ref<TypographyFontOption[]>;
};

export const useGetMixedFonts = ({ props, searchKeyword, filteredFonts, filteredUploadFonts }: Props) => {
  const pageMetaRecentlyValueParse = inject<Ref<TypographyFamily>>('pageMetaRecentlyValueParse');

  const isBunnySourceFont = computed(() => props?.fontType === 'bunny');

  const listThemeFont = computed(() => filteredFonts.value.filter((font) => font.type === 'theme'));
  const listBunnyFont = computed(() => filteredFonts.value.filter((font) => font.type === 'bunny' || !font.type));
  const listGoogleFont = computed(() => filteredFonts.value.filter((font) => font.type === 'google' || !font.type));

  const listCustomFont = computed(() => {
    const customFonts = filteredFonts.value.filter((font) => font.type === 'custom') ?? [];
    const uploadFonts = filteredUploadFonts.value || [];
    return mergeFonts(uploadFonts, customFonts);
  });

  const listRecentFont = computed(() => {
    const value = pageMetaRecentlyValueParse?.value;
    return Array.isArray(value) ? value : ([] as TypographyFamily[]);
  });

  const mixedFonts = computed(() => {
    const recentFonts =
      searchKeyword.value.length > 0 ? [] : createFontList(listRecentFont.value, FONT_CATEGORIES.RECENTLY, true);

    const customFonts = createFontList(listCustomFont.value, FONT_CATEGORIES.CUSTOM);

    const themeFonts = createFontList(listThemeFont.value, FONT_CATEGORIES.THEME);

    const optionsFonts = isBunnySourceFont.value
      ? createFontList(listBunnyFont.value, FONT_CATEGORIES.BUNNY)
      : createFontList(listGoogleFont.value, FONT_CATEGORIES.GOOGLE);

    return [...recentFonts, ...customFonts, ...themeFonts, ...optionsFonts];
  });

  return mixedFonts;
};
