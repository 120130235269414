import { defineStore } from 'pinia';

export const useErrorCollectorStore = defineStore('error-collector', {
  state: () => ({
    errorCount: 0,
    analyticErrorCount: 0, // Track non-network error
  }),
  actions: {
    addError(error: unknown | Error | string) {
      this.errorCount++;
      if (typeof error === 'string' && error.includes('Network request failed')) {
        this.analyticErrorCount++;
      }
      if (error instanceof Error && error.message.includes('Network request failed')) {
        this.analyticErrorCount++;
      }
      if (JSON.stringify(error).includes('Network request failed')) {
        this.analyticErrorCount++;
      }
    },
    clearError() {
      this.errorCount = 0;
      this.analyticErrorCount = 0;
    },
    hasError() {
      return this.errorCount;
    },
    hasAnalyticError() {
      return this.analyticErrorCount;
    },
  },
});
