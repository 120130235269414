<script setup lang="ts">
import { SettingLayout, PresetSettingLayout } from '@gem/element-setting-ui';
import type { LayoutSelectorType } from '@gem/element-setting-ui';
import LayoutItems from './components/LayoutItems.vue';
import { useLayoutSelector } from './hooks/useLayoutSelector';
const props = withDefaults(defineProps<LayoutSelectorType>(), {
  itemPerRow: 3,
});

const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
}>();

const { handleChangeLayout, presetContent, options } = useLayoutSelector(props, emit);
</script>

<template>
  <PresetSettingLayout
    :popover-label="{ en: popoverLabel || 'Layout' }"
    :show-label="false"
    :icon="presetContent.icon"
    :content-display="presetContent.content">
    <template v-if="groups?.length">
      <div class="flex flex-col gap-16">
        <template v-for="(group, index) in groups" :key="index">
          <SettingLayout :label="{ en: group.label }" layout="vertical" label-variant="secondary">
            <template #control>
              <LayoutItems
                :item-per-row="itemPerRow"
                :options="group.options"
                :value="value"
                @change-layout="handleChangeLayout" />
            </template>
          </SettingLayout>
        </template>
      </div>
    </template>
    <template v-if="options?.length && !groups?.length">
      <LayoutItems :item-per-row="itemPerRow" :options="options" :value="value" @change-layout="handleChangeLayout" />
    </template>
  </PresetSettingLayout>
</template>
