<script lang="ts" setup>
import type { ColorType } from '@gem/common';
import type { THEME_DARK, THEME_LIGHT } from '../const';
import { GButton } from '@gem/uikit-v2';
import { lightenDarkenColor } from '@gem/common';
import { useInputColor } from '../hooks/useInputColor';
import InputText from '../../inputs/InputText.vue';

const props = defineProps<{
  theme?: typeof THEME_DARK | typeof THEME_LIGHT;
  value?: ColorType | string;
  showTransparentColor: boolean;
  openColorPicker?: boolean;
}>();

const emits = defineEmits<{
  (e: 'toggleColorPicker'): void;
  (e: 'change', color: ColorType | string): void;
  (e: 'onChange', color: ColorType | string): void;
}>();

const {
  color,
  colorInput,
  opacityInput,
  noHashtagHexColor,
  browserSupportEyeDropper,
  handleOnChangeHex,
  handleOnChangeAlpha,
  handleEmit,
  onOpenEyeDropper,
} = useInputColor(props, emits);
</script>

<template>
  <div class="flex gap-8">
    <GButton type="secondary" size="medium" class="h-36 !w-36 rounded-xl !p-0" @click="emits('toggleColorPicker')">
      <div
        class="content !h-20 !w-20"
        :class="{
          'hover:border-light-450': showTransparentColor || color.a === 0,
        }">
        <div
          class="gemx-transparent-bg relative h-20 w-20 overflow-hidden rounded-[6px] border"
          :style="{
            'border-color': lightenDarkenColor(color.getColor()),
          }">
          <div
            class="picked-color !h-20 !w-20"
            :style="{
              backgroundColor: color.getColor(),
            }" />
        </div>
      </div>
    </GButton>
    <div class="relative flex flex-1">
      <InputText
        id="color-input"
        ref="colorInput"
        :value="noHashtagHexColor"
        placeholder="Eg: FFFFFF"
        no-border="right"
        :clear-button="false"
        @control-on-change="handleOnChangeHex"
        @control-change="() => handleEmit('change')" />
      <div class="border-dark-200 border-r"></div>
      <InputText
        id="opacity-input"
        ref="opacityInput"
        :value="Math.round(color.a * 100)"
        :no-border="'left'"
        max-width="55px"
        :show-default-unit="true"
        :default-unit="'%'"
        :units="['%']"
        :min="0"
        :max="100"
        :force-emit="true"
        type="number"
        @control-on-change="handleOnChangeAlpha"
        @control-change="() => handleEmit('change')" />
      <span class="text-12 text-light-200 absolute right-8 top-[10px]">%</span>
    </div>
    <GButton
      v-if="browserSupportEyeDropper"
      only-icon="polaris-eye-dropper"
      type="secondary"
      size="medium"
      class="w-36 rounded-xl p-8"
      @click.stop="onOpenEyeDropper" />
  </div>
</template>

<style scoped>
.gemx-transparent-bg {
  background-image: url('/images/bg-transparent.png');
  background-position: center;
  background-size: cover;
}
</style>
