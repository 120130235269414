<script lang="ts" setup>
import { GDropdown } from '@gem/uikit-v2';
import type { DropdownProps } from './types';

const props = withDefaults(defineProps<DropdownProps>(), {
  floating: true,
  fixWidth: false,
  showSelectedIcon: false,
});

const emit = defineEmits<{
  (e: 'controlChange', value: string): void;
}>();

const handleSelectDropdownItem = (value: string) => {
  emit('controlChange', value);
};
</script>

<template>
  <div>
    <GDropdown
      v-if="options?.length || emptyMessage"
      v-bind="props"
      :id="id"
      :value="value !== undefined ? [value] : undefined"
      :items="options || []"
      :empty-message="emptyMessage"
      :overlay-container="'#sidebar'"
      :show-selected-icon="showSelectedIcon"
      @select="(value) => handleSelectDropdownItem(value[0])" />
  </div>
</template>
