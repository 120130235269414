<script setup lang="ts">
import type { PaddingProps, PaddingType } from '@gem/element-setting-ui';
import { InputComboLayout, SettingLayout } from '@gem/element-setting-ui';
import { GButton } from '@gem/uikit-v2';
import InputUnitWithDropdown from '../inputs/InputUnitWithDropdown.vue';
import { usePaddingSetting } from './hooks/usePaddingSetting';

const props = defineProps<PaddingProps>();

const emit = defineEmits<{
  (e: 'controlChange', value?: PaddingType): void;
  (e: 'controlOnChange', value?: PaddingType): void;
  (e: 'change-link', value: boolean): void;
  (e: 'edit-global-style'): void;
}>();

const {
  val,
  isLinked,
  isFocus,
  isZeroValue,
  displayComboValue,
  positionOptions,
  dropdownOptions,
  onChangeInput,
  changeInput,
  handleSetDefaultValue,
  handleLinked,
  handleClear,
  isDisableInput,
  getNumberValue,
} = usePaddingSetting(props, emit);
</script>
<template>
  <div>
    <InputComboLayout
      :id="id"
      placeholder="Add..."
      :is-parent="true"
      :label="{ en: 'Padding' }"
      :show-label="false"
      :is-full-width="true"
      :content-display="displayComboValue"
      :combo-icon="val?.top && !isZeroValue ? 'gp-padding' : undefined"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleSetDefaultValue"
      @clear="handleClear">
      <SettingLayout layout="vertical">
        <template #control>
          <div class="flex w-full flex-col">
            <div class="relative grid w-full select-none grid-cols-2 gap-y-8">
              <div
                v-for="(item, key) in positionOptions"
                :key="key"
                :class="{ 'col-span-2': ['top', 'bottom'].includes(key) }">
                <div
                  class="flex"
                  :class="{
                    'justify-center': ['top', 'bottom'].includes(key),
                    'justify-end': key === 'right',
                  }">
                  <div class="w-[84px]">
                    <InputUnitWithDropdown
                      :id="key"
                      :value="isLinked ? getNumberValue(val?.['top']) : getNumberValue(val?.[key])"
                      :display-options="dropdownOptions"
                      :disable="isDisableInput(key)"
                      :active="isFocus && key === 'top'"
                      :units="['px', '%']"
                      align="center"
                      fallback-value="0px"
                      :dropdown-more-action="{
                        label: 'Edit Global Style',
                        action: () => emit('edit-global-style'),
                      }"
                      dropdown-button-size="36-20"
                      @control-change="(value) => changeInput(key, value)"
                      @control-on-change="(value) => onChangeInput(key, value)" />
                  </div>
                </div>
              </div>
              <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <GButton
                  type="ghost"
                  :active="isLinked"
                  size="normal"
                  :only-icon="isLinked ? 'gp-padding-linked' : 'gp-padding-separate'"
                  @click="handleLinked" />
              </div>
            </div>
          </div>
        </template>
      </SettingLayout>
    </InputComboLayout>
  </div>
</template>
