import { ref } from 'vue';
import { useItemManagementContext } from './context/useItemManagementContext';
import type { ItemProps } from '../types';

export const useItemInteraction = (props: ItemProps) => {
  const hasHover = ref(false);

  const { actions } = useItemManagementContext();

  const handleHoverItem = () => {
    if (hasHover.value) return;
    hasHover.value = true;

    if (!props.index) return;
    actions.handleHover(props.index);
  };

  const handleLeaveItem = () => {
    if (!hasHover.value) return;
    hasHover.value = false;
  };

  const handleClickItem = () => {
    if (!props.index) return;
    actions.handleClick(props.index);
  };

  const handleSlideItemToIndex = () => {
    if (props.tag !== 'CarouselItem') return;
    actions.handleSlideToIndex(Number(props.index));
  };

  const handleActivateItem = () => {
    if (!props.index || !props.isExpand) return;
    actions.handleActivate(props.index);
  };

  const handlePreventDragItem = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return {
    hasHover,
    handleClickItem,
    handleHoverItem,
    handleLeaveItem,
    handleActivateItem,
    handlePreventDragItem,
    handleSlideItemToIndex,
  };
};
