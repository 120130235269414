import type { ControlInputTextTypes } from '@gem/element-setting-ui';
import { ref } from 'vue';

export const useInputText = (props: ControlInputTextTypes, emit: any) => {
  const inputRef = ref<any>();
  const previousValue = ref(props.value ? props.value : '');

  const handleEmit = (type: 'change' | 'onChange', value?: string) => {
    const trimmedValue = value?.trim();
    if (!props?.forceEmit && trimmedValue !== '' && trimmedValue === previousValue.value) return;

    if (type === 'onChange') {
      emit('controlOnChange', trimmedValue);
    } else if (type === 'change') {
      previousValue.value = value;
      emit('controlChange', trimmedValue);
    }
  };

  const updateValue = (value: string) => inputRef.value?.updateValue(value);

  const modifyValue = (value: string) => {
    if (value === '') return props.fallbackValue || '';
    return value;
  };

  const handleControlChange = (value: string) => {
    handleEmit('change', modifyValue(value));
  };

  const handleControlOnChange = (value: string) => {
    handleEmit('onChange', value);
  };

  return { inputRef, updateValue, handleControlChange, handleControlOnChange };
};
