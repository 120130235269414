import { watch, computed } from 'vue';
import { useVirtualList } from '@vueuse/core';
import type { FontFamilyType, TypographyFamily } from '@gem/control';
import { getFontLabel, getThemeFontVariable, isFontMatch } from '../helpers';
import type { FontListItemProps, FontListProps, FontListEmits, FontListItemType } from '../types';
import { FONT_CATEGORIES } from '../constants';

const FONT_SOURCES = ['google', 'bunny'];

export const useFontList = (props: FontListProps, emit: FontListEmits) => {
  const mixedFonts = computed(() => props.fonts);

  const {
    list: fontList,
    containerProps,
    wrapperProps,
  } = useVirtualList(mixedFonts, {
    overscan: 10,
    itemHeight: 36,
  });

  const getFontListHeaderLabel = (label?: string) => {
    if (!label) return '';

    if (label === FONT_CATEGORIES.CUSTOM) return 'Uploaded';
    if (label === FONT_CATEGORIES.BUNNY) return 'Bunny fonts';
    if (label === FONT_CATEGORIES.THEME) return 'Theme fonts';
    if (label === FONT_CATEGORIES.GOOGLE) return 'Google fonts';

    return label;
  };

  const getFontListItemProps = (font: FontListItemType): FontListItemProps => {
    const themeFontVariable = handleGetThemeFontVariable(font.value, font.type);
    const isChecked = handleCheckSelectedFont({ value: font.value, type: font.type });
    const label = FONT_SOURCES.includes(font.type) && !font.isRecently ? font.label : handleGetLabel(font.value);

    const baseProps = {
      label,
      isChecked,
      font: font.value,
      themeFontVariable,
    };

    if (font.type === 'custom') {
      return {
        ...baseProps,
        hasDelete: !font?.isLoading,
        isLoading: !!font?.isLoading,
        isDisabledDelete: checkDisabledDelete(font.value),
      };
    }

    return baseProps;
  };

  const loadGoogleFonts = () => {
    const fonts = fontList.value;

    if (!fonts?.length) return;

    for (const font of fonts) {
      const { type, value, familyBackupValue } = font.data;

      if (!FONT_SOURCES.includes(type)) continue;

      const fontFamily = type === 'bunny' ? familyBackupValue ?? '' : value;

      emit('load-source-font', fontFamily, type);
    }
  };

  const handleGetLabel = (value?: string) => getFontLabel(value, props.themeFonts);

  const handleGetThemeFontVariable = (value?: string, type?: string): string | undefined => {
    return getThemeFontVariable(value, type, props.themeFonts);
  };

  const handleCheckSelectedFont = (font: TypographyFamily): boolean => {
    return isFontMatch({ inputFont: font, currentFont: props.value, themeFonts: props.themeFonts });
  };

  const checkDisabledDelete = (fontValue?: string) =>
    fontValue === props.value ||
    fontValue === props.globalStyleFont?.body.family ||
    fontValue === props.globalStyleFont?.heading.family;

  const handleOpenDeleteModal = (event: Event, fontData: FontFamilyType | FontListItemType) => {
    emit('open-modal-delete', event, fontData);
  };

  const handleSelectFont = (value: string, type: FontFamilyType) => {
    emit('select', value, type);
  };

  const handleChangeSourceFont = () => {
    emit('change-source');
  };

  const resetScroll = () => {
    if (containerProps.ref.value && containerProps.ref.value.scrollTop > 0) {
      containerProps.ref.value.scrollTop = 0;
    }
  };

  watch(fontList, (newVal) => {
    if (!newVal) return;
    loadGoogleFonts();
  });

  return {
    fontList,
    wrapperProps,
    containerProps,
    resetScroll,
    handleSelectFont,
    checkDisabledDelete,
    getFontListItemProps,
    handleOpenDeleteModal,
    handleChangeSourceFont,
    getFontListHeaderLabel,
  };
};
