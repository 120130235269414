<template>
  <svg width="100%" height="100%" viewBox="0 0 50 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="50" height="24" rx="2" fill="#5E5E5E"/>
  <circle cx="20" cy="8" r="2" fill="#F9F9F9"/>
  <path d="M18.1837 16.6453L20.249 14.0595C20.319 13.9718 20.4054 13.9008 20.5026 13.8512C20.5999 13.8017 20.7059 13.7746 20.8136 13.7718C20.9213 13.769 21.0283 13.7905 21.1277 13.835C21.2271 13.8795 21.3166 13.9458 21.3904 14.0298L22.3562 15.1271L26.1312 10.2946C26.2051 10.1999 26.298 10.1243 26.403 10.0733C26.508 10.0224 26.6224 9.99737 26.7376 10.0002C26.8529 10.0031 26.9661 10.0337 27.0687 10.0898C27.1714 10.1459 27.2609 10.2261 27.3307 10.3243L31.844 16.6772C31.9303 16.7989 31.9832 16.9441 31.9966 17.0962C32.01 17.2484 31.9835 17.4016 31.9201 17.5385C31.8566 17.6754 31.7587 17.7907 31.6374 17.8713C31.5161 17.952 31.3762 17.9948 31.2333 17.995L18.7672 18C18.6206 17.9999 18.4771 17.9549 18.3536 17.8703C18.2301 17.7857 18.1319 17.6649 18.0705 17.5224C18.0091 17.3799 17.9872 17.2216 18.0072 17.0661C18.0273 16.9107 18.0885 16.7646 18.1837 16.6453Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'ImageCover'
};
</script>
