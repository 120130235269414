<template>
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 17C9.44772 17 9 16.4124 9 15.6875V4.3125C9 3.58763 9.44772 3 10 3C10.5523 3 11 3.58763 11 4.3125V15.6875C11 16.4124 10.5523 17 10 17Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ProductButtonVerticalLineSeparator'
};
</script>
