<template>
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.23223 8.23223C9.20855 7.25592 10.7915 7.25592 11.7678 8.23223C12.7441 9.20855 12.7441 10.7915 11.7678 11.7678C10.7915 12.7441 9.20855 12.7441 8.23223 11.7678C7.25592 10.7915 7.25592 9.20855 8.23223 8.23223Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ProductButtonDotSeparator'
};
</script>
