import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type AssignObjectTriggerConditionUpdateMutationVariables = Types.Exact<{
  assignObjectTriggerConditionUpdateId: Types.Scalars['ID'];
  input: Types.UpdateAssignObjectTriggerConditionInput;
}>;

export type AssignObjectTriggerConditionUpdateMutationResponse = {
  assignObjectTriggerConditionUpdate?: Types.Maybe<
    Pick<Types.TriggerCondition, 'id' | 'operator' | 'type' | 'updatedAt'> & {
      value?: Types.Maybe<
        Pick<Types.AssignObjectTriggerCondition, 'objects'> | Pick<Types.OrderTriggerCondition, 'amount'>
      >;
    }
  >;
};

export const AssignObjectTriggerConditionUpdateDocument = `
    mutation AssignObjectTriggerConditionUpdate($assignObjectTriggerConditionUpdateId: ID!, $input: UpdateAssignObjectTriggerConditionInput!) {
  assignObjectTriggerConditionUpdate(
    id: $assignObjectTriggerConditionUpdateId
    input: $input
  ) {
    id
    operator
    type
    updatedAt
    value {
      ... on AssignObjectTriggerCondition {
        objects
      }
      ... on OrderTriggerCondition {
        amount
      }
    }
  }
}
    `;
export const useAssignObjectTriggerConditionUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AssignObjectTriggerConditionUpdateMutationResponse,
    TError,
    AssignObjectTriggerConditionUpdateMutationVariables,
    TContext
  >,
) =>
  useMutation<
    AssignObjectTriggerConditionUpdateMutationResponse,
    TError,
    AssignObjectTriggerConditionUpdateMutationVariables,
    TContext
  >(
    ['AssignObjectTriggerConditionUpdate'],
    (
      variables:
        | AssignObjectTriggerConditionUpdateMutationVariables
        | ComputedRef<AssignObjectTriggerConditionUpdateMutationVariables>
        | Ref<AssignObjectTriggerConditionUpdateMutationVariables>,
    ) =>
      appFetcher<
        AssignObjectTriggerConditionUpdateMutationResponse,
        AssignObjectTriggerConditionUpdateMutationVariables
      >(AssignObjectTriggerConditionUpdateDocument, variables)(),
    options,
  );
useAssignObjectTriggerConditionUpdateMutation.getKey = () => ['AssignObjectTriggerConditionUpdate'];
