<script setup lang="ts">
import { cn } from '@gem/common';
import { GSvg } from '@gem/icons';
import type { LayoutSelectorType } from '../types';
import { GTooltip } from '@gem/uikit-v2';
const emit = defineEmits<{
  (e: 'changeLayout', value: string): void;
}>();
withDefaults(defineProps<Pick<LayoutSelectorType, 'options' | 'itemPerRow' | 'value'>>(), {
  itemPerRow: 3,
});
const handleChangeLayout = (value: string) => {
  emit('changeLayout', value);
};
</script>

<template>
  <div class="grid w-full gap-8" :class="cn(`grid-cols-${itemPerRow}`)">
    <div
      v-for="item in options"
      :key="item.value"
      class="group/layout grid max-w-full cursor-pointer rounded-xl border border-transparent"
      :class="{
        'border-primary-300': item.value == value,
      }"
      @click="handleChangeLayout(item.value)">
      <GTooltip placement="top" strategy="fixed" display-type="block">
        <div
          class="bg-dark-400 hover:bg-dark-250 flex h-[80px] items-center justify-center overflow-hidden rounded-xl p-[12px]"
          :class="itemPerRow === 3 ? 'h-[52px]' : 'h-[64px]'">
          <GSvg :name="item.iconName" />
        </div>
        <template #content>
          {{ item.label }}
        </template>
      </GTooltip>
    </div>
  </div>
</template>
