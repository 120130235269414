<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.25C10.3452 1.25 10.625 1.52982 10.625 1.875V4.375H13.125C14.1605 4.375 15 5.21447 15 6.25V7.5C15 8.53553 14.1605 9.375 13.125 9.375H10.625V10.625H15C16.0355 10.625 16.875 11.4645 16.875 12.5V13.75C16.875 14.7855 16.0355 15.625 15 15.625H10.625V18.125C10.625 18.4702 10.3452 18.75 10 18.75C9.65482 18.75 9.375 18.4702 9.375 18.125V15.625H5C3.96447 15.625 3.125 14.7855 3.125 13.75V12.5C3.125 11.4645 3.96447 10.625 5 10.625H9.375V9.375H6.875C5.83947 9.375 5 8.53553 5 7.5V6.25C5 5.21447 5.83947 4.375 6.875 4.375H9.375V1.875C9.375 1.52982 9.65482 1.25 10 1.25ZM15 14.375C15.3452 14.375 15.625 14.0952 15.625 13.75V12.5C15.625 12.1548 15.3452 11.875 15 11.875H5C4.65482 11.875 4.375 12.1548 4.375 12.5V13.75C4.375 14.0952 4.65482 14.375 5 14.375H15ZM13.125 8.125H6.875C6.52982 8.125 6.25 7.84518 6.25 7.5V6.25C6.25 5.90482 6.52982 5.625 6.875 5.625H13.125C13.4702 5.625 13.75 5.90482 13.75 6.25V7.5C13.75 7.84518 13.4702 8.125 13.125 8.125Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'AlignCenter'
};
</script>
