<script setup lang="ts">
import { computed } from 'vue';
import { GSvg } from '@gem/icons';

const props = defineProps<{
  type: 'system' | 'uploaded';
  icon: any;
  selectedItem?: any;
  deleteIds?: string[];
  enableSelectDelete?: boolean;
}>();
const emit = defineEmits<{
  (e: 'on-select', value: string, id: string, type: 'system' | 'uploaded'): void;
  (e: 'bulk-select-icon', id: string): void;
}>();

const isTypeUploaded = computed(() => props.type === 'uploaded');
const icon = computed(() => props.icon);
const iconValueSvg = computed(() => (isTypeUploaded.value ? icon.value.node.filePath : icon.value.value));
const realId = computed(() => (isTypeUploaded.value ? icon.value.node.id : icon.value.id));

const onSelect = () => {
  if (props.selectedItem.id === icon.value.id) return;
  emit('on-select', iconValueSvg.value, realId.value, props.type);
};
</script>
<template>
  <div
    :data-test="`editor-preview-icon-${icon.name}`"
    class="bg-dark-400 hover:bg-dark-50 group relative mr-8 flex h-[43px] w-[43px] cursor-pointer items-center justify-center rounded-[6px]"
    :class="{
      'border-primary-300 border': selectedItem.id === realId,
    }"
    @click.stop="onSelect">
    <img v-if="isTypeUploaded" class="h-24 w-24" :src="icon?.node?.filePath" alt="" />
    <svg
      v-if="type === 'system'"
      viewBox="0 0 256 256"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24">
      <path strokeLinecap="round" strokeLinejoin="round" :d="icon.value" />
    </svg>
    <div
      v-if="enableSelectDelete"
      class="absolute top-[2px] right-[2px] flex h-16 w-16 items-center justify-center rounded-[4px] border"
      :class="[
        deleteIds?.includes(realId)
          ? 'bg-primary-300 border-primary-300  visible'
          : 'bg-dark-300 hover:bg-dark-200 border-dark-200 hover:border-dark-50  invisible group-hover:visible',
      ]"
      @click.stop="() => emit('bulk-select-icon', realId)">
      <GSvg v-if="deleteIds.includes(realId)" name="gp-checkbox" :width="8" :height="8" />
    </div>
  </div>
</template>
