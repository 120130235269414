import { computed } from 'vue';
import type { LayoutSelectorType } from '../types';

export const useLayoutSelector = (props: LayoutSelectorType, emit: any) => {
  const handleChangeLayout = (value: string) => {
    emit('controlChange', value);
  };
  const options = computed(() => {
    return [...(props.options || []), ...(props.groups?.map((item) => item.options).flat() || [])];
  });
  const presetContent = computed(() => {
    const optionActive = options.value.find((item) => item.value === props.value);
    return {
      content: optionActive?.label,
      icon: optionActive?.iconName,
    };
  });
  return { handleChangeLayout, presetContent, options };
};
