<template>
  <svg width="100%" height="100%" viewBox="0 0 78 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="6" cy="14" r="5.25" stroke="#F9F9F9" stroke-width="1.5"/>
<rect x="16" y="12" width="62" height="4" rx="1.8125" fill="#AAAAAA"/>
</svg>
</template>

<script>
export default {
  name: 'InputRadioButton'
};
</script>
