<template>
  <svg width="100%" height="100%" viewBox="0 0 94 36" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<path d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H88C91.0376 0.5 93.5 2.96243 93.5 6V30C93.5 33.0376 91.0376 35.5 88 35.5H6C2.96243 35.5 0.5 33.0376 0.5 30V6Z" stroke="#5E5E5E"/>
<rect x="8" y="16" width="68" height="4" rx="1.8125" fill="#AAAAAA"/>
<g clip-path="url(#clip0_5373_122460)">
<mask id="mask0_5373_122460" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="69" y="9" width="24" height="18">
<rect x="69" y="9.66675" width="24" height="16.6667" fill="url(#pattern0_5373_122460)"/>
</mask>
<g mask="url(#mask0_5373_122460)">
<rect x="80" y="8" width="2" height="20" fill="#F9F9F9"/>
</g>
</g>
<defs>
<pattern id="pattern0_5373_122460" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlink:href="#image0_5373_122460" transform="matrix(0.0416667 0 0 0.06 0 -0.22)"/>
</pattern>
<clipPath id="clip0_5373_122460">
<rect width="2" height="20" fill="white" transform="translate(80 8)"/>
</clipPath>
<image id="image0_5373_122460" width="24" height="24" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAOElEQVRIS2NkIA38hypnJFYb0QqhBo5aQDBkR4NoNIgwQ2A0oxFMFaNBNBpEGCEwWpoSTBQjMIgArGcYGW6Q3QMAAAAASUVORK5CYII="/>
</defs>
</svg>
</template>

<script>
export default {
  name: 'InputText'
};
</script>
