<template>
  <svg width="100%" height="100%" viewBox="0 0 50 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_23910_20506)">
    <rect width="50" height="24" rx="2" fill="#5E5E5E"/>
    <path d="M9 15.1264C9 15.9031 8.03582 16.2921 7.47192 15.7429L4.26218 12.6165C3.91261 12.276 3.91261 11.724 4.26218 11.3835L7.47192 8.25712C8.03582 7.70786 9 8.09687 9 8.87363L9 15.1264Z" fill="#F9F9F9"/>
    <path d="M41 15.1264C41 15.9031 41.9642 16.2921 42.5281 15.7429L45.7378 12.6165C46.0874 12.276 46.0874 11.724 45.7378 11.3835L42.5281 8.25711C41.9642 7.70786 41 8.09686 41 8.87363L41 15.1264Z" fill="#F9F9F9"/>
  </g>
  <defs>
    <clipPath id="clip0_23910_20506">
      <rect width="50" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {
  name: 'SlideControlInside'
};
</script>
