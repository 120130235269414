<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.75 5V15H17.2532V5H2.75ZM2.5 3.5C1.80964 3.5 1.25 4.05964 1.25 4.75V15.25C1.25 15.9404 1.80964 16.5 2.5 16.5H17.5032C18.1935 16.5 18.7532 15.9404 18.7532 15.25V4.75C18.7532 4.05964 18.1935 3.5 17.5032 3.5H2.5Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'ImageShapeHorizontal',
};
</script>
