import type { GalleryImageListProps, GalleryImageItem, ImageUploadType } from '../../types';

import { ref, computed } from 'vue';
import { useInfiniteScroll } from '@vueuse/core';
import {
  convertImageFile,
  getImageUrlPreview,
  getUploadCareImageUrlByMimeType,
  handleFormatFileName,
} from '../../helpers';
import { useImageSelectContext } from '../useImageSelectContext';

export const useGalleryImageList = (props: GalleryImageListProps) => {
  const { actions, state } = useImageSelectContext();

  const listContainer = ref<HTMLElement | null>(null);

  useInfiniteScroll(
    listContainer,
    () => {
      if (!props?.galleryData?.viewMore) return;
      actions.handleShowMoreImage('listImage');
    },
    { distance: 100 },
  );

  const imageList = computed<GalleryImageItem[]>(() => {
    return state.imageList?.value
      ?.map((item) => convertImageFile(item))
      .map((image) => ({
        ...image,
        name: handleFormatFileName(image),
        isLoading: !isImageLoaded(image.id),
        previewUrl: getImageUrlPreview(image),
      }));
  });

  const postUploadImage = async (file: File) => {
    actions.handlePostUploadImage(file);
  };

  const handleClickImage = (image: GalleryImageItem) => {
    const imageFile: ImageUploadType = {
      width: image.width,
      height: image.height,
      fileName: image.fileName,
      backupFileKey: image.backupFileKey,
      backupFilePath: image.backupFilePath,
      filePath: getUploadCareImageUrlByMimeType(image),
      storage: image.fileKey?.includes('gid://shopify/') ? 'FILE_CONTENT' : 'THEME',
    };

    actions.handleTriggerImageChange(imageFile);

    handleClose();
  };

  const handleClose = () => {
    actions.closeGallery();
  };

  const handleChangeFile = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (!target.files?.length) return;

    const file = target.files.item(0);
    if (!file) return;

    if (!props?.allowedFiles?.includes(file.type)) {
      actions.handleShowAlert('format');
      handleClose();
      return;
    }

    if (props?.maximumSize && file.size > props.maximumSize) {
      actions.handleShowAlert('size');
      handleClose();
      return;
    }

    postUploadImage(file);

    actions.handleSetIsUploading(true);

    handleClose();
  };

  const handleDeleteImage = (id?: string) => {
    if (!id) return;
    actions.handleDeleteImage(id);
  };

  const handleSetImageLoaded = (id: string) => {
    props?.loadedList?.push(id);
  };

  const isImageLoaded = (id: string) => {
    return props?.loadedList?.includes(id);
  };

  return {
    imageList,
    listContainer,
    isImageLoaded,
    handleChangeFile,
    handleClickImage,
    handleDeleteImage,
    handleSetImageLoaded,
  };
};
