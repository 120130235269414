import { ref } from 'vue';
import type { FontListItemType, FontPickerEmits } from '../types';

export const useModalDeleteFont = (emit: FontPickerEmits) => {
  const isShow = ref(false);
  const currentFontData = ref<FontListItemType | {}>({});

  const handleOpen = (e: Event, fontData: FontListItemType | {}) => {
    e.stopPropagation();
    isShow.value = true;
    currentFontData.value = fontData;
  };

  const handleClose = () => {
    isShow.value = false;
    currentFontData.value = {};
  };

  const handleDeleteFont = () => emit('handleDeleteFont', handleClose, currentFontData);

  return {
    isShow,
    handleOpen,
    handleClose,
    handleDeleteFont,
  };
};
