import type { FontFamilyType } from '@gem/common';
import type { FontListInstance, FontListItemType, FontPickerEmits, FontPickerProps } from '../types';

import { computed, onMounted, ref } from 'vue';
import { useSearchFont } from './useSearchFont';
import { useFontLoader } from './useFontLoader';
import { useGetMixedFonts } from './useGetMixedFonts';
import { useModalDeleteFont } from './useModalDeleteFont';
import { cloneDeepObject } from '@gem/uikit/src/types/common';
import {
  getFontLabel,
  getFontStyles,
  getFontFamilyStyle,
  getCurrentFontOption,
  getThemeFontVariable,
} from '../helpers';

export const useFontPicker = (props: FontPickerProps, emit: FontPickerEmits) => {
  const fontListRef = ref<FontListInstance | null>(null);

  const deleteModal = useModalDeleteFont(emit);
  const { handleLoadSourceFont } = useFontLoader();

  const {
    onSearchMode,
    searchKeyword,
    noSearchFound,
    filteredFonts,
    filteredUploadFonts,
    handleSearch,
    handleClearSearch,
  } = useSearchFont(props, fontListRef);

  const mixedFonts = useGetMixedFonts({ props, searchKeyword, filteredFonts, filteredUploadFonts });

  const inputComboLayoutContent = computed(() => {
    if (!fontOption?.value) return '';
    return getFontLabel(fontOption.value.value, props.themeFonts);
  });

  const inputComboLayoutStyle = computed(() => {
    const fontFamily = getFontFamilyStyle({
      font: fontOption?.value?.value || '',
    });
    return getFontStyles(fontFamily);
  });

  const fontOption = computed(() => {
    if (!props.value) return;
    return getCurrentFontOption(props.options ?? [], props.value);
  });

  const handleUploadFont = (event: Event) => {
    searchKeyword.value = '';
    emit('handleUploadFont', event);
  };

  const handleChangeSourceFont = () => emit('handleSwitchFont');

  const handleSaveFontMeta = (data: FontListItemType) => {
    const payload = cloneDeepObject(data);

    const themFontVariable = getThemeFontVariable(data.value, data.type, props.themeFonts);

    payload.value = themFontVariable ?? data.value;

    emit('handleSaveFontMeta', payload, 'recently');
  };

  const handleSelectFont = (value: string, type: FontFamilyType) => {
    const payload = { value, type };
    handleSaveFontMeta(payload);
    emit('controlChange', props.id, payload);
  };

  onMounted(() => {
    if (
      fontOption.value?.value &&
      (fontOption.value?.type === 'google' || fontOption.value?.type === 'bunny' || !fontOption.value?.type)
    ) {
      handleLoadSourceFont(fontOption.value.value, fontOption.value?.type);
    }
  });

  return {
    mixedFonts,
    fontListRef,
    deleteModal,
    onSearchMode,
    noSearchFound,
    searchKeyword,
    inputComboLayoutStyle,
    inputComboLayoutContent,
    handleSearch,
    handleUploadFont,
    handleSelectFont,
    handleClearSearch,
    handleSaveFontMeta,
    handleLoadSourceFont,
    handleChangeSourceFont,
  };
};
