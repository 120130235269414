<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { event } from 'gemx/src/modules/editor/modules/common/use-cases/event';
import ProductPickerPopover from '../modal/product-single-modal/ProductPickerPopover.vue';
import ProductSelected from './components/ProductSelected.vue';
import type { EmitTypeProductControl, ProductControlProps } from './type';
import { STATIC_PRODUCT_STATUS } from './constant';
import { useSetValueSelected } from './hooks/useSetValueSelected';

const props = defineProps<ProductControlProps>();
const emit = defineEmits<EmitTypeProductControl>();

const {
  isDynamicProductInThemeSection,
  isThemeSectionEditor,
  valueDefaults,
  isSalePage,
  convertProductDisplay,
  productStatusByPageType,
} = useSetValueSelected(props);

const productListVisibility = ref<boolean>(false);
const isDisplaySwitchToAssignedBanner = ref<boolean>(false);

const closeModal = () => {
  productListVisibility.value = false;
  emit('closeModal');
};

const openModal = () => {
  productListVisibility.value = true;
  emit('openModal');
};

const setValueSelected = (productValue?: string) => {
  if (!productValue) return;
  if (productValue === valueDefaults.value?.productId) return;
  const product = props.productList?.find((item) => item.id === productValue);
  if (productStatusByPageType.value === STATIC_PRODUCT_STATUS) {
    emit('setPickedStatic', { productId: productValue, productStatus: STATIC_PRODUCT_STATUS });
  } else {
    emit('setPickedDynamic', {
      productId: productValue,
      productStatus: DYNAMIC_PRODUCT_STATUS,
      productHandle: product?.handle,
    });
  }
  emit('controlChange', props.id, {
    productId: productValue,
    initialVariantId: valueDefaults.value.initialVariantId,
    initialVariantBaseId: valueDefaults.value.initialVariantBaseId,
    productHandle: product?.handle,
    productStatus: productStatusByPageType.value,
  });
};

const onSearch = (value?: string) => {
  emit('controlOnSearch', props.id, value);
};

onMounted(() => {
  if (props?.value?.isUpdatedProduct) {
    emit('controlChange', props.id, {
      productId: props.value?.productId,
      initialVariantId: valueDefaults.value.initialVariantId,
      initialVariantBaseId: valueDefaults.value.initialVariantBaseId,
      productHandle: props.value?.productHandle,
      productStatus: productStatusByPageType.value,
    });
  }
  if (isThemeSectionEditor.value) {
    emit('set-preview-dynamic-product', {
      productId: props.value?.productId,
      productHandle: props.value?.productHandle,
    });
    emit('setPickedStatic', { productId: props.value?.productId, productStatus: STATIC_PRODUCT_STATUS });
  }
  if (props.shouldDisplaySwitchToAssignedBanner) {
    isDisplaySwitchToAssignedBanner.value = true;
  }
});

const emitRefresh = () => {
  emit('controlRefresh');
};

onMounted(() => {
  event.off('request-sync-product-source', emitRefresh);
  event.on('request-sync-product-source', emitRefresh);
});

const handleSync = () => {
  emit('onSyncProduct');
};

const DYNAMIC_PRODUCT_STATUS = 'dynamic';
const onChangeProductType = (newType: any) => {
  let productId, productHandle;
  if (newType == DYNAMIC_PRODUCT_STATUS) {
    const pickedDynamicProduct = props?.pickedDynamicProduct;
    if (pickedDynamicProduct && pickedDynamicProduct.productId) {
      productId = pickedDynamicProduct.productId;
      productHandle = pickedDynamicProduct.productHandle;
    } else if (isThemeSectionEditor.value && props.previewDynamicProduct?.productId) {
      productId = props.previewDynamicProduct.productId;
      productHandle = props.previewDynamicProduct.productHandle;
    }
  } else {
    const pickedStaticProduct = props?.pickedStaticProduct;
    if (!pickedStaticProduct || !pickedStaticProduct.productId) {
      emit('setPickedStatic', { productId: 'latest', productStatus: STATIC_PRODUCT_STATUS });
    }
    if (pickedStaticProduct && pickedStaticProduct.productId) {
      productId = pickedStaticProduct.productId;
      productHandle = pickedStaticProduct.productHandle;
    }
  }
  emit('controlChange', props.id, {
    productId: productId ?? 'latest',
    initialVariantId: valueDefaults.value.initialVariantId,
    initialVariantBaseId: valueDefaults.value.initialVariantBaseId,
    productHandle: productHandle,
    productStatus: newType,
  });
};
</script>
<template>
  <div class="flex flex-col gap-8">
    <ProductPickerPopover
      :id="id"
      :value="convertProductDisplay"
      :product-status-by-page-type="productStatusByPageType"
      :is-dynamic-product-in-theme-section="isDynamicProductInThemeSection"
      :picked-dynamic-product="pickedDynamicProduct"
      :list="productList"
      :type="'Product'"
      :open="productListVisibility"
      :is-loading="isLoading || isSync"
      :is-sync="isSync"
      :sync-percent="syncPercent"
      :page-type="pageType"
      :page-id="pageId"
      :input-search-component="inputSearchComponent"
      :is-display-switch-to-assigned-banner="isDisplaySwitchToAssignedBanner"
      :product-show-mode="productShowMode"
      :is-fetching-next-page-product="isFetchingNextPageProduct"
      :navigate-to-product-shopify="navigateToProductShopify"
      :is-no-product-in-store="isNoProductInStore"
      :is-no-assigned-product="isNoAssignedProduct"
      @show-more="emit('showMore', 'fetch')"
      @show-more-assigned="emit('showMoreAssigned', 'fetch')"
      @refresh="emit('controlRefresh')"
      @close-modal="closeModal"
      @open-modal="openModal"
      @set-value-selected="setValueSelected"
      @open-assign-modal="emit('openAssignModal')"
      @on-search="onSearch"
      @select-product-mode="onChangeProductType">
      <template #btn-popover
        ><ProductSelected
          :product-status-by-page-type="productStatusByPageType"
          :convert-product-display="convertProductDisplay"
          :is-no-product-in-store="isNoProductInStore"
          :is-no-assigned-product="isNoAssignedProduct"
          :product-list-visibility="productListVisibility"
          :page-type="pageType"
          :is-loading="isLoading"
          :value="value"
          :picked-dynamic-product="pickedDynamicProduct"
          :is-sale-page="isSalePage"
          @on-sync-product="handleSync" />
      </template>
    </ProductPickerPopover>
  </div>
</template>
