import { computed } from 'vue';
import type { ProductSelectedProp } from '../type';

export const useGetDataProductSelected = (props: ProductSelectedProp) => {
  const productSrcType = computed(() => props.productSrcType);

  // Pick products
  const titleProduct = computed(() => {
    if (props.list.length === 1) return props.list?.[0].title ?? '';
    if (props.list.length) return `${props.list.length} products`;
    return 'No product selected';
  });

  // Pick collection
  const productsLengthCollection = computed(() =>
    (props.collectionDisplay?.products?.length ?? 0) < 2
      ? `${props.collectionDisplay?.products?.length ?? 0} product`
      : `${props.collectionDisplay?.products?.length} products`,
  );
  const collectionTitle = computed(() =>
    props.collectionDisplay?.id ? props.collectionDisplay?.title : 'No collection selected',
  );

  // Related products
  const relatedTag = computed(() => props.valueSetting?.relatedTag);
  const relatedCollectionExclude = computed(() => props.valueSetting?.relatedCollectionExclude);
  const relatedTagsExclude = computed(() => props.valueSetting?.relatedTagsExclude);
  const titleRelated = computed(() => {
    const relatedTag = props.valueSetting?.relatedTag;
    if (relatedTag?.includes('collection')) return 'Same collection with assigned product';
    if (relatedTag?.includes('tags')) return 'Same tag with assigned product';
    return 'Same vendor with assigned product';
  });
  const isShowProductExcludeLength = computed(
    () => relatedTag.value?.includes('collection') || relatedTag.value?.includes('tags'),
  );

  const classContainerProductSelected = computed(() => ({
    'bg-dark-400 group relative flex h-[56px] cursor-pointer items-center gap-[11px] overflow-hidden rounded-xl p-8 border-transparent border':
      true,
    'hover:bg-dark-300': !props.isSalePage,
    '!bg-dark-250': props.isLoading,
    '!border-primary-300': props.productListVisibility,
  }));
  const productsExcludeLength = computed(() => {
    if (productSrcType.value !== 'RelatedProduct') return '';
    if (relatedTag.value?.includes('collection')) {
      const excludeLengths = (relatedCollectionExclude.value?.split(',') ?? []).length;
      return excludeLengths > 1 ? `Exclude ${excludeLengths} collections` : `Exclude ${excludeLengths} collection`;
    }
    if (relatedTag.value?.includes('tags')) {
      const excludeLengths = (relatedTagsExclude.value?.split(',') ?? []).length;
      return excludeLengths > 1 ? `Exclude ${excludeLengths} tags` : `Exclude ${excludeLengths} tag`;
    }
    return '';
  });

  const lineClampClass = computed(() => (relatedTag.value?.includes('vendor') ? 'line-clamp-2' : 'line-clamp-1'));
  return {
    titleProduct,
    productsLengthCollection,
    collectionTitle,
    titleRelated,
    lineClampClass,
    isShowProductExcludeLength,
    productsExcludeLength,
    classContainerProductSelected,
  };
};
