<script setup lang="ts">
import type { SettingUIGroup } from '@gem/element-setting-ui';
import { computed, useSlots } from 'vue';
import SettingTitle from '../SettingTitle.vue';
import { cn } from '@gem/common';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';
import MoreSettings from '../MoreSettings.vue';

defineProps<{
  data?: SettingUIGroup;
  noBorder?: boolean;
  showDevices?: boolean;
}>();

const settingSideBarStore = useSettingSideBarStore();
const currentDevice = computed(() => settingSideBarStore.currentDevice);
const slot = useSlots();
</script>

<template>
  <div
    class="flex flex-col gap-8 py-16"
    :class="cn([noBorder ? '' : 'border-dark-300 border-b', data?.options?.displayNone ? 'hidden' : ''])">
    <div v-if="data?.label" class="flex items-center justify-between">
      <SettingTitle
        class="w-full"
        data-test="group-title"
        :show-devices="showDevices"
        :current-device="currentDevice"
        :label="data?.label"
        :help="data?.help" />
      <slot name="toggleSettings"></slot>
      <MoreSettings v-if="slot.moreSettings && data?.moreSettings?.type === 'icon'" :data="data?.moreSettings">
        <slot name="moreSettings"></slot>
      </MoreSettings>
    </div>
    <div class="flex flex-col gap-16">
      <slot name="controls" />
      <MoreSettings
        v-if="slot.moreSettings && data?.moreSettings?.type !== 'icon'"
        data-test="group-more-settings"
        :data="data?.moreSettings">
        <slot name="moreSettings"></slot>
      </MoreSettings>
    </div>
  </div>
</template>
