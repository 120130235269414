<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path d="M2.8125 1.25C3.15768 1.25 3.4375 1.52982 3.4375 1.875L3.4375 18.125C3.4375 18.4702 3.15768 18.75 2.8125 18.75C2.46732 18.75 2.1875 18.4702 2.1875 18.125L2.1875 1.875C2.1875 1.52982 2.46732 1.25 2.8125 1.25Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.0625 6.25C4.0625 5.21447 4.90197 4.375 5.9375 4.375H12.1875C13.223 4.375 14.0625 5.21447 14.0625 6.25V7.5C14.0625 8.53553 13.223 9.375 12.1875 9.375H5.9375C4.90197 9.375 4.0625 8.53553 4.0625 7.5V6.25ZM5.9375 5.625C5.59232 5.625 5.3125 5.90482 5.3125 6.25V7.5C5.3125 7.84518 5.59232 8.125 5.9375 8.125H12.1875C12.5327 8.125 12.8125 7.84518 12.8125 7.5V6.25C12.8125 5.90482 12.5327 5.625 12.1875 5.625H5.9375Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9375 10.625C4.90197 10.625 4.0625 11.4645 4.0625 12.5V13.75C4.0625 14.7855 4.90197 15.625 5.9375 15.625H15.9375C16.973 15.625 17.8125 14.7855 17.8125 13.75V12.5C17.8125 11.4645 16.973 10.625 15.9375 10.625H5.9375ZM5.3125 12.5C5.3125 12.1548 5.59232 11.875 5.9375 11.875H15.9375C16.2827 11.875 16.5625 12.1548 16.5625 12.5V13.75C16.5625 14.0952 16.2827 14.375 15.9375 14.375H5.9375C5.59232 14.375 5.3125 14.0952 5.3125 13.75V12.5Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'AlignLeft'
};
</script>
