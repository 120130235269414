import { provide } from 'vue';
import type { ImageSelectContext, ImageSelectorEmits, ImageSelectorProps } from '../types';
import { useImageSelectActions } from './useImageSelectActions';
import { useImageSelectState } from './useImageSelectState';

export function useCreateImageSelectContext(props: ImageSelectorProps, emit: ImageSelectorEmits): ImageSelectContext {
  const state = useImageSelectState(props);
  const actions = useImageSelectActions(props, emit, state);

  const context: ImageSelectContext = {
    state,
    props,
    actions,
  };

  provide('imageSelectContext', context);

  return context;
}
