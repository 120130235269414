<template>
  <svg width="100%" height="100%" viewBox="0 0 94 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H88C91.0376 0.5 93.5 2.96243 93.5 6V30C93.5 33.0376 91.0376 35.5 88 35.5H6C2.96243 35.5 0.5 33.0376 0.5 30V6Z" stroke="#5E5E5E"/>
<rect x="8" y="16" width="62" height="4" rx="1.8125" fill="#AAAAAA"/>
<path d="M82.4624 19.7903C82.207 20.0699 81.793 20.0699 81.5376 19.7903L79.1928 17.2225C78.7809 16.7713 79.0726 16 79.6552 16L84.3448 16C84.9274 16 85.2191 16.7713 84.8072 17.2225L82.4624 19.7903Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'InputDropdown'
};
</script>
