import { ref } from 'vue';
import { useHandleSearch } from './useHandleSearch';
export const useHandleDataSearchComponent = (emit: { (e: 'onSearch', value?: string): void }) => {
  const isSearchSetting = ref<boolean>(false);
  const inputSearch = ref<HTMLElement>();
  const searchKeyword = ref<string>('');
  const { openSearchSetting, onChangeSearch, closeSearchSetting } = useHandleSearch(
    isSearchSetting,
    inputSearch,
    searchKeyword,
    emit,
  );

  const onBlurInputSearch = () => {
    return !searchKeyword.value && closeSearchSetting();
  };

  return {
    onBlurInputSearch,
    openSearchSetting,
    closeSearchSetting,
    onChangeSearch,
    searchKeyword,
    inputSearch,
    isSearchSetting,
  };
};
