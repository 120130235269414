<script lang="ts" setup>
import { GButton } from '@gem/uikit-v2';

import { replaceImageToSize } from '../../../../hook/useResizeImage';
import ArchivedStatus from '../../../../icons/ArchivedStatus.vue';
import DraftStatus from '../../../../icons/DraftStatus.vue';
import { useHandleDataSingleList } from '../hooks/useHandleDataSingleList';
import type { EmitTypeProductSingle, SingleProductProps } from '../types';

// props & emit
defineProps<SingleProductProps>();
const emit = defineEmits<EmitTypeProductSingle>();

const { setValueSelected, productInfinityScroll } = useHandleDataSingleList(emit);
</script>
<template>
  <div>
    <div
      v-if="(list ?? []).length === 0"
      class="flex h-[280px] max-h-[calc(100vh-200px)] w-full flex-col items-center justify-center">
      <img class="h-[80px] w-[80px] rounded-xl" src="../../../images/searchEmpty.png" alt="collections" />
      <div v-if="isNotHaveProductInStore" class="text-12 font-regular text-text-dark-300 text-center leading-5">
        No product found in your store.<br />
        <a class="text-primary-200 cursor-pointer no-underline" type="link" @click="navigateToProductShopify"
          >Create new in Shopify
        </a>
        and try again.
      </div>
      <div v-else class="text-12 font-regular text-text-dark-300 text-center leading-5">
        No product match your search.<br />Try another keyword
      </div>
    </div>
    <perfect-scrollbar v-else ref="productInfinityScroll" class="max-height-manual-list !max-h-[282px] px-16">
      <div class="flex flex-col">
        <div
          v-for="(item, index) in list"
          :key="index"
          data-test="setting-modal-product-item"
          class="active:border-primary-300 hover:bg-dark-300 flex cursor-pointer items-center gap-8 rounded-[12px] p-8 active:border"
          :class="{
            'border-primary-300 !cursor-default border': item.id == value?.id,
          }"
          @click="setValueSelected(item.id)"
          @keypress="setValueSelected(item.id)">
          <div
            class="h-[40px] w-[40px] flex-shrink-0 rounded-xl bg-transparent"
            :class="{
              'flex items-center justify-center': !item.image,
            }">
            <img
              v-if="item.image"
              class="h-full w-full rounded-xl"
              :src="replaceImageToSize(item.image, '40x40') ?? item.image"
              alt="collections" />
            <img
              v-else
              class="h-full w-full rounded-xl bg-transparent"
              src="../../../images/no-product.png"
              alt="no-image" />
          </div>
          <div
            class="text-12 line-clamp-2 text-text-dark-500 font-regular w-full truncate whitespace-pre-wrap leading-5">
            {{ item?.title }}
          </div>
          <div v-if="item.status?.toLowerCase() !== 'active'" class="flex items-center">
            <g-tooltip
              v-if="item?.status?.toLowerCase() === 'draft'"
              placement="top"
              wrapper-class="w-full"
              content-class="!whitespace-normal"
              :is-teleport="true">
              <DraftStatus />
              <template #content>
                <div class="text-text-dark-500 font-regular text-12 leading-5">Draft product</div>
              </template>
            </g-tooltip>
            <g-tooltip
              v-if="item?.status?.toLowerCase() === 'archived'"
              placement="top"
              wrapper-class="w-full"
              content-class="!whitespace-normal"
              :is-teleport="true">
              <ArchivedStatus />
              <template #content>
                <div class="text-text-dark-500 font-regular text-12 leading-5">Archived product</div>
              </template>
            </g-tooltip>
          </div>
        </div>
        <GButton v-if="isLoadMore" :loading="isLoadMore" size="medium" type="ghost" only-icon="polaris-refresh" />
      </div>
    </perfect-scrollbar>
  </div>
</template>
