<script lang="ts" setup>
import Dot from './components/Dot.vue';
import { usePositionSetting } from './hooks/usePositionSetting';

const props = defineProps<{
  id: string;
  value?: number | string;
  ignoreValue?: string[];
}>();
const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
}>();

const { val, slots, hideIndex, handleChange } = usePositionSetting(props, emit);
</script>

<template>
  <div>
    <div
      class="bg-dark-400 mx-auto mr-0 grid w-[140px] grid-cols-3 grid-rows-3 place-content-center place-items-center gap-4 rounded-xl">
      <Dot
        v-for="(item, index) in slots"
        :key="item"
        :value="item"
        :selected="item == val"
        :class="{
          'pointer-events-none opacity-0': hideIndex?.includes(index),
        }"
        @control-change="handleChange" />
    </div>
  </div>
</template>
