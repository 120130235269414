import { Color } from '../Color';
import { ref, computed, watch } from 'vue';
import { TRANSPARENT_COLOR } from '../helpers';
import { isValidHexWithoutPrefix, isValidColorFormat, getHexColorThreeDigit } from '@gem/common';
import type { InputColorProps } from '../types';

declare global {
  interface Window {
    EyeDropper: any;
  }
}

type EyeDropperConstructor = new () => EyeDropperInterface;

interface ColorSelectionOptions {
  signal: AbortSignal;
}

interface ColorSelectionResult {
  sRGBHex: string;
}

interface EyeDropperInterface extends EyeDropperConstructor {
  open: (options?: ColorSelectionOptions) => Promise<ColorSelectionResult>;
}

declare let EyeDropper: {
  prototype: EyeDropperInterface;
  new (): EyeDropperInterface;
};

export function useInputColor(props: InputColorProps, emits: any) {
  const colorInput = ref();
  const opacityInput = ref();
  const color = ref(Color.fromString(props.value));
  const noHashtagHexColor = ref(
    props.value && props.value !== TRANSPARENT_COLOR ? color.value.getHexColor()?.replace('#', '') : '',
  );
  const changeTagHexColor = () => {
    noHashtagHexColor.value = color.value?.getHexColor()?.replace('#', '');
  };

  const handleEmit = (type: 'change' | 'onChange') => {
    const newValue = color.value.getColor();
    if (!newValue) return;
    if (type === 'change') {
      colorInput.value?.updateValue(color.value.getHexColor()?.replace('#', ''));
      opacityInput.value?.updateValue(Math.round(color.value.a * 100));
      changeTagHexColor();
      emits('change', newValue);
    } else {
      emits('onChange', newValue);
    }
  };

  const handleOnChangeHex = (value?: string) => {
    if (!value) return;
    if (getHexColorThreeDigit(value)) {
      changeColorOnThreeDigitHex(value);
      emits('onChange', color.value.getColor());
      return;
    } else {
      if (isValidHexWithoutPrefix(value)) {
        const opacity = color.value.a;
        color.value = Color.fromString('#' + value);
        color.value.setAlpha(opacity);
      } else if (isValidColorFormat(value)) {
        color.value = Color.fromString(value);
      }
    }
    handleEmit('onChange');
  };

  const handleOnChangeAlpha = (value?: string) => {
    if (!value) return;
    const newValue = Number(Math.max(0, Math.min(100, Number(value)) / 100).toFixed(2));
    if (newValue >= 0 && newValue <= 1) {
      color.value.setAlpha(newValue);
      handleEmit('onChange');
    }
  };

  const browserSupportEyeDropper = computed(() => window?.EyeDropper);

  const onOpenEyeDropper = () => {
    const eyeDropper = new EyeDropper();
    eyeDropper
      .open()
      .then((result: any) => {
        color.value = Color.fromString(result.sRGBHex);
        handleEmit('change');
      })
      .catch((e: any) => {
        console.error(e);
      });
  };

  const changeColorOnThreeDigitHex = (value: string) => {
    noHashtagHexColor.value = value;
    color.value = Color.fromString(value);
  };

  watch(
    () => props.value,
    (newValue) => {
      color.value = Color.fromString(newValue);
      if (getHexColorThreeDigit(noHashtagHexColor.value)) {
        return;
      }
      changeTagHexColor();
    },
  );

  return {
    color,
    colorInput,
    opacityInput,
    noHashtagHexColor,
    browserSupportEyeDropper,
    handleOnChangeHex,
    handleOnChangeAlpha,
    handleEmit,
    onOpenEyeDropper,
  };
}
