<template>
  <svg viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
    <g clip-path="url(#clip0_293_7965)">
      <path d="M-8 2C-8 -1.31371 -5.31371 -4 -2 -4H82C85.3137 -4 88 -1.31371 88 2V46C88 49.3137 85.3137 52 82 52H-2C-5.31371 52 -8 49.3137 -8 46V2Z" fill="#474747"/>
      <path d="M-8 2C-8 -1.31371 -5.31371 -4 -2 -4H82C85.3137 -4 88 -1.31371 88 2V20H-8V2Z" fill="#5E5E5E"/>
      <rect x="31" y="29" width="18" height="18" rx="9" transform="rotate(-90 31 29)" fill="#F9F9F9"/>
      <path d="M37.1931 23.2225L39.5379 25.7903C39.7932 26.0699 40.2073 26.0699 40.4626 25.7903L42.8074 23.2225C43.2193 22.7713 42.9276 22 42.345 22H37.6555C37.0729 22 36.7811 22.7713 37.1931 23.2225Z" fill="#212121"/>
      <path d="M37.1931 16.7775L39.5379 14.2097C39.7932 13.9301 40.2073 13.9301 40.4626 14.2097L42.8074 16.7775C43.2193 17.2287 42.9276 18 42.345 18L37.6555 18C37.0729 18 36.7811 17.2287 37.1931 16.7775Z" fill="#212121"/>
      <rect x="-8" y="20" width="96" height="1" fill="#F9F9F9"/>
    </g>
    <defs>
      <clipPath id="clip0_293_7965">
        <path d="M0 6C0 2.68629 2.68629 0 6 0H74C77.3137 0 80 2.68629 80 6V42C80 45.3137 77.3137 48 74 48H6C2.68629 48 0 45.3137 0 42V6Z" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ImageComparisonVertical'
};
</script>
