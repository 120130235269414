import type { TypographyV2Props, TypographyV2Value } from '@gem/common';
import { isObjectEqual } from '@gem/common';
import type { OptionSelect } from '@gem/uikit';
import { computed } from 'vue';
import { useGetFontWeightFromOptions } from '../composables/useGetFontWeightFromOptions';
import { useGetFontWeightOptions } from '../composables/useGetFontWeightOptions';
import type { TypoControlProps } from '../types';

export const useTypoGraphyControl = (typoControlProps: TypoControlProps, emit: any) => {
  const allFonts = computed(() => typoControlProps.allFonts);

  const checkExistFont = computed(() => {
    return allFonts.value?.some((font: any) => {
      if (typeof typoControlProps.value?.custom?.fontFamily === 'string') {
        return typoControlProps.value?.custom?.fontFamily === font?.family;
      }
      return typoControlProps.value?.custom?.fontFamily?.value === font?.family;
    });
  });

  const displayValue = computed<TypographyV2Props | undefined>(() => {
    const textShadow = typoControlProps.value?.custom?.textShadow ?? {
      angle: 37,
      blur: '2px',
      color: 'rgba(0, 0, 0, 0.6)',
      distance: '4px',
      type: 'none',
    };
    let baseTypo = { ...typoControlProps.baseTypo };
    if (typoControlProps.isCustom) {
      const mergeValue = { ...baseTypo, ...typoControlProps.value?.custom };
      return {
        ...mergeValue,
        fontFamily:
          !checkExistFont.value && typoControlProps.fontBackup
            ? typoControlProps.fontBackup.family
            : mergeValue.fontFamily,
        textShadow,
      };
    }

    if (typoControlProps.value?.custom && typoControlProps.value?.custom?.fontSize) {
      baseTypo = {
        ...baseTypo,
        fontSize: typoControlProps.value.custom.fontSize,
      };
    }

    return baseTypo;
  });

  const displayAttr = computed(() => {
    return typoControlProps.value?.attrs;
  });
  const typoName = computed(() => {
    return typoControlProps.typos?.find((typo: any) => typo.id === typoControlProps.value?.type)?.name ?? 'Custom';
  });

  const { getFontWeightOptions } = useGetFontWeightOptions({
    themeFonts: typoControlProps.themeFonts,
    fontBackup: typoControlProps.fontBackup,
  });

  const fontWeightOptions = computed<{ title: string; value: string }[] | undefined>(() =>
    getFontWeightOptions(allFonts.value, displayValue?.value?.fontFamily),
  );

  const handleSaveColor = (newColorList: {}) => emit('saveColor', newColorList);

  const handleChangeField = (
    name: string,
    value: any,
    propType: 'attrs' | 'custom' = 'custom',
    type: 'controlOnChange' | 'controlChange',
  ) => {
    const currentPropValue = propType === 'attrs' ? displayAttr.value : displayValue.value;
    const newValue: TypographyV2Value = {
      ...typoControlProps.value,
      [propType]: {
        ...currentPropValue,
        [name]: value,
      },
    };
    if (name === 'fontFamily' && propType === 'custom') {
      const font = allFonts.value?.find((item) => item.family === value);
      const fontVariants = font?.variants;
      const fontWeightOptions = getFontWeightOptions(allFonts.value, newValue.custom?.fontFamily);
      const fontWeight = useGetFontWeightFromOptions(newValue.custom, fontWeightOptions);

      newValue.custom = {
        ...newValue.custom,
        fontVariants,
        fontWeight,
      };
    }
    const { fontVariants, ...restCustom } = newValue.custom ?? {};
    if (isObjectEqual(typoControlProps.baseTypo, restCustom) && typoControlProps.value?.type) {
      emit('changeTypo', typoControlProps.value.type);
    } else {
      if (type === 'controlChange') {
        emit('controlChange', newValue);
      } else {
        emit('controlOnChange', newValue);
      }
    }
  };

  const handleControlChange = (name: string, value: any, propType: 'attrs' | 'custom' = 'custom') => {
    handleChangeField(name, value, propType, 'controlChange');
  };

  const handleControlOnChange = (name: string, value?: any, propType: 'attrs' | 'custom' = 'custom') => {
    handleChangeField(name, value, propType, 'controlOnChange');
  };

  const handleChangeTypo = (_: OptionSelect, newType: any) => {
    if (newType !== typoControlProps.value?.type) {
      emit('changeTypo', newType);
    }
  };

  return {
    allFonts,
    displayValue,
    displayAttr,
    typoName,
    fontWeightOptions,
    handleSaveColor,
    handleControlChange,
    handleControlOnChange,
    handleChangeTypo,
  };
};
