<script lang="ts" setup>
import { GButton } from '@gem/uikit-v2';
import { replaceImageToSize } from '../../../../hook/useResizeImage';
import ArchivedStatus from '../../../../icons/ArchivedStatus.vue';
import DraftStatus from '../../../../icons/DraftStatus.vue';
import { useHandleDataCollectionPicker } from '../hooks/useHandleDataCollectionPicker';
import type { CollectionPickerEmit, CollectionPickerProps } from '../type';

defineProps<CollectionPickerProps>();
const emit = defineEmits<CollectionPickerEmit>();

const { setValueSelected, productInfinityScroll } = useHandleDataCollectionPicker(emit);
</script>
<template>
  <div>
    <div
      v-if="(list ?? []).length === 0"
      class="flex h-[280px] max-h-[calc(100vh-200px)] w-full flex-col items-center justify-center">
      <img class="h-[80px] w-[80px] rounded-xl" src="../../../images/searchEmpty.png" alt="collections" />
      <div v-if="isNotHaveCollection" class="text-12 font-regular text-text-dark-300 text-center leading-5">
        No collection found in your store.<br />
        <a class="text-primary-200 cursor-pointer no-underline" type="link" @click="navigateToCollectionShopify"
          >Create new in Shopify
        </a>
        and try again.
      </div>
      <div v-else class="text-12 font-regular text-text-dark-300 text-center leading-5">
        No collection match your search.<br />Try another keyword
      </div>
    </div>
    <perfect-scrollbar v-else ref="productInfinityScroll" class="max-height-collection-list px-16">
      <div class="flex flex-col">
        <div
          v-for="(item, index) in list"
          :key="index"
          data-test="setting-modal-product-item"
          class="active:border-primary-300 hover:bg-dark-300 flex cursor-pointer items-center gap-8 rounded-[12px] p-8 active:border"
          :class="{
            'border-primary-300 !cursor-default border': item.id == value,
          }"
          @click="setValueSelected(item.id)"
          @keypress="setValueSelected(item.id)">
          <div
            class="h-[40px] w-[40px] flex-shrink-0 rounded-xl bg-transparent"
            :class="{
              'flex items-center justify-center': !item.image,
            }">
            <img
              v-if="item.image"
              class="h-full w-full rounded-xl"
              :src="replaceImageToSize(item.image, '40x40') ?? item.image"
              alt="collections" />
            <img v-else class="h-full w-full rounded-xl" src="../../../images/no-product.png" alt="collections" />
          </div>
          <div class="flex flex-col justify-center">
            <p
              class="text-12 line-clamp-1 text-text-dark-500 font-regular w-full truncate whitespace-pre-wrap leading-5">
              {{ item?.title }}
            </p>
            <p class="text-12 font-regular text-text-dark-300 leading-5">{{ item?.products?.length ?? 0 }} products</p>
          </div>
          <div v-if="item.status?.toLowerCase() !== 'active'" class="flex items-center">
            <g-tooltip
              v-if="item?.status?.toLowerCase() === 'draft'"
              placement="top"
              wrapper-class="w-full"
              content-class="!whitespace-normal"
              :is-teleport="true">
              <DraftStatus />
              <template #content>
                <div class="text-text-dark-500 font-regular text-12 leading-5">Draft product</div>
              </template>
            </g-tooltip>
            <g-tooltip
              v-if="item?.status?.toLowerCase() === 'archived'"
              placement="top"
              wrapper-class="w-full"
              content-class="!whitespace-normal"
              :is-teleport="true">
              <ArchivedStatus />
              <template #content>
                <div class="text-text-dark-500 font-regular text-12 leading-5">Archived product</div>
              </template>
            </g-tooltip>
          </div>
        </div>
        <GButton v-if="isLoadMore" :loading="isLoadMore" size="medium" type="ghost" only-icon="polaris-refresh" />
      </div>
    </perfect-scrollbar>
  </div>
</template>
