<script setup lang="ts">
import { useImageSelector } from './hooks/useImageSelector';
import type { ImageSelectorProps, ImageSelectorEmits } from './types';

import LinkInput from './components/LinkInput.vue';
import WarningPopup from './components/WarningPopup.vue';
import OverlayActions from './components/OverlayActions.vue';
import Gallery from './components/image-gallery/Gallery.vue';
import LoadingIndicator from './components/LoadingIndicator.vue';
import BrowseGalleryTrigger from './components/BrowseGalleryTrigger.vue';

const props = withDefaults(defineProps<ImageSelectorProps>(), {
  maximumSize: 1 * 1024 * 1024, // 10MB
  allowedFiles: () => ['image/jpeg', 'image/gif', 'image/png', 'image/webp', 'image/svg+xml'],
});

const emit = defineEmits<ImageSelectorEmits>();

const {
  imageSrc,
  isLoaded,
  showAlert,
  alertType,
  errorMessage,
  imageClasses,
  isOpenGallery,
  previewImageSrc,
  openGallery,
  imageList,
  deletedImageList,
  handleSetImageList,
} = useImageSelector(props, emit);

defineExpose({
  imageList,
  deletedImageList,
  handleSetImageList,
});
</script>

<template>
  <div class="bg-dark-400 mb-16 rounded-xl p-16">
    <div
      ref="wrapImage"
      class="custom-control-image_upload group/upload bg-dark-300 hover:bg-dark-150 group relative flex h-[126px] w-full items-center justify-center overflow-hidden rounded-xl transition-all"
      :class="{
        'hover:bg-dark-400': imageSrc && !errorMessage,
        'transparent-bg bg-dark-400': imageSrc && isLoaded,
        'animated-background bg-transparent ': !!imageSrc && !isLoaded,
      }">
      <OverlayActions :is-invalid-src="!!errorMessage" />
      <LoadingIndicator v-if="isUploading" />
      <img
        v-if="!isUploading && imageSrc && !errorMessage"
        alt="preview-image"
        :class="imageClasses"
        :src="`${previewImageSrc}`"
        @load="isLoaded = true" />
    </div>
    <LinkInput :is-active="isActiveSourceInput" :error-message="errorMessage" />
    <BrowseGalleryTrigger @open-gallery="openGallery" />
    <Gallery v-bind="props" :is-open="isOpenGallery" :gallery-data="gallery" />
    <WarningPopup :show="showAlert" :type="alertType" :maximum-size="maximumSize" @close="showAlert = false" />
  </div>
</template>

<style lang="scss" scoped>
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #494949 10%, #252525 18%, #494949 33%);
  background-size: 800px 100%;
  position: relative;
}

.custom-control-image_upload:hover .custom-upload-image-file {
  visibility: visible;
}

.transparent-bg {
  background-image: url('../assets/bg-transparent.png');
}
</style>
