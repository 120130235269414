import type { ItemManagementProps, ItemManagementEmits, ItemManagementContext } from '../../types';

import { provide } from 'vue';
import { useItemManagementState } from './useItemManagementState';
import { useItemManagementActions } from './useItemManagementActions';

export function useCreateItemManagementContext(
  props: ItemManagementProps,
  emit: ItemManagementEmits,
): ItemManagementContext {
  const state = useItemManagementState(props);
  const actions = useItemManagementActions(state, props, emit);

  const context: ItemManagementContext = {
    state,
    props,
    actions,
  };

  provide('itemManagementContext', context);

  return context;
}
