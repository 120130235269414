import { ref } from 'vue';
import type { EmitTypeSearchComponent } from '../types';
import { useSearchListProduct } from './useSearchListProduct';

export const useHandleDataSearchComponent = (emit: EmitTypeSearchComponent) => {
  const isSearchSetting = ref<boolean>(false);
  const inputSearch = ref<HTMLElement>();
  const searchKeyword = ref<string>('');

  const { closeSearchSetting, onChangeSearch, openSearchSetting } = useSearchListProduct(
    isSearchSetting,
    inputSearch,
    searchKeyword,
    emit,
  );

  const onBlurInputSearch = () => {
    return !searchKeyword.value && closeSearchSetting();
  };
  return {
    closeSearchSetting,
    onChangeSearch,
    openSearchSetting,
    inputSearch,
    onBlurInputSearch,
    searchKeyword,
    isSearchSetting,
  };
};
