import { cloneDeepObject } from '@gem/common';
import type { FontItem, FontName } from '@gem/common';
import type { FontCategory, FontListItemType, NestedValue } from './types';
import type { TypographyFamily, TypographyFontOption, TypographyV2Props } from '@gem/control';

export const mergeFonts = (
  baseFonts: TypographyFontOption[],
  additionalFonts: TypographyFontOption[],
): TypographyFontOption[] => {
  const combinedArray = [...baseFonts, ...additionalFonts];

  const mergedMap = combinedArray.reduce((uniqueFonts, font) => {
    if (!font?.value) return uniqueFonts;

    const existingFont = uniqueFonts.get(font.value);

    uniqueFonts.set(font.value, existingFont ? { ...existingFont, ...font } : font);

    return uniqueFonts;
  }, new Map<string, TypographyFontOption>());

  return Array.from(mergedMap.values());
};

export const getFontFamilyStyle = ({ font, isVariable }: { font?: string; isVariable?: boolean }) => {
  if (!font) return undefined;

  if (isVariable || font?.includes('--g-theme-font')) {
    return `var(${font})`;
  }

  return font === 'sans-serif' ? font : `'${font}'`;
};

export const getFontStyles = (fontFamily?: string) => {
  return {
    'font-weight': '400',
    ...(fontFamily && { 'font-family': `${fontFamily}` }),
  };
};

export const getFontLabel = (value?: string, themeFonts?: Record<string, FontItem>): string => {
  if (value?.includes('--g-theme-font')) {
    const typeValue = value.replace('--g-theme-font-', '') as FontName;
    return themeFonts?.[typeValue]?.family ?? value ?? '';
  }

  return value ?? '';
};

export const getThemeFontVariable = (
  value?: string,
  type?: string,
  themeFonts?: Record<string, FontItem>,
): string | undefined => {
  if (type !== 'theme') return;

  if (value === themeFonts?.heading?.family) {
    return `--g-theme-font-heading`;
  }

  if (value === themeFonts?.body?.family) {
    return `--g-theme-font-body`;
  }
};

export const createFontList = (
  fonts: TypographyFamily[] | TypographyFontOption[],
  label: FontCategory,
  isRecently?: boolean,
): FontListItemType[] => {
  if (!fonts) return [];

  const dataFonts = cloneDeepObject(fonts);

  const fontList = dataFonts.map((item): FontListItemType => {
    return isRecently
      ? {
          value: hasNestedValue(item.value) ? item.value.value : item.value,
          type: hasNestedValue(item.value) ? item.value.type : 'google',
          isRecently: true,
        }
      : {
          ...item,
          type: item?.type || 'google',
        };
  });

  if (fontList.length > 0) {
    fontList.unshift({
      label,
      isLabel: true,
    } as FontListItemType);
  }

  return fontList;
};

const hasNestedValue = (value: unknown): value is NestedValue => {
  return typeof value === 'object' && value !== null && 'value' in value;
};

export const getCurrentFontOption = (
  fontOptions: TypographyFontOption[],
  fontFamily: TypographyV2Props['fontFamily'],
): TypographyFontOption | undefined => {
  if (typeof fontFamily === 'string') {
    const font = fontOptions?.find((item) => item.value === fontFamily);

    return (
      font ?? {
        label: fontFamily,
        value: fontFamily,
        type: 'google',
      }
    );
  }

  if (!fontFamily?.value) return;

  const font = fontOptions?.find((item) => item.value === fontFamily.value);

  return (
    font ?? {
      type: fontFamily.type,
      label: fontFamily.value,
      value: fontFamily.value,
    }
  );
};

export const isFontMatch = ({
  inputFont,
  themeFonts,
  currentFont,
}: {
  inputFont: TypographyFamily;
  themeFonts: Record<string, FontItem> | undefined;
  currentFont: string | TypographyFamily | undefined;
}): boolean => {
  if (!currentFont) return false;

  const resolveFont = (font: string): string => {
    return getFontLabel(font, themeFonts)?.toLowerCase()?.trim() || '';
  };

  if (typeof currentFont === 'string') {
    return resolveFont(inputFont.value) === resolveFont(currentFont);
  }

  const resolvedInputFont = resolveFont(inputFont.value);
  const resolvedCurrentFont = resolveFont(currentFont.value);

  return resolvedInputFont === resolvedCurrentFont && inputFont?.type === currentFont.type;
};
