import type { Ref } from 'vue';
import type { ItemProps } from '../types';

import { nextTick, ref, watch, computed } from 'vue';
import { useItemManagementContext } from './context/useItemManagementContext';

type UseItemTitleOptions = {
  inputRef: Ref<HTMLInputElement | null>;
};

export const useItemTitle = (props: ItemProps, { inputRef }: UseItemTitleOptions) => {
  const { state, actions } = useItemManagementContext();

  const title = ref(actions.handleNormalizeItemTitle(props.item?.title));
  const originalTitle = ref(title.value);
  const shouldSkipBlur = ref(false);

  const handleCheckIsEditingTitle = (id: string) => state.editingId.value === id;

  const handleCheckIsActiveItem = (id: string) => {
    if (!props.tag) return false;
    return props.isExpand ? state.activeIds.value?.includes(id) : false;
  };

  const handleOnChangeTitle = (value: string) => {
    if (title.value === value) return;
    title.value = value;
    if (props.index) actions.handleOnChangeTitle(value, props.index);
  };

  const handleEditTitleItem = () => {
    if (!props.index) return;

    originalTitle.value = title.value;
    shouldSkipBlur.value = false;

    actions.handleEditTitleState(props.index);
    nextTick(() => inputRef.value?.focus());
  };

  const handleCancelEditTitle = () => {
    if (!props.index) return;

    shouldSkipBlur.value = true;

    title.value = originalTitle.value;

    if (originalTitle.value) {
      actions.handleChangeTitle(originalTitle.value, props.index);
    }

    actions.handleBlurTitleState(props.index);
  };

  const handleConfirmEditTitle = () => {
    if (!props.index) return;

    shouldSkipBlur.value = true;

    actions.handleBlurTitleState(props.index);

    if (props.item?.title === title.value) return;

    const newTitle = title.value?.trim()?.length === 0 || !title.value ? state.itemInfo.value.titleItem : title.value;

    actions.handleChangeTitle(newTitle, props.index);
    originalTitle.value = newTitle;
  };

  const handleOnBlur = (value: Event | KeyboardEvent) => {
    if (!props.index) return;

    if (shouldSkipBlur.value) {
      shouldSkipBlur.value = false;
      return;
    }

    actions.handleBlurTitleState(props.index);

    const currentValue = (value?.target as HTMLInputElement)?.value?.trim();

    if (currentValue?.length === 0) {
      actions.handleChangeTitle(state.itemInfo.value.titleItem, props.index);
      return;
    }

    if (currentValue === title.value) return;

    actions.handleChangeTitleOnEvent(value, props.index);
  };

  const isEditingTitle = computed(() => {
    if (!props.index) return false;
    return handleCheckIsEditingTitle(props.index);
  });

  const isActive = computed(() => {
    if (!props.index) return false;
    return handleCheckIsActiveItem(props.index);
  });

  watch(
    () => props.item?.title,
    (value) => {
      title.value = value;

      if (!isEditingTitle.value) {
        originalTitle.value = value;
      }
    },
  );

  return {
    title,
    isActive,
    isEditingTitle,
    handleOnBlur,
    handleOnChangeTitle,
    handleEditTitleItem,
    handleCancelEditTitle,
    handleConfirmEditTitle,
    handleCheckIsActiveItem,
    handleCheckIsEditingTitle,
  };
};
