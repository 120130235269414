<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path d="M17.1875 18.75C17.5327 18.75 17.8125 18.4702 17.8125 18.125V1.875C17.8125 1.52982 17.5327 1.25 17.1875 1.25C16.8423 1.25 16.5625 1.52982 16.5625 1.875V18.125C16.5625 18.4702 16.8423 18.75 17.1875 18.75Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9375 7.5C5.9375 8.53553 6.77697 9.375 7.8125 9.375H14.0625C15.098 9.375 15.9375 8.53553 15.9375 7.5V6.25C15.9375 5.21447 15.098 4.375 14.0625 4.375H7.8125C6.77697 4.375 5.9375 5.21447 5.9375 6.25V7.5ZM7.8125 8.125C7.46732 8.125 7.1875 7.84518 7.1875 7.5V6.25C7.1875 5.90482 7.46732 5.625 7.8125 5.625H14.0625C14.4077 5.625 14.6875 5.90482 14.6875 6.25V7.5C14.6875 7.84518 14.4077 8.125 14.0625 8.125H7.8125Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.0625 15.625C3.02697 15.625 2.1875 14.7855 2.1875 13.75V12.5C2.1875 11.4645 3.02697 10.625 4.0625 10.625H14.0625C15.098 10.625 15.9375 11.4645 15.9375 12.5V13.75C15.9375 14.7855 15.098 15.625 14.0625 15.625H4.0625ZM3.4375 13.75C3.4375 14.0952 3.71732 14.375 4.0625 14.375H14.0625C14.4077 14.375 14.6875 14.0952 14.6875 13.75V12.5C14.6875 12.1548 14.4077 11.875 14.0625 11.875H4.0625C3.71732 11.875 3.4375 12.1548 3.4375 12.5V13.75Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'AlignRight'
};
</script>
