<script lang="ts" setup>
import { ThemePageType } from '../../../../type/common';
import { DYNAMIC_PRODUCT_STATUS } from '../constant';
import { useHandleDataSearchComponent } from '../hooks/useHandleDataSearchComponent';
import type { EmitTypeSearchComponent, SearchComponentProps } from '../types';

defineProps<SearchComponentProps>();
const emit = defineEmits<EmitTypeSearchComponent>();

const {
  closeSearchSetting,
  onChangeSearch,
  openSearchSetting,
  inputSearch,
  onBlurInputSearch,
  searchKeyword,
  isSearchSetting,
} = useHandleDataSearchComponent(emit);

defineExpose({
  closeSearchSetting,
  searchKeyword,
  isSearchSetting,
});
</script>

<template>
  <div :class="{ 'w-full': isSearchSetting }" class="px-16" data-test="editor-control-search-setting">
    <div v-if="isSearchSetting" class="w-full items-center">
      <div class="bg-dark-500 text-light-450 relative h-36 w-full">
        <div class="z-5 absolute top-0 flex h-36 items-center px-[11.5px]">
          <g-base-icon name="search" width="13" height="13" viewBox="0 0 16 16" />
        </div>
        <g-input
          ref="inputSearch"
          type="text"
          input-style="secondary"
          placeholder="Search product"
          title="Search in Settings & Advanced"
          :value="searchKeyword"
          classes="!pl-36 h-36 text-12 leading-6 font-regular"
          @blur="onBlurInputSearch"
          @on-change="onChangeSearch" />
      </div>
    </div>
    <div v-else class="flex h-36 items-center justify-between">
      <div class="text-12 font-regular text-text-dark-300 leading-5">
        {{
          productShowMode === DYNAMIC_PRODUCT_STATUS && pageType === ThemePageType.GP_PRODUCT
            ? 'Choose product to preview'
            : 'Pick product'
        }}
      </div>
      <div
        data-test="editor-search-setting-open"
        class="gemx-sidebar-header-icon text-light-100 hover:bg-dark-250 flex cursor-pointer select-none items-center justify-center rounded-xl p-10 transition duration-200"
        @click="openSearchSetting">
        <g-base-icon name="search" width="16" height="16" viewBox="0 0 20 20" />
      </div>
    </div>
  </div>
</template>
