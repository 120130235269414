import type { Maybe, PageSection } from '@/types/graphql';
import {
  actionAddSection,
  actionDeleteAllSections,
  actionDeleteSection,
  actionDuplicateSection,
  actionMoveSection,
  actionUpdateDisplaySection,
  actionUpdateNameSection,
  actionMoveComponentToNewSection,
  actionReplaceSection,
  actionRestoreVersionHistory,
  actionUpdateComponentSection,
  actionMoveComponentSection,
  actionUpdateVisibilitySection,
} from '../../history/use-cases/action';
import useSectionStore from '../stores/section';
import { historyCreate } from '../../history/use-cases/history';
import {
  builderAddSection,
  builderApplyChangeset,
  builderDeleteSection,
  builderGetChangeset,
  builderInit,
} from '../../preview/utils/builder';
import useEditorStore from '../stores/editor';
import {
  cacheAddComponentBySection,
  cacheClear,
  cacheDeleteComponentBySectionCid,
  cacheGetSectionCidByComponentUid,
} from './cache';
import { RenderSectionCID } from '@/utils/id';
import type { ActionOptions, Section } from '../utils/types';
import { cloneDeepObject } from '@/utils/common';
import {
  convertComponentToJSON,
  convertComponentToString,
  generateNewUid,
  getComponentByUid,
  updateWidgetTypeSettingComponent,
} from '../utils/section/component';
// import { arrayUnique } from '@/utils/arrayUnique';
import { event } from './event';
import useNotification from '@/composables/useNotification';
import { sentryCaptureException } from './sentry';
import useHistoryStore from '../../history/stores/history';
import { STICKY_TAG } from '../utils/const';
import { useCleanComponentData } from '@/modules/editor/modules/common/hooks/useCleanComponentData';
import { useSwitchPage } from '../../header/stores/switchPage';
import { TrackInitTimeChildOp, trackInitTimeFinishChildOp, TrackInitTimeChildOpStatus } from '@/core/performance';

export const sectionInit = (sections: Section[], order?: Maybe<string[]>) => {
  const editorStore = useEditorStore();
  const sectionStore = useSectionStore();
  const getLinkedThemeSectionIds = computed(() => sectionStore.getLinkedThemeSectionIds);

  sectionStore.clear();
  const sortedSections: Section[] = [];

  // Sort
  if (order?.length && sections?.length) {
    sortSectionByOrder(sections, order, sortedSections);

    // Add other section not in order
    sections.forEach((section) => {
      const found = sortedSections.find((item) => item?.id == section.id);
      if (!found) {
        sortedSections.push(section);
      }
    });
  } else {
    sections.forEach((section) => {
      sortedSections.push(section);
    });
  }
  if (sortedSections?.length) {
    sectionStore.setListSectionSnapshot(sortedSections);
    for (let i = 0; i < sortedSections.length; i++) {
      const section = sortedSections[i];

      if (!section.cid) {
        section.cid = getSectionCID();
      }

      if (section.isThemeSection) {
        sectionStore.setLinkedThemeSectionId([...getLinkedThemeSectionIds.value, section.id]);
      }

      sectionStore.setItem(section, i);

      cacheAddComponentBySection(section);
    }
  }

  // Success update section form remote store to local store
  editorStore.setInitPushSectionToStore(true);
  trackInitTimeFinishChildOp(TrackInitTimeChildOp.FETCH_THEME_PAGE, {
    trackInitTimeChildOpStatus: TrackInitTimeChildOpStatus.SUCCESS,
  });
};

const handleStickyElement = (section: Section) => {
  const sectionStore = useSectionStore();
  const stickies = sectionStore.stickies;
  let cId;
  if (stickies.length) {
    cId = stickies.pop()?.cid;
  }
  sectionCreate(section, {
    cid: cId,
    direction: cId ? 'after' : 'before',
  });
};

export const handleDeleteSections = (sections: Section[]) => {
  const sectionStore = useSectionStore();
  const sectionStoreItems = sectionStore.getItems;
  sections.forEach((section) => {
    if (section.cid) sectionStore.deleteIdInDeleted(section.id);
  });
  const deletedSections = sectionStoreItems.filter((item) => !sections.find((section) => section.id == item.id));
  deletedSections.forEach((section) => {
    if (section.cid) sectionStore.deleteItem(section.cid);
  });
};

export const handleAllStickyElement = (sections: Section[]) => {
  sections.forEach((section) => {
    if (section.name == STICKY_TAG) {
      handleStickyElement(section);
    }
  });
};

const linkThemeSection = (section: Section) => {
  if (section.isThemeSection) {
    const sectionStore = useSectionStore();
    const getLinkedThemeSectionIds = computed(() => sectionStore.getLinkedThemeSectionIds);
    sectionStore.setLinkedThemeSectionId([...getLinkedThemeSectionIds.value, section.id]);
    sectionStore.removeFromUnlinkThemeSectionList(section.id);
  }
};

const handleOtherSection = (sections: Section[], isRestoreFormVersionHistory?: boolean) => {
  const sectionStore = useSectionStore();
  sections.forEach((section) => {
    cacheAddComponentBySection(section);
    if (section.name === STICKY_TAG || !isRestoreFormVersionHistory) return;
    linkThemeSection(section);
  });
  sectionStore.replaceItems(sections);
};

export const restoreSections = (
  sections: Section[],
  options?: ActionOptions & {
    callback?: Function;
    notChangeCid?: boolean;
    isRestoreFormVersionHistory?: boolean;
    listOfHistory?: Section[];
  },
) => {
  const sectionStore = useSectionStore();
  const currentSections = sectionStore.getItems;
  const newSections = cloneDeepObject(sections);
  handleDeleteSections(newSections);
  handleAllStickyElement(newSections);
  handleOtherSection(newSections, options?.isRestoreFormVersionHistory);
  builderInit(newSections, () => {
    options?.callback && options?.callback();
  });
  if (options?.noRecordHistory) return;
  historyCreate(
    actionRestoreVersionHistory({
      oldSections: currentSections,
      newSections: newSections,
      isRestoreFormVersionHistory: options?.isRestoreFormVersionHistory,
      listOfHistory: options?.listOfHistory,
    }),
  );
};

export const replaceSections = (
  sections: Section[],
  order?: Maybe<string[]>,
  options?: ActionOptions & {
    callback?: Function;
  },
) => {
  const editorStore = useEditorStore();
  const sectionStore = useSectionStore();
  const getLinkedThemeSectionIds = computed(() => sectionStore.getLinkedThemeSectionIds);
  const { setIsSwitchingPage } = useSwitchPage();

  sectionStore.clear();
  const sortedSections: Section[] = [];
  // Sort
  if (order?.length && sections?.length) {
    // order = arrayUnique(order);
    sortSectionByOrder(sections, order, sortedSections);

    // Add other section not in order
    sections.forEach((section) => {
      const found = sortedSections.find((item) => item?.id == section.id);
      if (!found) {
        sortedSections.push(section);
      }
    });
  } else {
    sections.forEach((section) => {
      sortedSections.push(section);
    });
  }
  if (sortedSections?.length) {
    sectionStore.setListSectionSnapshot(sortedSections);
    for (let i = 0; i < sortedSections.length; i++) {
      const section = sortedSections[i];

      if (!section.cid) {
        section.cid = getSectionCID();
      }

      if (section.isThemeSection) {
        sectionStore.setLinkedThemeSectionId([...getLinkedThemeSectionIds.value, section.id]);
      }

      sectionStore.setItem(section, i);

      cacheAddComponentBySection(section);
    }
  }
  builderInit(sortedSections, () => {
    options?.callback && options?.callback();
  });
  // Success update section form remote store to local store
  editorStore.setInitPushSectionToStore(true);
  setIsSwitchingPage(false);
  editorStore.setInitLoadingTemplate(false);
  editorStore.setInitLoadingGlobalStyle(false);
};

const sortSectionByOrder = (sections: Section[], order: string[], sortedSections: Section[]) => {
  for (const idByOrder of order) {
    const section = sections.find((item) => item?.id === idByOrder);
    if (!section) {
      return;
    }
    const isDuplicate = sortedSections.find((item) => item.id === section.id);
    const newSection = isDuplicate ? getNewSectionWhenDuplicated(section) : section;
    sortedSections.push(newSection);
  }
};

const getNewSectionWhenDuplicated = (section: Section) => {
  const component = convertComponentToJSON(section?.component || '');

  if (!component) {
    return section;
  }

  generateNewUid(component);

  return {
    ...section,
    cid: getSectionCID(),
    component: convertComponentToString(component),
  };
};

export const getSectionCID = () => {
  const historyCIDs = useHistoryStore().getCids;
  const sectionStoreCIDs = useSectionStore().getItems.map((item) => item.cid as string);
  const expelStoreCIDs = [...historyCIDs, ...sectionStoreCIDs];
  return RenderSectionCID(expelStoreCIDs);
};

/**
 * sectionCreate
 * @param section
 * @param position
 * - Cid != undefined -> before/after cid
 * - Cid == undefined -> before/after all sections
 * @param options
 */
export const sectionCreate = (
  section: Section,
  position: { cid?: Maybe<string>; direction: 'before' | 'after'; replaceIndex?: number },
  options?: ActionOptions,
) => {
  const { cleanAdvancedSetting } = useCleanComponentData();
  // History
  if (!options?.noRecordHistory) {
    historyCreate(
      actionAddSection({
        section: JSON.parse(JSON.stringify(section)),
        position,
      }),
    );
  }

  const { jsonComponent } = updateWidgetTypeSettingComponent(JSON.parse(section.component ?? '{}'));
  section.component = JSON.stringify(cleanAdvancedSetting(jsonComponent));
  const sectionStore = useSectionStore();
  const sections = sectionStore.getItems;
  const { cid, direction, replaceIndex } = position;

  let index;
  if (cid) {
    const foundIndex = sections.findIndex((item) => item.cid == position.cid);
    index = direction == 'before' ? foundIndex : foundIndex + 1;
  } else if (replaceIndex) {
    index = replaceIndex;
  } else {
    index = direction == 'before' ? 0 : sections.length;
  }
  sectionStore.setItem(section, index);
  // Link Theme Section
  const linkThemeSectionIds = sectionStore.getLinkThemeSectionIds;
  if (options?.isHandleThemeSection && !options.isRefreshThemeSection) {
    sectionStore.setLinkThemeSectionId([...linkThemeSectionIds, section?.id]);
  }

  // Add to preview
  if (!options?.noApplyToPreview) {
    const order = sectionStore.getPosition(section.cid ?? '');
    builderAddSection(section, order, () => {
      event.emit('toolbar-active-refresh');
    });
  }

  // Update cache
  cacheAddComponentBySection(section);
};

export const sectionDelete = (cid: string, options?: ActionOptions) => {
  const sectionStore = useSectionStore();
  const editorStore = useEditorStore();

  const section = sectionStore.getItemByCid(cid);

  if (section?.cid) {
    // History
    if (!options?.noRecordHistory) {
      const oldSection = JSON.parse(JSON.stringify(section));
      if (!section.isShopifySection) {
        delete oldSection.id; // Add new section without old id
      }
      const beforeSection = sectionStore.getSectionBefore(oldSection.cid);

      historyCreate(
        actionDeleteSection({
          section: oldSection,
          position: {
            cid: beforeSection?.cid,
            direction: beforeSection?.cid ? 'after' : 'before',
          },
        }),
      );
    }

    if (options?.isHandleThemeSection && section?.isThemeSection && !options.isRefreshThemeSection) {
      sectionStore.setUnlinkThemeSectionId(cid);
    } else {
      sectionStore.deleteItem(cid);
    }

    if (!options?.noApplyToPreview) {
      builderDeleteSection(section);
    }

    cacheDeleteComponentBySectionCid(cid);

    // Close sidebar setting if editing section or child component
    if (options?.preventRemoveEditingComponent) return;

    const editingComponentUid = editorStore.getEditingComponentUid;
    if (editingComponentUid) {
      const parentCid = cacheGetSectionCidByComponentUid(editingComponentUid);

      if (parentCid == cid || !parentCid) {
        editorStore.setEditingComponentUid(null);
      }
    }
  }
};

export const sectionMove = (cid: string, afterCid: string | null, options?: ActionOptions) => {
  const sectionStore = useSectionStore();
  const { cleanSectionData } = useCleanComponentData();

  const section = cleanSectionData(sectionStore.getItemByCid(cid) ?? ({} as Section));
  if (section?.cid) {
    // History
    if (!options?.noRecordHistory) {
      const oldAfterCid = sectionStore.getSectionBefore(cid)?.cid || null;
      historyCreate(actionMoveSection({ cid, oldAfterCid, afterCid }));
    }

    sectionStore.moveItem(cid, afterCid);

    if (!options?.noApplyToPreview) {
      builderDeleteSection(section, () => {
        const order = sectionStore.getPosition(section?.cid || '');
        setTimeout(() => {
          builderAddSection(section, order, () => {
            event.emit('toolbar-active-refresh');
          });
        }, 0);
      });
    }
  }
};

export const sectionUpdateComponent = ({
  cid,
  newComponent,
  options,
}: {
  cid: string;
  newComponent: string; // component of section after change
  options?: ActionOptions;
}) => {
  const editorStore = useEditorStore();
  const sectionStore = useSectionStore();
  const { cleanSectionData } = useCleanComponentData();

  const section = cleanSectionData(sectionStore.getItemByCid(cid) ?? ({} as Section));
  if (!section) {
    return;
  }

  const oldComponent = section.component;
  if (!oldComponent) {
    return;
  }

  // History
  if (!options?.noRecordHistory) {
    historyCreate(actionUpdateComponentSection({ cid, oldComponent, newComponent }));
  }

  if (section?.cid) {
    const clone = cloneDeepObject(section) as PageSection;
    clone.component = newComponent;
    sectionStore.update(clone);

    const paths = builderGetChangeset(oldComponent, newComponent);

    // Update preview iframe
    if (!options?.noApplyToPreview) {
      builderApplyChangeset(paths);
    }

    const removeUids: string[] = [];
    for (const path of paths) {
      if (path.type === 'remove') {
        path.uid && removeUids.push(path.uid);
      }
    }
    // Close sidebar setting if delete editing component
    if (removeUids.length > 0) {
      const editingComponentUid = editorStore.getEditingComponentUid;
      if (editingComponentUid) {
        const jsonComponent = convertComponentToJSON(newComponent || '');
        if (jsonComponent) {
          // Check delete parent editing component
          const isExitsComponent = getComponentByUid(jsonComponent, editingComponentUid);
          if (!isExitsComponent) {
            editorStore.setEditingComponentUid(null);
          }
        }
      }
      // Cache delete component
      cacheDeleteComponentBySectionCid(cid);
    }

    cacheAddComponentBySection(clone);
  }
};

export const sectionMoveComponent = ({
  fromCid,
  fromNewComponent,
  toCid,
  toNewComponent,
  options,
}: {
  fromCid: string;
  fromNewComponent: string;
  toCid: string;
  toNewComponent: string;
  options?: ActionOptions;
}) => {
  const sectionStore = useSectionStore();
  const fromSection = sectionStore.getItemByCid(fromCid);
  const toSection = sectionStore.getItemByCid(toCid);
  const historyStore = useHistoryStore();
  if (!toSection) {
    return;
  }

  const toOldComponent = toSection.component;
  const fromOldComponent = fromSection?.component;
  if (!toOldComponent || !fromOldComponent) {
    return;
  }

  // History
  if (!options?.noRecordHistory) {
    historyCreate(
      actionMoveComponentSection({
        fromCid,
        fromOldComponent,
        fromNewComponent,
        toCid,
        toOldComponent,
        toNewComponent,
      }),
    );
  }

  if (toSection?.cid && fromSection?.cid) {
    historyStore.setCids([...new Set([...historyStore.getCids, toSection.cid])]);
    const cloneFrom = cloneDeepObject(fromSection) as PageSection;
    const cloneTo = cloneDeepObject(toSection) as PageSection;
    cloneFrom.component = fromNewComponent;
    sectionStore.update(cloneFrom);
    cloneTo.component = toNewComponent;
    sectionStore.update(cloneTo);

    const pathsFrom = builderGetChangeset(fromOldComponent, fromNewComponent);
    const pathsTo = builderGetChangeset(toOldComponent, toNewComponent);

    // Update preview iframe
    if (!options?.noApplyToPreview) {
      builderApplyChangeset(pathsFrom);
      builderApplyChangeset(pathsTo);
    }

    if (cloneTo) {
      cacheAddComponentBySection(cloneTo);
    }
  }
};

export const sectionUpdateDisplay = (cid: string, value: boolean, options?: ActionOptions) => {
  const sectionStore = useSectionStore();
  const { cleanSectionData } = useCleanComponentData();
  const section = cleanSectionData(sectionStore.getItemByCid(cid) ?? ({} as Section));

  if (section) {
    // History
    if (!options?.noRecordHistory) {
      historyCreate(actionUpdateDisplaySection({ cid, oldValue: section?.display ?? true, newValue: value }));
    }

    const clone = JSON.parse(JSON.stringify(section));
    clone.display = value;
    sectionStore.update(clone);

    if (!options?.noApplyToPreview) {
      if (clone.display) {
        const order = sectionStore.getPosition(clone.cid);
        builderAddSection(clone, order, () => {
          event.emit('toolbar-active-refresh');
        });
      } else {
        builderDeleteSection(clone);
      }
    }
  }
};

export const sectionUpdateVisibility = (cid: string, value: boolean, options?: ActionOptions) => {
  const sectionStore = useSectionStore();

  const section = sectionStore.getItemByCid(cid);
  if (section) {
    // History
    if (!options?.noRecordHistory) {
      historyCreate(actionUpdateVisibilitySection({ cid, oldValue: !!section?.isShopifyVisibility, newValue: value }));
    }

    const clone = JSON.parse(JSON.stringify(section));
    clone.isShopifyVisibility = value;
    sectionStore.update(clone);
  }
};

export const sectionUpdateName = (cid: string, newName: string, options?: ActionOptions) => {
  const sectionStore = useSectionStore();

  const section = sectionStore.getItemByCid(cid);
  if (section) {
    // History
    if (!options?.noRecordHistory) {
      historyCreate(actionUpdateNameSection({ cid, oldName: section?.name || '', newName: newName }));
    }

    const clone = JSON.parse(JSON.stringify(section));
    clone.name = newName;
    sectionStore.update(clone);
  }
};

export const sectionDuplicate = (sectionCid: string, options?: ActionOptions) => {
  const { isHandleThemeSection, isHandleShopifySection } = options ?? {};

  const sectionStore = useSectionStore();

  const section = sectionStore.getItemByCid(sectionCid);
  const position = sectionStore.getPosition(sectionCid);

  if (section) {
    const sectionClone = cloneDeepObject(section);
    const component = convertComponentToJSON(sectionClone?.component || '');
    if (component) {
      // Create new section
      generateNewUid(component);

      let newSection: Section;

      if (isHandleShopifySection) {
        const newKeyId = '-gp-dup-' + new Date().getTime();
        newSection = {
          ...section,
          cid: getSectionCID(),
          component: convertComponentToString(component),
          id: section.id + newKeyId,
          name: section.name,
        };
      } else if (isHandleThemeSection) {
        newSection = {
          ...section,
          cid: getSectionCID(),
          component: convertComponentToString(component),
        };
      } else {
        newSection = {
          id: '', // create new id == 0
          cid: getSectionCID(),
          isCustom: sectionClone.isCustom,
          isCustomNew: !!sectionClone.isCustom,
          name: `Section ${component?.uid}`,
          display: true,
          component: convertComponentToString(component),
        };
      }

      // History
      if (!options?.noRecordHistory) {
        historyCreate(
          actionDuplicateSection({
            cid: sectionCid,
            sectionDuplicated: newSection,
          }),
        );
      }

      // Add to store
      sectionStore.setItem(newSection, position + 1);

      // Add to preview
      if (!options?.noApplyToPreview && newSection.cid) {
        const order = sectionStore.getPosition(newSection?.cid);
        builderAddSection(newSection, order, () => {
          event.emit('toolbar-active-refresh');
        });
      }

      // Update cache
      cacheAddComponentBySection(newSection);

      return component?.uid ?? '';
    }
  }
};

export const sectionsDeleteAll = () => {
  createHistoryForDeleteAllSections();
  builderClearAllSection();
  clearSectionInStore();
  cacheClear();
  resetEditingComponent();
};

const createHistoryForDeleteAllSections = () => {
  const sectionStore = useSectionStore();
  const allDeleteSections = sectionStore.items.map((section) => {
    const oldSection = JSON.parse(JSON.stringify(section));
    delete oldSection.id;
    const beforeSection = sectionStore.getSectionBefore(oldSection.cid);
    const direction: 'after' | 'before' = beforeSection?.cid ? 'after' : 'before';
    return {
      section: oldSection,
      position: {
        cid: beforeSection?.cid,
        direction: direction,
      },
    };
  });
  historyCreate(actionDeleteAllSections({ list: allDeleteSections }));
};

const builderClearAllSection = () => {
  const sectionStore = useSectionStore();
  sectionStore.items.forEach((section) => {
    builderDeleteSection(section);
  });
};

const clearSectionInStore = () => {
  const sectionStore = useSectionStore();
  sectionStore.deleteAll();
};

const resetEditingComponent = () => {
  const editorStore = useEditorStore();
  editorStore.setEditingComponentUid(null);
};

export const moveComponentToNewSection = ({
  componentUid,
  fromCid,
  fromComponent,
  newSection,
  position,
  isUndo,
  options,
}: {
  componentUid: string;
  fromCid: string;
  fromComponent: string;
  newSection: Section;
  position: { cid?: Maybe<string>; direction: 'before' | 'after' };
  isUndo?: boolean;
  options?: ActionOptions;
}) => {
  const editorStore = useEditorStore();
  const sectionStore = useSectionStore();
  const { handleError } = useNotification();

  const fromSection = sectionStore.getItemByCid(fromCid);
  if (!fromSection) {
    sentryCaptureException('moveComponentToNewSection', "Can't find section by from cid", {
      fromCid,
    });
    handleError("Can't find section by from cid");
    return;
  }

  const cloneFromSection = cloneDeepObject(fromSection);
  const oldFromComponent = cloneFromSection.component;
  if (!options?.noRecordHistory) {
    if (oldFromComponent) {
      historyCreate(
        actionMoveComponentToNewSection({
          componentUid,
          fromCid,
          newFromComponent: fromComponent,
          oldFromComponent: oldFromComponent,
          newSection,
          position,
        }),
      );
    }
  }

  if (isUndo) {
    // Delete section before cache restore old component
    sectionDelete(newSection?.cid || '', { ...options, noRecordHistory: true });
  }

  cloneFromSection.component = fromComponent;
  sectionStore.update(cloneFromSection);
  cacheAddComponentBySection(cloneFromSection);

  // Update preview iframe delete component old section
  if (!options?.noApplyToPreview) {
    if (oldFromComponent) {
      const paths = builderGetChangeset(oldFromComponent, fromComponent);
      builderApplyChangeset(paths);
    }
  }

  if (!isUndo) {
    // Change store and preview
    sectionCreate(newSection, position, { ...options, noRecordHistory: true });
  }

  if (!newSection.cid || !newSection.component) {
    sentryCaptureException('moveComponentToNewSection', "Can't find section by cid, component", {
      newSection,
    });
    handleError("Can't find section by cid, component");
    return;
  }

  editorStore.setEditingComponentUid(componentUid);
};

export const sectionReplace = ({
  oldSection,
  newSection,
  options,
}: {
  oldSection: Section;
  newSection: Section;
  options?: ActionOptions;
}) => {
  const sectionStore = useSectionStore();

  const findIndex = sectionStore.getPosition(oldSection?.cid || '');

  if (findIndex === -1) return;

  sectionDelete(oldSection?.cid || '', {
    noRecordHistory: true,
    preventRemoveEditingComponent: true,
    isHandleThemeSection: oldSection?.isThemeSection || newSection?.isThemeSection,
    isRefreshThemeSection: options?.isRefreshThemeSection,
  });
  sectionCreate(
    newSection,
    {
      direction: 'before',
      replaceIndex: findIndex,
    },
    {
      noRecordHistory: true,
      isHandleThemeSection: newSection?.isThemeSection,
      isRefreshThemeSection: options?.isRefreshThemeSection,
    },
  );

  if (!options?.noRecordHistory) {
    historyCreate(
      actionReplaceSection({
        oldSection,
        newSection,
        findIndex,
      }),
    );
  }

  cacheAddComponentBySection(newSection);
};
