<script setup lang="ts">
import type { ActionSettingInput, GridSettingInput, RowLayoutProps } from '@gem/element-setting-ui';
import { PresetSettingLayout, SettingLayout, ColumnWithSlider } from '@gem/element-setting-ui';
import ProductLayoutRecommend from './components/ProductLayoutRecommend.vue';
import { useRowLayoutSettingsUpdate } from './hooks/useRowLayoutSettingsUpdate';
import type { ScreenType } from '@gem/common';

import ColumnOrder from './components/ColumnOrder.vue';
import ColumnWidth from './components/ColumnWidth.vue';
import { computed } from 'vue';
import type { SvgName } from '@gem/icons';

const props = withDefaults(defineProps<RowLayoutProps>(), {
  maxCol: 6,
  currentScreen: 'desktop',
  isSupportResponsiveOrder: true,
});

const emit = defineEmits<{
  (
    e: 'control-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (
    e: 'control-on-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (e: 'control-change-children', input: ActionSettingInput[], device: ScreenType): void;
  (e: 'control-change-layout', input: GridSettingInput[]): void;
  (e: 'remove-item', uid: string): void;
  (e: 'copy-item', uid: string): void;
  (e: 'add-item'): void;
}>();

const {
  orderItems,
  isShowColumnWithSlider,
  handleChangeLayout,
  handleReOrder,
  handleCopyItem,
  handleRemoveItem,
  handleUpdateCols,
  handleChangeDisplay,
  getReOrderValue,
} = useRowLayoutSettingsUpdate(props, emit);

const presetContent = computed((): { icon: SvgName; content: string } => {
  const cols = props.value?.cols?.length;
  switch (cols) {
    case 1:
      return {
        icon: 'gp-product-layout-vertical',
        content: 'Vertical',
      };
    case 2:
      return {
        icon: 'gp-product-layout-horizontal',
        content: 'Horizontal',
      };
    default:
      return {
        icon: 'gp-product-layout-horizontal',
        content: `${cols} columns`,
      };
  }
});

const onReOrder = (data: { id?: string; title?: string }[]) => {
  handleReOrder(getReOrderValue(data, props.currentScreen));
};
</script>

<template>
  <PresetSettingLayout
    :popover-label="{ en: 'Layout' }"
    :show-label="false"
    :icon="presetContent.icon"
    :content-display="presetContent.content">
    <div class="flex flex-col gap-16">
      <SettingLayout layout="vertical" label-variant="secondary" :label="{ en: 'Layout' }">
        <template #control>
          <ProductLayoutRecommend :value="value" @change-layout="(value) => handleChangeLayout(value, currentScreen)" />
        </template>
      </SettingLayout>
      <ColumnOrder
        :childrens="childrens"
        :order-items="orderItems"
        :current-screen="currentScreen"
        :is-support-responsive-order="isSupportResponsiveOrder"
        @copy-item="handleCopyItem"
        @remove-item="handleRemoveItem"
        @on-re-order="onReOrder" />
      <SettingLayout
        v-if="isShowColumnWithSlider"
        layout="vertical"
        label-variant="secondary"
        :label="{ en: 'Column width' }">
        <template #control>
          <ColumnWithSlider
            :disabled="value?.display !== 'fill'"
            :cols="value?.cols"
            @control-change="(value) => handleUpdateCols('change', value, currentScreen)"
            @control-on-change="(value) => handleUpdateCols('onChange', value, currentScreen)" />
        </template>
      </SettingLayout>
      <ColumnWidth
        :display-type="value?.display"
        @control-change="(value) => handleChangeDisplay(value, currentScreen)" />
    </div>
  </PresetSettingLayout>
</template>
