<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_13907_146907)">
      <path
        d="M11.5193 3.14001L10.3697 5.23765C10.3602 5.25642 10.3602 5.27989 10.3697 5.29866L12.8351 9.79896C12.8589
      9.84354 12.923 9.84354 12.9467 9.79896L16.9156 2.55577C16.9394 2.51353 16.9085 2.46191 16.8586
      2.46191H12.6712C12.1891 2.46191 11.7473 2.72236 11.5193 3.14001Z"
        fill="currentColor" />
      <path
        d="M7.80711 2.46191H3.54605C3.42492 2.46191 3.34654 2.59096 3.40592 2.69655L7.3487 9.89516L3.40354
      17.0985C3.34654 17.2041 3.42254 17.3331 3.54605 17.3331H7.79761C7.89261 17.3331 7.98287 17.2815 8.028
      17.197L11.9708 9.9984C12.0088 9.93036 12.0088 9.84589 11.9708 9.77784L8.0375 2.598C7.99237 2.51353 7.90449 2.46191
      7.80711 2.46191Z"
        fill="currentColor" />
      <path
        d="M11.5193 16.6758L10.3697 14.5781C10.3602 14.5594 10.3602 14.5359 10.3697 14.5171L12.8351 10.0168C12.8589
      9.97225 12.923 9.97225 12.9467 10.0168L16.9133 17.26C16.937 17.3023 16.9061 17.3539 16.8563
      17.3539H12.6688C12.1867 17.3539 11.7449 17.0934 11.5169 16.6758H11.5193Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_13907_146907">
        <rect width="13.5385" height="14.8923" fill="white" transform="translate(3.38452 2.46191)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Gemx',
};
</script>
