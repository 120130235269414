<script setup lang="ts">
import { ref } from 'vue';
import type { ItemInputProps } from '../../types';

defineProps<ItemInputProps>();

const emit = defineEmits<{
  (e: 'blur', value: Event): void;
  (e: 'confirm'): void;
  (e: 'onChange', value: string): void;
}>();

const inputRef = ref<HTMLInputElement | null>(null);

defineExpose({
  value: inputRef.value?.value,
  focus: () => inputRef.value?.focus(),
});

const handleConfirm = () => emit('confirm');
const handleBlur = (value: Event) => emit('blur', value);
const handleOnChange = (value: string) => emit('onChange', value);
</script>

<template>
  <g-input
    v-show="isEditingTitle"
    ref="inputRef"
    class="!bg-dark-400 text-light-450 h-32 w-full rounded-xl leading-[18px]"
    input-style="primary"
    size="small"
    :value="title"
    @blur="handleBlur"
    @keyup.enter="handleConfirm"
    @on-change="handleOnChange" />
</template>
