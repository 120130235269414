<script setup lang="ts">
import GalleryImageItem from './GalleryImageItem.vue';
import type { GalleryDeletedImageListProps } from '../../types';
import { useGalleryDeletedImageList } from '../../hooks/gallery/useGalleryDeletedImageList';

const props = defineProps<GalleryDeletedImageListProps>();

const { imageList, listContainer, handleRestoreImage, handleSetImageLoaded, handleForceDeleteImage } =
  useGalleryDeletedImageList(props);
</script>

<template>
  <div
    class="bg-light-300 h-full rounded-xl ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none">
    <div class="low:h-[70vh] h-[614px] grid-cols-3 gap-6">
      <perfect-scrollbar ref="listContainer" class="p-16">
        <div class="hide-scrollbar grid grid-cols-3 gap-16">
          <GalleryImageItem
            v-for="image in imageList"
            :key="image.id"
            :item="image"
            is-deleted-section
            @restore="handleRestoreImage"
            @set-loaded="handleSetImageLoaded"
            @force-delete="handleForceDeleteImage" />
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
