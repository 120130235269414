<template>
  <svg width="100%" height="100%" viewBox="0 0 54 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 2C0 0.89543 0.895431 0 2 0H52C53.1046 0 54 0.895431 54 2V22C54 23.1046 53.1046 24 52 24H2C0.895429 24 0 23.1046 0 22V2Z" fill="#5E5E5E"/>
  <circle cx="20" cy="18" r="2" fill="#F9F9F9"/>
  <circle opacity="0.5" cx="27" cy="18" r="2" fill="#F9F9F9"/>
  <circle opacity="0.5" cx="34" cy="18" r="2" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'CarouselDotInside'
};
</script>
