<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.75 2.75V17.25H17.25V2.75H2.75ZM2.5 1.25C1.80964 1.25 1.25 1.80964 1.25 2.5V17.5C1.25 18.1904 1.80964 18.75 2.5 18.75H17.5C18.1904 18.75 18.75 18.1904 18.75 17.5V2.5C18.75 1.80964 18.1904 1.25 17.5 1.25H2.5Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'ImageShapeSquare',
};
</script>
