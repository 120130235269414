<script setup lang="ts">
import type { ControlToggleTypes } from '@gem/element-setting-ui';
import { OptionValue } from '@gem/element-setting-ui';

defineProps<ControlToggleTypes>();
const emit = defineEmits<{
  (e: 'controlChange', value?: boolean): void;
}>();

const handleControlChange = (value?: boolean) => {
  emit('controlChange', value);
};
</script>

<template>
  <OptionValue
    :id="id || ''"
    :value="value"
    :options="[
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ]"
    @control-change="handleControlChange" />
</template>
