import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type SalePageProductUpdateMutationVariables = Types.Exact<{
  salePageProductUpdateId: Types.Scalars['ID'];
  input: Types.UpdateSalePageProductInput;
}>;

export type SalePageProductUpdateMutationResponse = {
  salePageProductUpdate?: Types.Maybe<Pick<Types.SalePageProduct, 'baseIDs' | 'id'>>;
};

export const SalePageProductUpdateDocument = `
    mutation SalePageProductUpdate($salePageProductUpdateId: ID!, $input: UpdateSalePageProductInput!) {
  salePageProductUpdate(id: $salePageProductUpdateId, input: $input) {
    baseIDs
    id
  }
}
    `;
export const useSalePageProductUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SalePageProductUpdateMutationResponse,
    TError,
    SalePageProductUpdateMutationVariables,
    TContext
  >,
) =>
  useMutation<SalePageProductUpdateMutationResponse, TError, SalePageProductUpdateMutationVariables, TContext>(
    ['SalePageProductUpdate'],
    (
      variables:
        | SalePageProductUpdateMutationVariables
        | ComputedRef<SalePageProductUpdateMutationVariables>
        | Ref<SalePageProductUpdateMutationVariables>,
    ) =>
      appFetcher<SalePageProductUpdateMutationResponse, SalePageProductUpdateMutationVariables>(
        SalePageProductUpdateDocument,
        variables,
      )(),
    options,
  );
useSalePageProductUpdateMutation.getKey = () => ['SalePageProductUpdate'];
