<script lang="ts" setup>
import { useAnglePicker } from './hooks/useAnglePicker';
import type { AnglePickerProps, AnglePickerEmits } from './types';

const emit = defineEmits<AnglePickerEmits>();
const props = defineProps<AnglePickerProps>();

const { currentAngle, centerPointElement, circleElement, handleRotationStart } = useAnglePicker(props, emit);
</script>

<template>
  <div class="relative flex w-full flex-row-reverse items-start justify-between">
    <div
      ref="circleElement"
      class="border-dark-400 relative flex h-[84px] w-[84px] select-none items-center justify-center rounded-full border-[15px] bg-transparent">
      <div
        class="text-light-450 text-12 after:bg-text-dark-100 relative after:absolute after:right-[-4px] after:top-[1px] after:h-[3px] after:w-[3px] after:rounded-full after:content-['']">
        {{ currentAngle }}
      </div>
      <div class="absolute left-[-18px] top-[-18px] h-[calc(100%_+_36px)] w-[calc(100%_+_36px)]">
        <div
          class="relative float-left h-[calc(100%-1px)] w-[calc(100%-1px)] cursor-pointer rounded-full"
          @mousedown="handleRotationStart">
          <div ref="centerPointElement" class="mx-auto mt-[calc(50%-3px)] h-6 w-6 rounded-full bg-transparent" />
          <div
            class="absolute top-[calc(50%-6px)] left-1/2 w-1/2 origin-left cursor-pointer py-6 px-0"
            :style="`transform: rotate(${currentAngle}deg)`">
            <span class="bg-light-100 absolute top-[2px] right-6 h-8 w-8 rounded-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
