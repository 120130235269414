import { defineStore } from 'pinia';
import type { HashProductSetting } from '../types';

type State = {
  baseIDList: string[];
  productList: Map<string, HashProductSetting>;
  productLatest: any;
};

export const useDynamicTriggerStore = defineStore('dynamicTrigger', {
  state: (): State => ({
    baseIDList: [],
    productList: new Map<string, HashProductSetting>(),
    productLatest: {},
  }),
  getters: {
    getBaseIDList: (state) => state.baseIDList,
    getProductList: (state) => state.productList,
    getProductLatest: (state) => state.productLatest,
  },
  actions: {
    setBaseIDList(value: string[]) {
      this.baseIDList = value;
    },
    setProductLatest(value: any) {
      this.productLatest = value;
    },
    setProductList(value: Map<string, HashProductSetting>) {
      this.productList = value;
    },
    addProduct(key: string, value: HashProductSetting) {
      this.productList.set(key, value);
    },
    deleteProduct(key: string) {
      this.productList.delete(key);
    },
  },
});
