<script setup lang="ts">
import type { TAB_TYPE } from './types';
import type { ColorPickerProps } from './types';
import { InputComboLayout, SettingLayout, OptionValue } from '@gem/element-setting-ui';
import { useColorPicker } from './hooks/useColorPicker';
import { type ColorSuggestions } from '@gem/common';
import SolidColorPicker from './components/SolidColorPicker.vue';
import GradientColorPicker from './components/GradientColorPicker.vue';
import { ref } from 'vue';
import { makeGradientColorFormColor } from './helpers';

const props = defineProps<ColorPickerProps>();

const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
  (e: 'saveMyColors', value?: ColorSuggestions): void;
}>();

const solidColorCache = ref();
const gradientColorCache = ref();

const { displayValue, colorMode, handleSetDefaultValue, handleClearColor, colorValue } = useColorPicker(props, emit);

const handleControlChange = (controlId: string, value?: string) => {
  emit('controlChange', value);
};

const handleControlOnChange = (controlId: string, value?: string) => {
  emit('controlOnChange', value);
};

const handleSaveMyColors = (value?: ColorSuggestions) => {
  emit('saveMyColors', value);
};

const handleChangeMode = (value: string) => {
  colorMode.value = value as TAB_TYPE;
  if (value === 'gradient') {
    solidColorCache.value = props.value;
    if (gradientColorCache.value) handleControlChange(props.id, gradientColorCache.value);
    else handleControlChange(props.id, makeGradientColorFormColor(solidColorCache.value));
  } else {
    gradientColorCache.value = props.value;
    if (solidColorCache.value) handleControlChange(props.id, solidColorCache.value);
    else handleSetDefaultValue(true);
  }
};
</script>
<template>
  <div>
    <InputComboLayout
      placeholder="Add..."
      :label="{ en: 'Color' }"
      :show-label="false"
      :is-full-width="true"
      :content-display="displayValue"
      :combo-color="colorValue"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleSetDefaultValue"
      @clear="handleClearColor">
      <SettingLayout layout="vertical">
        <template #control>
          <div class="flex flex-col gap-16">
            <OptionValue
              v-if="enableGradient"
              id="mode"
              :value="colorMode"
              :options="[
                { value: 'solid', label: 'Solid' },
                { value: 'gradient', label: 'Gradient' },
              ]"
              @control-change="(value) => handleChangeMode(value)" />
            <SolidColorPicker
              v-if="colorMode === 'solid'"
              v-bind="props"
              @control-change="handleControlChange"
              @control-on-change="handleControlOnChange"
              @save-my-colors="handleSaveMyColors" />
            <GradientColorPicker
              v-if="colorMode === 'gradient'"
              v-bind="props"
              @control-change="handleControlChange"
              @control-on-change="handleControlOnChange"
              @save-my-colors="handleSaveMyColors" />
          </div>
        </template>
      </SettingLayout>
    </InputComboLayout>
  </div>
</template>
