<template>
  <svg width="100%" height="100%" viewBox="0 0 94 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H88C91.0376 0.5 93.5 2.96243 93.5 6V30C93.5 33.0376 91.0376 35.5 88 35.5H6C2.96243 35.5 0.5 33.0376 0.5 30V6Z" stroke="#5E5E5E"/>
<path d="M17.2538 16.0565C16.9535 15.9164 16.5964 16.0462 16.4563 16.3465C16.3162 16.6468 16.446 17.0038 16.7463 17.144L19.5771 18.465C19.8452 18.5901 20.1549 18.5901 20.4229 18.465L23.2538 17.144C23.554 17.0038 23.6839 16.6468 23.5437 16.3465C23.4036 16.0462 23.0466 15.9164 22.7463 16.0565L20 17.3381L17.2538 16.0565Z" fill="#F9F9F9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.4 13.6001C15.7432 13.6001 14.4 14.9432 14.4 16.6001V19.4001C14.4 21.057 15.7432 22.4001 17.4 22.4001H22.6C24.2569 22.4001 25.6 21.057 25.6 19.4001V16.6001C25.6 14.9432 24.2569 13.6001 22.6 13.6001H17.4ZM15.6 16.6001C15.6 15.606 16.4059 14.8001 17.4 14.8001H22.6C23.5941 14.8001 24.4 15.606 24.4 16.6001V19.4001C24.4 20.3942 23.5941 21.2001 22.6 21.2001H17.4C16.4059 21.2001 15.6 20.3942 15.6 19.4001V16.6001Z" fill="#F9F9F9"/>
<rect x="30" y="16" width="52" height="4" rx="1.8125" fill="#AAAAAA"/>
</svg>
</template>

<script>
export default {
  name: 'InputEmail'
};
</script>
