<script setup lang="ts">
import { computed } from 'vue';
import type { ItemIconProps } from '../../types';

const props = defineProps<ItemIconProps>();
const emit = defineEmits<{
  (e: 'activateItem'): void;
}>();

const showEditIcon = computed(() => !props.isEditingTitle);
const showDragIcon = computed(() => !props.isExpand && !props.isEditingTitle);
</script>

<template>
  <span
    v-if="showEditIcon"
    class="text-light-450 hover:text-light-450 hover:bg-dark-150 mr-4 flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-xl transition-all duration-200 group-[.sortable-chosen]/sortable-item:hover:cursor-grabbing"
    :class="{ 'rotate-180': isActive }"
    @click="emit('activateItem')">
    <g-base-icon v-if="showDragIcon" name="item-drag" width="16" height="16" viewBox="0 0 16 16" />
    <g-base-icon v-else name="instant-arrow-down" width="16" height="16" viewBox="0 0 16 16" />
  </span>
</template>
