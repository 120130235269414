import { stringInsertToIndex } from '@gem/common';
import type { ControlTextareaWithActionTypes } from '@gem/element-setting-ui';
import { onMounted, ref, watch, defineExpose, nextTick, computed } from 'vue';

export const useTextareaWithAction = (props: ControlTextareaWithActionTypes, emit: any) => {
  const textareaRef = ref<any>();
  const dropdownRef = ref<any>();
  const isFocus = ref(false);
  const isActiveDropdown = ref(false);
  const previousValue = ref(props.value ? props.value : '');
  const val = ref(props.value ? props.value : '');

  const isEnableDropdown = computed(() => props.displayOptions?.length);
  const minHeight = computed(() => (isEnableDropdown.value ? 44 : Math.max(props.minHeight || 0, 36)));
  const maxHeight = computed(() => props.maxHeight || 116);

  const handleEmit = (type: 'change' | 'onChange', value?: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue === previousValue.value) return;

    if (type === 'onChange') {
      emit('controlOnChange', trimmedValue);
    } else if (type === 'change') {
      previousValue.value = value;
      emit('controlChange', trimmedValue);
    }
  };

  const handleClickSuggestItem = (suggestValue: string) => {
    const oldValue = val.value as string;
    const currentPointerIndex = textareaRef.value?.selectionStart || oldValue.length;
    const newValue = stringInsertToIndex(oldValue, ` ${suggestValue}`, currentPointerIndex);
    handleEmit('change', newValue);
  };

  const handleControlChange = (e: any) => {
    let value = e.target.value;
    if (props.isFallback && !value) {
      value = props.value;
      val.value = value;
      emit('controlOnChange', value);
      return;
    }
    handleEmit('change', value);
  };

  const handleControlOnChange = (e: any) => {
    const value = e.target.value;
    handleEmit('onChange', value);
  };

  const handleFocus = () => {
    isFocus.value = true;
  };

  const handleBlur = (e: any) => {
    let value = e.target.value;
    if (props.isFallback && !value) {
      value = props.value;
      val.value = value;
      emit('controlOnChange', value);
      emit('blur', e);
      return;
    }
    handleEmit('change', value);
    emit('blur', e);
  };

  const onOpenDropdown = () => {
    isActiveDropdown.value = true;
  };

  const onCloseDropdown = () => {
    isActiveDropdown.value = false;
  };

  const setRangeHeight = () => {
    const $textarea = textareaRef.value;
    if (!$textarea) return;
    $textarea.style.height = 'auto';
    const elHeight = parseFloat($textarea.scrollHeight) - 16;
    const height = Math.min(maxHeight.value, Math.max(minHeight.value, elHeight));
    $textarea.style.height = height + 'px';
  };

  const setValue = (value: any) => {
    if (value != val.value) {
      val.value = value;
    }
  };

  defineExpose({ setValue });

  watch(
    () => props.value,
    (value) => {
      setValue(value);
    },
  );

  watch(val, () => {
    setRangeHeight();
  });

  onMounted(() => {
    nextTick(() => {
      setRangeHeight();
    });
  });

  return {
    val,
    isFocus,
    textareaRef,
    dropdownRef,
    isActiveDropdown,
    isEnableDropdown,
    handleBlur,
    handleFocus,
    onOpenDropdown,
    onCloseDropdown,
    handleControlChange,
    handleControlOnChange,
    handleClickSuggestItem,
  };
};
