<template>
  <svg width="100%" height="100%" viewBox="0 0 50 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_23910_20451)">
    <rect x="9" width="32" height="24" rx="2" fill="#5E5E5E"/>
    <path d="M5 15.1264C5 15.9031 4.03582 16.2921 3.47192 15.7429L0.262177 12.6165C-0.0873925 12.276 -0.0873925 11.724 0.262177 11.3835L3.47192 8.25712C4.03582 7.70786 5 8.09687 5 8.87363L5 15.1264Z" fill="#F9F9F9"/>
    <path d="M45 15.1264C45 15.9031 45.9642 16.2921 46.5281 15.7429L49.7378 12.6165C50.0874 12.276 50.0874 11.724 49.7378 11.3835L46.5281 8.25711C45.9642 7.70786 45 8.09686 45 8.87363L45 15.1264Z" fill="#F9F9F9"/>
  </g>
  <defs>
    <clipPath id="clip0_23910_20451">
      <rect width="50" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {
  name: 'SlideControlOutside'
};
</script>
