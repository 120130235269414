import type { GDropdownMenuItemState, GDropdownMenuItemSize } from '../types';

export const DROPDOWN_MENU_ITEM_STYLE_BY_TYPE: Record<GDropdownMenuItemState, { dark: string; light: string }> = {
  default: {
    dark: 'bg-transparent text-text-dark-500',
    light: 'bg-transparent text-text-light-500',
  },
  active: {
    dark: 'bg-dark-250 text-text-dark-500',
    light: 'bg-light-450 text-text-light-500',
  },
  activeWithCheckedIcon: {
    dark: 'text-text-dark-500',
    light: 'text-text-light-500',
  },
  hover: {
    dark: 'hover:bg-dark-250 hover:text-text-dark-500',
    light: 'hover:bg-light-450 hover:text-text-light-500',
  },
  disabled: {
    dark: 'bg-transparent text-text-dark-100 cursor-not-allowed ',
    light: 'bg-transparent text-text-light-100 cursor-not-allowed ',
  },
  error: {
    dark: 'bg-transparent text-red-200',
    light: 'bg-transparent text-red-300',
  },
};

export const DROPDOWN_MENU_ITEM_STYLE_BY_SIZE: Record<GDropdownMenuItemSize, string> = {
  large: 'min-h-40 text-14 leading-normal p-8',
  medium: 'min-h-36 text-12 leading-normal p-8',
  'semi-medium': 'h-32 text-12 leading-6 p-4',
};

export const DROPDOWN_MENU_STYLE_BY_SIZE: Record<GDropdownMenuItemSize, string> = {
  large: 'p-8 gap-8',
  medium: 'p-8 gap-8',
  'semi-medium': 'p-4 gap-0',
};
