<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.375 2C1.375 1.65482 1.65482 1.375 2 1.375H6C6.34518 1.375 6.625 1.65482 6.625 2V6C6.625 6.34518 6.34518 6.625 6 6.625H2C1.65482 6.625 1.375 6.34518 1.375 6V2ZM2.625 2.625V5.375H5.375V2.625H2.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.375 8C1.375 7.65482 1.65482 7.375 2 7.375H6C6.34518 7.375 6.625 7.65482 6.625 8V12C6.625 12.3452 6.34518 12.625 6 12.625H2C1.65482 12.625 1.375 12.3452 1.375 12V8ZM2.625 8.625V11.375H5.375V8.625H2.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 13.375C1.65482 13.375 1.375 13.6548 1.375 14V18C1.375 18.3452 1.65482 18.625 2 18.625H6C6.34518 18.625 6.625 18.3452 6.625 18V14C6.625 13.6548 6.34518 13.375 6 13.375H2ZM2.625 17.375V14.625H5.375V17.375H2.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.375 2C7.375 1.65482 7.65482 1.375 8 1.375H12C12.3452 1.375 12.625 1.65482 12.625 2V6C12.625 6.34518 12.3452 6.625 12 6.625H8C7.65482 6.625 7.375 6.34518 7.375 6V2ZM8.625 2.625V5.375H11.375V2.625H8.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 7.375C7.65482 7.375 7.375 7.65482 7.375 8V12C7.375 12.3452 7.65482 12.625 8 12.625H12C12.3452 12.625 12.625 12.3452 12.625 12V8C12.625 7.65482 12.3452 7.375 12 7.375H8ZM8.625 11.375V8.625H11.375V11.375H8.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.375 14C7.375 13.6548 7.65482 13.375 8 13.375H12C12.3452 13.375 12.625 13.6548 12.625 14V18C12.625 18.3452 12.3452 18.625 12 18.625H8C7.65482 18.625 7.375 18.3452 7.375 18V14ZM8.625 14.625V17.375H11.375V14.625H8.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1.375C13.6548 1.375 13.375 1.65482 13.375 2V6C13.375 6.34518 13.6548 6.625 14 6.625H18C18.3452 6.625 18.625 6.34518 18.625 6V2C18.625 1.65482 18.3452 1.375 18 1.375H14ZM14.625 5.375V2.625H17.375V5.375H14.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.375 8C13.375 7.65482 13.6548 7.375 14 7.375H18C18.3452 7.375 18.625 7.65482 18.625 8V12C18.625 12.3452 18.3452 12.625 18 12.625H14C13.6548 12.625 13.375 12.3452 13.375 12V8ZM14.625 8.625V11.375H17.375V8.625H14.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 13.375C13.6548 13.375 13.375 13.6548 13.375 14V18C13.375 18.3452 13.6548 18.625 14 18.625H18C18.3452 18.625 18.625 18.3452 18.625 18V14C18.625 13.6548 18.3452 13.375 18 13.375H14ZM14.625 17.375V14.625H17.375V17.375H14.625Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'BgRepeat'
};
</script>
