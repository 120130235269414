import type { Ref, ComputedRef } from 'vue';
import type { ControlBaseProps } from '../../sidebar-setting/types';

export type Item = {
  id: string;
  title: string;
  icon?: string;
};

export type Component = {
  uid: string;
  tag: any;
  label?: string;
  childrens?: Component[];
  settings?: Record<string, any>;
  styles?: Record<string, any>;
  advanced?: Record<string, any>;
  editorConfigs?: Record<string, any>;
};

export type ItemManagementProps = ControlBaseProps & {
  id?: string;
  value?: string[];
  hasAddMore?: boolean;
  expandItem?: boolean;
  titleItem?: string;
  iconItem?: string;
  tag?: ItemManagementElementTag;
  childrens?: Component[];
  iconList?: string[];
  componentUid?: string;
  childTemplate?: any;
};

export type ItemProps = {
  index?: string;
  item?: Item;
  icon?: string;
  tag?: ItemManagementElementTag;
  isExpand?: boolean;
};

export type ItemIconProps = {
  isActive?: boolean;
  isEditingTitle?: boolean | null;
  isExpand?: boolean;
  tag?: string;
};

export type ItemActionProps = {
  index?: string;
  isEditable?: boolean;
  isEditingTitle?: boolean | null;
  isDisabledDelete?: boolean;
  showAction: boolean;
  hasHover: boolean;
};

export type ItemInputProps = {
  title?: string;
  isEditingTitle?: boolean | null;
};

export type ItemTitleProps = {
  title?: string;
  isEditingTitle?: boolean | null;
  showAction: boolean;
  hasHover: boolean;
};

export type ItemInfoConfig = {
  title: string;
};

export enum ItemIconAction {
  Add = 'add',
  Move = 'move',
  Delete = 'delete',
  Clone = 'clone',
}

export type ItemIconActionOptions = {
  index?: string;
  from?: number;
  to?: number;
};

export type ItemManagementElementTag =
  | 'TabItem'
  | 'Marquee'
  | 'Accordion'
  | 'MarqueeItem'
  | 'CarouselItem'
  | 'IconListItem'
  | 'FormDropdown'
  | 'AccordionItem'
  | 'IconListItemV2'
  | 'IconListItemHoz';

export type ItemActionType =
  | 'edit'
  | 'duplicate'
  | 'cancel-edit'
  | 'confirm-edit'
  | 'cancel-delete'
  | 'confirm-delete'
  | 'show-delete-action';

export type ItemManagementEmits = {
  (e: 'change', value?: any): void;
  (e: 'change-active-item', field: 'hoverItem' | 'activeItem', value?: string): void;
  (e: 'onChange', value?: any): void;
  (e: 'trigger-update-child-icon', action: ItemIconAction, options?: ItemIconActionOptions): void;
};

export type ItemEmits = {
  (e: 'edit', index: string): void;
  (e: 'changeTitle', value: string, index: string): void;
  (e: 'change', value: Event | KeyboardEvent, index: string): void;
  (e: 'duplicate', index: string): void;
  (e: 'delete', index: string): void;
  (e: 'moveToIndex', index: number): void;
  (e: 'changeStatusEditTitleItem', id: string, value?: boolean): void;
  (e: 'changeStatusBlurringTitleItem', id: string, value?: boolean): void;
  (e: 'click', index: string): void;
  (e: 'hover', index?: string): void;
  (e: 'check-disable-action', action: 'edit' | 'duplicate' | 'delete'): boolean;
};

export type ItemManagementContext = {
  props: ItemManagementProps;

  state: {
    itemInfo: ComputedRef<{
      tag: any;
      label: any;
      titleItem: string;
    }>;
    items: Ref<Item[]>;
    activeIds: Ref<string[]>;
    editingId: Ref<string>;
    blurringTitleList: Ref<string[]>;
  };

  actions: {
    handleAppendItem: () => void;
    handleCheckIsEditable: () => boolean;
    handleClick: (index: string) => void;
    handleHover: (index?: string) => void;
    handleDelete: (index: string) => void;
    handleActivate: (index: string) => void;
    handleDuplicate: (index: string, customTitle: string) => void;
    handleBlurTitleState: (id: string) => void;
    handleEditTitleState: (id: string) => void;
    handleSlideToIndex: (index: number) => void;
    handleChangeTitle: (value: string, index: string) => void;
    handleOnChangeTitle: (value: string, index: string) => void;
    handleNormalizeItemTitle: (title?: string) => string | undefined;
    handleReorderItem: (items: Item[], from?: number, to?: number) => void;
    handleChangeTitleOnEvent: (value: Event | KeyboardEvent, index: string) => void;
    handleCheckDisableAction: (action: 'edit' | 'duplicate' | 'delete') => boolean | undefined;
  };
};
