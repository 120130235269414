<script lang="ts" setup>
const props = defineProps<{
  value: string;
  selected?: boolean;
}>();

const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
}>();

const onClick = () => {
  emit('controlChange', props.value);
};
</script>

<template>
  <div class="hover:bg-dark-200 flex h-24 w-24 cursor-pointer items-center justify-center rounded-xl" @click="onClick">
    <div
      class="aspect-square rounded-full"
      :class="{
        'border-primary-300 w-12 border-[4px] bg-transparent': selected,
        'w-4 bg-white': !selected,
      }"></div>
  </div>
</template>
