<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 10C17.5 13.866 14.366 17 10.5 17C6.63401 17 3.5 13.866 3.5 10C3.5 6.13401 6.63401 3 10.5 3C14.366 3 17.5 6.13401 17.5 10ZM13.8226 14.3833C12.8996 15.0841 11.7484 15.5 10.5 15.5C7.46243 15.5 5 13.0376 5 10C5 8.75165 5.4159 7.60043 6.1167 6.67736L13.8226 14.3833ZM14.8833 13.3226L7.17736 5.6167C8.10043 4.9159 9.25165 4.5 10.5 4.5C13.5376 4.5 16 6.96243 16 10C16 11.2484 15.5841 12.3996 14.8833 13.3226Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'CursorNone'
};
</script>
