<template>
  <svg viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
    <g clip-path="url(#clip0_293_7933)">
      <path d="M-8 2C-8 -1.31371 -5.31371 -4 -2 -4H82C85.3137 -4 88 -1.31371 88 2V46C88 49.3137 85.3137 52 82 52H-2C-5.31371 52 -8 49.3137 -8 46V2Z" fill="#474747"/>
      <path d="M-8 2C-8 -1.31371 -5.31371 -4 -2 -4H29V52H-2C-5.31371 52 -8 49.3137 -8 46V2Z" fill="#5E5E5E"/>
      <rect x="20" y="15" width="18" height="18" rx="9" fill="#F9F9F9"/>
      <path d="M25.7775 21.1931L23.2097 23.5379C22.9301 23.7932 22.9301 24.2073 23.2097 24.4626L25.7775 26.8074C26.2287 27.2193 27 26.9276 27 26.345V21.6555C27 21.0729 26.2287 20.7811 25.7775 21.1931Z" fill="#212121"/>
      <path d="M32.2225 21.1931L34.7903 23.5379C35.0699 23.7932 35.0699 24.2073 34.7903 24.4626L32.2225 26.8074C31.7713 27.2193 31 26.9276 31 26.345V21.6555C31 21.0729 31.7713 20.7811 32.2225 21.1931Z" fill="#212121"/>
      <rect x="29" y="-4" width="1" height="56" fill="#F9F9F9"/>
    </g>
    <defs>
      <clipPath id="clip0_293_7933">
        <path d="M0 6C0 2.68629 2.68629 0 6 0H74C77.3137 0 80 2.68629 80 6V42C80 45.3137 77.3137 48 74 48H6C2.68629 48 0 45.3137 0 42V6Z" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ImageComparisonHorizontal'
};
</script>
