import type { ImageDimensions, ImageLoadingResult } from '../types';
import type { useImageSelectState } from './useImageSelectState';

export const useImageValidation = (state: ReturnType<typeof useImageSelectState>) => {
  const imageLoadingHelpers = {
    createSuccessHandler(img: HTMLImageElement): Promise<ImageLoadingResult> {
      return new Promise((resolve) => {
        img.onload = () =>
          resolve({
            isLoadable: true,
            width: img.width,
            height: img.height,
          });
      });
    },

    createErrorHandler(
      img: HTMLImageElement,
      originalSrc: string,
      currentSrc: string,
      timeoutDuration: number,
    ): Promise<ImageLoadingResult> {
      return new Promise((resolve) => {
        const handleError = () =>
          resolve({
            isLoadable: false,
            isStale: originalSrc !== currentSrc,
          });

        img.onerror = handleError;
        img.onabort = handleError;
        setTimeout(handleError, timeoutDuration);
      });
    },

    triggerImageLoad(img: HTMLImageElement, imgLink: string): void {
      img.src = imgLink;
    },
  };

  const tryLoadImage = async (
    imgLink: string,
    currentImageSrc: string,
    timeoutDuration: number,
  ): Promise<ImageLoadingResult> => {
    const img = new Image();
    const originalSrc = currentImageSrc;

    const successPromise = imageLoadingHelpers.createSuccessHandler(img);
    const errorPromise = imageLoadingHelpers.createErrorHandler(img, originalSrc, currentImageSrc, timeoutDuration);

    img.src = imgLink;

    return Promise.race([successPromise, errorPromise]);
  };

  const isHttpsUrl = (url: string): boolean => {
    try {
      return new URL(url).protocol === 'https:';
    } catch {
      return false;
    }
  };

  const updateImageDimensions = (dimensions: ImageDimensions) => {
    state.imageWidth.value = dimensions.width;
    state.imageHeight.value = dimensions.height;
  };

  const setImageSource = (imgLink?: string) => {
    if (!imgLink) return;

    state.imageSrc.value = imgLink;
    state.isLinkHttps.value = true;
    state.isLinkValid.value = true;
  };

  const isImageValid = async (imgLink: string, timeoutDuration: number): Promise<boolean> => {
    if (!isHttpsUrl(imgLink)) {
      state.isLinkHttps.value = false;
      return false;
    }

    const loadResult = await tryLoadImage(imgLink, state.imageSrc.value, timeoutDuration);

    if (loadResult.isStale || !loadResult.isLoadable) {
      if (!loadResult.isLoadable) {
        state.isLinkValid.value = false;
      }
      return false;
    }

    updateImageDimensions({
      width: loadResult.width!,
      height: loadResult.height!,
    });

    return true;
  };

  return {
    isHttpsUrl,
    isImageValid,
    setImageSource,
  };
};
