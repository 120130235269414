<script lang="ts" setup>
import { computed } from 'vue';
import { GIcon } from '@gem/icons';
import type { OptionSelect } from '@gem/uikit';
import { usePreviewTypoStyleConverter } from '../composables/usePreviewTypoStyleConverter';

interface Props {
  activeId?: string | number;
  isHoverSelect: boolean;
  filteredOptions: OptionSelect[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'select', option: OptionSelect): void;
}>();

const { getRenderValue } = usePreviewTypoStyleConverter();

const isActiveOption = (item: OptionSelect) => {
  return String(props.activeId) === String(item.id);
};

const optionClasses = computed(() => (item: OptionSelect) => [
  'hover:bg-light-100/20 text-light-450 text-12 relative cursor-pointer select-none rounded-xl p-[8px] leading-[20px] pl-40 pr-8',
  { 'cursor-default': isActiveOption(item) },
]);

const onSelect = (item: OptionSelect) => {
  emit('select', item);
};
</script>

<template>
  <div class="text-16 sm:text-14 right-0 z-[999] w-[248px] p-8 focus:outline-none">
    <div v-for="item in props.filteredOptions" :key="item.id">
      <div :class="optionClasses(item)" :data-value="item.id" data-test="select-option" @click="onSelect(item)">
        <span v-if="isActiveOption(item)" class="absolute inset-y-0 left-0 flex items-center pl-8 pr-12 text-green-300">
          <GIcon name="polaris-check" />
        </span>
        <div class="flex flex-col gap-4">
          <div class="font-regular flex w-full justify-between">
            <span :style="{ fontSize: getRenderValue(item.value) }" v-html="item.name"> </span>
            <span v-if="item.value" class="text-text-dark-300">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
