import * as Sentry from '@sentry/vue';
import type { SeverityLevel } from '@sentry/vue';
import type { Shop } from '../types/graphql';

export const sentrySetIdentification = (shop: Pick<Shop, 'id' | 'deployPlatformDomain' | 'publicToken'>) => {
  Sentry.setUser({
    id: String(shop.id),
    defaultDomain: shop.deployPlatformDomain,
    publicToken: shop.publicToken,
  });
};

export const sentryCaptureException = (
  funcName: string,
  message: string,
  data: object,
  options?: {
    level?: SeverityLevel;
    tag?: {
      key: string;
      value: string;
    };
  },
) => {
  Sentry.withScope((scope) => {
    scope.setLevel(options?.level ?? 'log');
    if (options?.tag) {
      scope.setTag(options?.tag.key, options?.tag.value);
    }
    scope.setExtra('function', funcName);
    scope.setExtra('data', JSON.stringify(data));
    Sentry.captureMessage(`${funcName}: ${message}`);
  });
};
