import type { Ref } from 'vue';
import { nextTick, ref } from 'vue';
import type { EmitTypeSearchComponent } from '../types';

export const useSearchListProduct = (
  isSearchSetting: Ref<Boolean>,
  inputSearch: Ref<HTMLElement | undefined>,
  searchKeyword: Ref<string>,
  emit: EmitTypeSearchComponent,
) => {
  const openSearchSetting = () => {
    isSearchSetting.value = true;
    nextTick(() => {
      if (inputSearch.value) {
        inputSearch.value.focus();
      }
    });
  };

  const timeDebounceSearch = ref<ReturnType<typeof setTimeout> | null>(null);
  const onChangeSearch = (value: string) => {
    searchKeyword.value = value;
    if (timeDebounceSearch.value) {
      clearTimeout(timeDebounceSearch.value);
    }

    timeDebounceSearch.value = setTimeout(() => {
      emit('onSearch', searchKeyword.value);
    }, 200);
  };

  const closeSearchSetting = () => {
    isSearchSetting.value = false;
    if (!searchKeyword.value) return;
    searchKeyword.value = '';
    emit('onSearch', '');
  };
  return { openSearchSetting, closeSearchSetting, onChangeSearch };
};
