<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.89124 18.1211C1.89124 18.4663 2.17106 18.7461 2.51624 18.7461C2.86141 18.7461 3.14124 18.4663 3.14124 18.1211L3.14124 14.5139C3.33672 14.583 3.54709 14.6206 3.76624 14.6206L11.2662 14.6206C12.3018 14.6206 13.1412 13.7811 13.1412 12.7456L13.1412 7.2456C13.1412 6.21007 12.3018 5.37061 11.2662 5.37061L3.76623 5.37061C3.54709 5.37061 3.33672 5.4082 3.14124 5.4773L3.14124 1.87018C3.14124 1.525 2.86141 1.24518 2.51624 1.24518C2.17106 1.24518 1.89123 1.525 1.89123 1.87018L1.89123 7.24561L1.89124 12.7456L1.89124 18.1211ZM3.14124 7.2446C3.14124 7.24493 3.14123 7.24527 3.14123 7.24561L3.14124 12.7456C3.14124 12.7458 3.14124 12.7461 3.14124 12.7463C3.14162 13.0912 3.42129 13.3706 3.76624 13.3706L11.2662 13.3706C11.6114 13.3706 11.8912 13.0908 11.8912 12.7456L11.8912 7.24561C11.8912 6.90043 11.6114 6.62061 11.2662 6.62061L3.76623 6.62061C3.42139 6.62061 3.14178 6.89988 3.14124 7.2446Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'JustifyContentLeft'
};
</script>
