<script setup lang="ts">
import type { ControlTextareaWithActionTypes } from './type';
import { useTextareaWithAction } from './hooks/useTextareaWithAction';
import { GButton, GPopover, GDropdownMenu } from '@gem/uikit-v2';

const props = defineProps<ControlTextareaWithActionTypes>();
const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
  (e: 'blur', event?: any): void;
}>();
const {
  val,
  isFocus,
  textareaRef,
  dropdownRef,
  isActiveDropdown,
  isEnableDropdown,
  handleBlur,
  handleFocus,
  onOpenDropdown,
  onCloseDropdown,
  handleControlChange,
  handleControlOnChange,
  handleClickSuggestItem,
} = useTextareaWithAction(props, emit);
</script>

<template>
  <div class="group relative">
    <textarea
      ref="textareaRef"
      v-model="val"
      class="gp-textarea scroll-bar !text-12 min-h-[20px] py-8"
      :class="[isEnableDropdown ? 'pl-8 pr-40' : 'px-8', isFocus ? '' : 'group-hover:bg-dark-200']"
      type="text"
      :dir="direction"
      :placeholder="placeholder || ''"
      :disabled="readonly"
      @input.stop="handleControlOnChange"
      @change.stop="handleControlChange"
      @focus.stop="handleFocus"
      @focusout="() => (isFocus = false)"
      @blur.stop="handleBlur" />
    <div
      v-if="isEnableDropdown"
      class="absolute right-8 top-8 flex h-28 w-28 cursor-pointer items-center justify-center rounded-xl group-hover:visible"
      :class="[isActiveDropdown ? 'visible' : 'invisible']">
      <GPopover
        ref="dropdownRef"
        :has-arrow="false"
        :closeable="true"
        :overlay="false"
        :offset-top="-2"
        :z-index="9999"
        overlay-container="#root-modal"
        placement="bottom-end"
        cls="!p-0"
        @open="onOpenDropdown"
        @close="onCloseDropdown">
        <template #default="{ open }">
          <GButton type="ghost" size="normal" only-icon="polaris-plus" :active="open" @click="() => {}" />
        </template>
        <template #content>
          <GDropdownMenu
            :width="dropdownWidth || '200px'"
            :items="displayOptions"
            :show-checked-icon="false"
            :is-multi-select="false"
            @on-select="
              (value: string[]) => {
                handleClickSuggestItem(value[0]);
              }
            " />
        </template>
      </GPopover>
    </div>
  </div>
</template>

<style lang="css" scoped>
.gp-textarea {
  @apply bg-dark-400 text-12 font-regular box-border block h-auto w-full resize-none rounded-xl border border-transparent leading-5 text-white outline-none;
  @apply placeholder:text-text-dark-100 focus:border-primary-300 hover:border-transparent;
  @apply disabled:bg-dark-200 disabled:text-text-dark-100 disabled:hover:border-dark-200 disabled:cursor-not-allowed disabled:select-none;
}

.scroll-bar::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #424242;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.scroll-bar::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
