export const useAdmin = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const isAdmin = urlParams.get('admin_debug') === 'true';
  const adminShopID = urlParams.get('shop_id');
  const adminShopifyDomain = urlParams.get('shop');
  const adminToken = urlParams.get('admin_token');

  return {
    isAdmin,
    adminToken,
    adminShopID,
    adminShopifyDomain,
  };
};
