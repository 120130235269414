import { useInfiniteScroll } from '@vueuse/core';
import { ref } from 'vue';
import type { EmitTypeProductSingle } from '../types';

export const useHandleDataAssigned = (emit: EmitTypeProductSingle) => {
  const productInfinityScroll = ref<HTMLElement | null>(null);
  useInfiniteScroll(
    productInfinityScroll,
    () => {
      // load more
      emit('showMoreAssigned');
    },
    { distance: 100 },
  );

  const setValueSelected = (id?: string) => {
    if (!id) return;
    emit('setValueSelected', id);
  };
  return { setValueSelected, productInfinityScroll };
};
