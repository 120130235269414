<script setup lang="ts">
import { GInput } from '@gem/uikit-v2';
import { useImageSelectContext } from '../hooks/useImageSelectContext';

defineProps<{
  isActive?: boolean;
  errorMessage?: string;
}>();

const { actions, state } = useImageSelectContext();

const { imageSrc } = state;
const { changeImageLink, handleClearUrl } = actions;
</script>

<template>
  <div class="relative mt-16 mb-8 flex w-full items-center justify-between">
    <GInput
      :value="imageSrc"
      :active="isActive"
      :clear-button="true"
      input-style="inverse"
      :error-message="errorMessage"
      placeholder="https://example.com/image.jpg"
      @clear="handleClearUrl"
      @on-change="changeImageLink" />
  </div>
</template>
