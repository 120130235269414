<template>
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.10522 9C1.10522 8.72386 1.32908 8.5 1.60522 8.5H18.1062C18.3823 8.5 18.6062 8.72386 18.6062 9V11C18.6062 11.2761 18.3823 11.5 18.1062 11.5H1.60522C1.32908 11.5 1.10522 11.2761 1.10522 11V9Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ProductButtonHorizontalLineSeparator'
};
</script>
