import type { Ref } from 'vue';
import { ref, watch, computed } from 'vue';
import type { Product } from '../../types';
import type { MultiProductPickerProps, ProductListPickerTypesEmit, ProductSource } from '../type';

export const useSetValueSelectedProducts = (
  props: MultiProductPickerProps,
  handleAddVariant?: any,
  handleDeleteVariant?: any,
) => {
  const PRODUCT_PICK_LIMIT = 20;
  const listProduct = ref<Product[]>(props.value ?? []);
  const handleSetValueHaveProduct = (item: Product) => {
    if (listProduct.value.length <= 1) {
      alert('You must choose at least 1 product!');
      return;
    }
    const index = listProduct.value.findIndex((i) => i.id === item.id);
    listProduct.value.splice(index, 1);
    handleDeleteVariant(item.id);
  };

  const productPickLimit = computed(() =>
    props?.maxAllowSelected ?? 0 > PRODUCT_PICK_LIMIT ? PRODUCT_PICK_LIMIT : props.maxAllowSelected,
  );

  const handleSetValueNotHaveProduct = (item: Product) => {
    if (productPickLimit.value && listProduct.value.length >= productPickLimit.value) {
      alert(`You can only select up to ${productPickLimit.value} products`);
      return;
    }
    if (listProduct.value.length >= 20) {
      alert('You can only select up to 20 products!');
      return;
    }
    listProduct.value?.push(item);
    handleAddVariant(item);
  };

  const setValueSelected = (item?: Product) => {
    if (!item?.id) return;
    const product = listProduct.value?.find((i) => i.id === item.id);
    if (!product) {
      handleSetValueNotHaveProduct(item);
      return;
    }
    handleSetValueHaveProduct(item);
  };

  watch(
    () => props.value,
    (newValue) => {
      listProduct.value = newValue ?? [];
    },
  );

  return { listProduct, setValueSelected };
};

export const useSetValueModal = (emit: ProductListPickerTypesEmit, searchProductListReference: Ref<any>) => {
  const handleSelectTypePicker = (value: ProductSource) => {
    emit('select-picker-type', value);
    searchProductListReference.value.closeSearchSetting();
  };

  const setValueSelectedProducts = (value?: Product[]) => {
    emit('setValueSelected', value);
  };

  const setValueSelectedCollection = (value?: string) => {
    if (value) {
      emit('setValueSelectedCollection', value);
    }
  };

  return { handleSelectTypePicker, setValueSelectedProducts, setValueSelectedCollection };
};
