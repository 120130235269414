import type { Ref } from 'vue';
import type { TypographyFontOption } from '@gem/control';
import type { FontListInstance, FontPickerProps } from '../types';

import { getFontLabel } from '../helpers';
import { ref, computed, nextTick } from 'vue';

export const useSearchFont = (props: FontPickerProps, fontListRef: Ref<FontListInstance | null>) => {
  const fontOptions = computed(() => props.options ?? []);

  const searchKeyword = ref('');

  const filteredFonts = computed(() => getFilteredFonts(fontOptions.value));
  const filteredUploadFonts = computed(() => getFilteredFonts(props.fontUploads));

  const noSearchFound = computed(() => {
    return filteredFonts.value.length === 0 && filteredUploadFonts.value.length === 0;
  });

  const onSearchMode = computed(() => {
    return searchKeyword.value.length > 0;
  });

  const getFilteredFonts = (fontOptions?: TypographyFontOption[]) => {
    if (!fontOptions) return [];

    return fontOptions.filter((font) => {
      if (searchKeyword.value.length === 0) {
        return true;
      }

      const fontLabel = font.type === 'theme' ? getFontLabel(font.value, props?.themeFonts) ?? font.value : font.value;

      return (
        font.label?.toLowerCase().includes(searchKeyword.value) ||
        fontLabel?.toLowerCase().includes(searchKeyword.value)
      );
    });
  };

  const handleSearch = (value: string) => {
    searchKeyword.value = value.trim().toLocaleLowerCase();

    nextTick(() => {
      fontListRef.value?.resetScroll();
    });
  };

  const handleClearSearch = () => {
    searchKeyword.value = '';

    nextTick(() => {
      fontListRef.value?.resetScroll();
    });
  };

  return {
    onSearchMode,
    searchKeyword,
    filteredFonts,
    noSearchFound,
    filteredUploadFonts,
    handleSearch,
    handleClearSearch,
  };
};
