<script lang="ts" setup>
import { computed } from 'vue';
import { GIcon } from '@gem/icons';
import { GButton } from '@gem/uikit-v2';
import type { FontListItemProps } from '../types';
import { getFontFamilyStyle, getFontStyles } from '../helpers';

const emit = defineEmits<{
  (event: 'open-modal-delete', e: Event): void;
}>();

const props = withDefaults(defineProps<FontListItemProps>(), {
  font: '',
  isChecked: false,
  isLoading: false,
  hasDelete: false,
  themeFontVariable: '',
  hasDisabledDelete: false,
});

const labelStyle = computed(() => {
  const font = props.themeFontVariable || props.font;
  const fontFamilyStyle = getFontFamilyStyle({
    font,
    isVariable: Boolean(props.themeFontVariable),
  });

  return getFontStyles(fontFamilyStyle);
});
</script>

<template>
  <div
    data-test="editor-control-font-option"
    class="text-12 font-regular hover:bg-dark-300 group relative inset-0 flex h-36 cursor-pointer items-center rounded-xl bg-transparent pr-4 transition-all before:absolute">
    <div class="flex aspect-square h-full items-center justify-center">
      <div :class="isChecked ? 'visible' : 'invisible'" class="flex items-center text-green-300">
        <GIcon name="polaris-check" />
      </div>
      <GButton v-if="isLoading" type="dangerGhost" size="medium" :loading="isLoading" />
    </div>
    <div class="flex w-full items-center justify-between pr-[2px]">
      <span class="max-w-[166px] overflow-hidden text-ellipsis whitespace-nowrap" :style="labelStyle">
        {{ label }}
      </span>
      <div v-if="hasDelete" class="invisible group-hover:visible">
        <GButton
          size="small"
          type="dangerGhost"
          :disable="isDisabledDelete"
          :only-icon="'polaris-delete'"
          @click.stop="(e: Event) => emit('open-modal-delete', e)" />
      </div>
    </div>
  </div>
</template>
