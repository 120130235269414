import { ref, watch, onMounted, onUnmounted } from 'vue';
import type { AnglePickerProps, AnglePickerEmits } from '../types';

const MAX_ANGLE = 359;

export const useAnglePicker = (props: AnglePickerProps, emit: AnglePickerEmits) => {
  const currentAngle = ref(Number(props.value));
  const minAngle = ref(Math.max(props.min ?? 0, 0));
  const maxAngle = ref(Math.min(props.max ?? MAX_ANGLE, MAX_ANGLE));

  const circleElement = ref<HTMLElement>();
  const centerPointElement = ref<HTMLElement>();
  const centerPosition = ref({ x: 0, y: 0 });

  const calculateRotationAngle = (event: MouseEvent): number => {
    const { x: mouseX, y: mouseY } = event;

    const deltaX = mouseX - centerPosition.value.x;
    const deltaY = mouseY - centerPosition.value.y;

    const distanceFromCenter = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    let rotationAngle = Math.round(Math.asin(deltaY / distanceFromCenter) * (180 / Math.PI));

    if (deltaX < 0) {
      rotationAngle = 180 - rotationAngle;
    }

    if (rotationAngle < 0) {
      rotationAngle = maxAngle.value + rotationAngle;
    }

    return rotationAngle;
  };

  const emitAngleChange = (isFinalChange: boolean = false) => {
    if (!currentAngle.value) return;

    currentAngle.value = Math.min(Math.max(currentAngle.value, minAngle.value), maxAngle.value);

    emit('controlOnChange', props.id, currentAngle.value);

    if (isFinalChange) {
      emit('controlChange', props.id, currentAngle.value);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    currentAngle.value = calculateRotationAngle(event);
    emitAngleChange();
  };

  const handleMouseUp = () => {
    window.removeEventListener('mouseup', handleMouseUp);
    window.removeEventListener('mousemove', handleMouseMove);
    emitAngleChange(true);
  };

  const handleRotationStart = () => {
    const elementBounds = centerPointElement.value?.getBoundingClientRect();
    if (!elementBounds) return;

    centerPosition.value = {
      x: elementBounds.x,
      y: elementBounds.y,
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
  };

  onMounted(() => {
    circleElement.value?.addEventListener('mousedown', handleMouseMove);
  });

  onUnmounted(() => {
    window.removeEventListener('mouseup', handleMouseUp);
    window.removeEventListener('mousemove', handleMouseMove);
    circleElement.value?.removeEventListener('mousedown', handleMouseMove);
  });

  watch(
    () => props.value,
    (newAngle) => {
      if (newAngle === undefined) return;
      currentAngle.value = Math.min(Math.max(Number(newAngle), minAngle.value), maxAngle.value);
    },
  );

  return {
    currentAngle,
    circleElement,
    centerPointElement,
    handleRotationStart,
  };
};
