import { isColor } from '@gem/common';
import type { Ref } from 'vue';

import { computed } from 'vue';
import { generateNewGlobalStyleColor } from '../helpers';
import useSettingSideBarStore from '../../../hooks/useSettingSideBarStore';

const DEFAULT_COLOR = ['#121212', '#4F4F4F', '#E2E2E2', '#FFFFFF', '#334FB4'];
export const MAX_COLOR_SIZE = 7;
export const SUGGESTED_COLOR_FOR_YOU = ['#00000000', '#151515', '#FFFFFF', '#5594E7', '#5E8E49', '#E4893A', '#C5453F'];
export const START_CHANGE_INDEX_OF_GRADIENT_TAB = 0;
export const START_CHANGE_INDEX_OF_SOLID_TAB = 5;

const useMyColor = (globalColors: Ref<any>) => {
  const settingSideBarStore = useSettingSideBarStore();
  const currentGlobalStyleColor = computed(() => settingSideBarStore.globalStyles?.color || {});
  const systemColor = computed(() => {
    return [
      currentGlobalStyleColor?.value?.brand,
      currentGlobalStyleColor?.value?.['text-1'],
      currentGlobalStyleColor?.value?.highlight,
      currentGlobalStyleColor?.value?.['text-3'],
      currentGlobalStyleColor?.value?.info,
    ];
  });

  const globalColor = computed(() => {
    if (!currentGlobalStyleColor?.value) return DEFAULT_COLOR.map((c) => c.toLowerCase());

    if (!globalColors.value || !currentGlobalStyleColor.value) {
      return DEFAULT_COLOR.map((c) => c.toLowerCase());
    }

    if (currentGlobalStyleColor.value?.['my-colors'] && currentGlobalStyleColor.value?.['my-colors'].length > 0) {
      const colors = currentGlobalStyleColor.value?.['my-colors']
        .concat(currentGlobalStyleColor.value['theme-colors'] as string[])
        .filter(Boolean);

      return colors.map((c: string) => {
        if (isColor(c)) {
          return c;
        } else {
          return globalColors.value.find((col: any) => col.colorType === c)?.color;
        }
      });
    }

    return DEFAULT_COLOR.map((c) => c.toLowerCase());
  });

  const myColors = computed(() =>
    ([...new Set(globalColor.value)] as string[]).filter((color) => color !== 'transparent').slice(0, MAX_COLOR_SIZE),
  );

  const getMyColorChange = (newColor: string | undefined) => {
    if (!newColor) return;

    const changeIndex = currentGlobalStyleColor?.value['color-index-change'] || 5;
    const changeInfo = {
      colorKeys: ['my-colors', 'theme-colors'],
      changeIndexKey: 'color-index-change',
      initStartIndex: START_CHANGE_INDEX_OF_SOLID_TAB,
      changeIndex,
    };
    return generateNewGlobalStyleColor(newColor, myColors.value, MAX_COLOR_SIZE, changeInfo);
  };

  return {
    myColors,
    SUGGESTED_COLOR_FOR_YOU,
    systemColor,
    getMyColorChange,
  };
};

export default useMyColor;
