<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.375 8C1.375 7.65482 1.65482 7.375 2 7.375H6C6.34518 7.375 6.625 7.65482 6.625 8V12C6.625 12.3452 6.34518 12.625 6 12.625H2C1.65482 12.625 1.375 12.3452 1.375 12V8ZM2.625 8.625V11.375H5.375V8.625H2.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.375 8C7.375 7.65482 7.65482 7.375 8 7.375H12C12.3452 7.375 12.625 7.65482 12.625 8V12C12.625 12.3452 12.3452 12.625 12 12.625H8C7.65482 12.625 7.375 12.3452 7.375 12V8ZM8.625 8.625V11.375H11.375V8.625H8.625Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 7.375C13.6548 7.375 13.375 7.65482 13.375 8V12C13.375 12.3452 13.6548 12.625 14 12.625H18C18.3452 12.625 18.625 12.3452 18.625 12V8C18.625 7.65482 18.3452 7.375 18 7.375H14ZM14.625 11.375V8.625H17.375V11.375H14.625Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'BgRepeatY'
};
</script>
