<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.875 10.625C18.875 10.9702 18.6152 11.25 18.2946 11.25L15.9732 11.25L15.9732 13.75C15.9732 14.7855 15.1937 15.625 14.2321 15.625L13.0714 15.625C12.1099 15.625 11.3304 14.7855 11.3304 13.75L11.3304 11.25L10.1696 11.25L10.1696 15.625C10.1696 16.6605 9.39014 17.5 8.42857 17.5L7.26786 17.5C6.30629 17.5 5.52679 16.6605 5.52679 15.625L5.52679 11.25L3.20536 11.25C2.88484 11.25 2.625 10.9702 2.625 10.625C2.625 10.2798 2.88484 10 3.20536 10L5.52679 10L5.52679 5.625C5.52679 4.58947 6.30629 3.75 7.26786 3.75L8.42857 3.75C9.39014 3.75 10.1696 4.58947 10.1696 5.625L10.1696 10L11.3304 10L11.3304 7.5C11.3304 6.46447 12.1099 5.625 13.0714 5.625L14.2321 5.625C15.1937 5.625 15.9732 6.46447 15.9732 7.5L15.9732 10L18.2946 10C18.6152 10 18.875 10.2798 18.875 10.625ZM6.6875 15.625C6.6875 15.9702 6.94734 16.25 7.26786 16.25L8.42857 16.25C8.74909 16.25 9.00893 15.9702 9.00893 15.625L9.00893 5.625C9.00893 5.27982 8.74909 5 8.42857 5L7.26786 5C6.94734 5 6.6875 5.27982 6.6875 5.625L6.6875 15.625ZM12.4911 13.75L12.4911 7.5C12.4911 7.15482 12.7509 6.875 13.0714 6.875L14.2321 6.875C14.5527 6.875 14.8125 7.15482 14.8125 7.5L14.8125 13.75C14.8125 14.0952 14.5527 14.375 14.2321 14.375L13.0714 14.375C12.7509 14.375 12.4911 14.0952 12.4911 13.75Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'AlignCenterVertical'
};
</script>
