<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path d="M18.8071 1.84668C18.8071 1.5015 18.5273 1.22168 18.1821 1.22168C17.837 1.22168 17.5571 1.5015 17.5571 1.84668L17.5571 18.0976C17.5571 18.4428 17.8369 18.7226 18.1821 18.7226C18.5273 18.7226 18.8071 18.4428 18.8071 18.0976L18.8071 1.84668Z" fill="currentColor"/>
  <path d="M2.50708 1.9021C2.50708 1.55692 2.22726 1.2771 1.88208 1.2771C1.5369 1.2771 1.25708 1.55692 1.25708 1.9021L1.25708 18.153C1.25708 18.4982 1.5369 18.778 1.88208 18.778C2.22726 18.778 2.50708 18.4982 2.50708 18.153L2.50708 1.9021Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.657 2.84277C14.6925 2.84277 15.532 3.68224 15.532 4.71777L15.532 15.2178C15.532 16.2533 14.6925 17.0928 13.657 17.0928L13.157 17.0928C12.1214 17.0928 11.282 16.2533 11.282 15.2178L11.282 4.71777C11.282 3.68224 12.1214 2.84277 13.157 2.84277L13.657 2.84277ZM14.282 4.71777C14.282 4.3726 14.0022 4.09277 13.657 4.09277L13.157 4.09277C12.8118 4.09277 12.532 4.3726 12.532 4.71777L12.532 15.2178C12.532 15.563 12.8118 15.8428 13.157 15.8428L13.657 15.8428C14.0022 15.8428 14.282 15.563 14.282 15.2178L14.282 4.71777Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.78198 4.71777C8.78198 3.68224 7.94252 2.84277 6.90698 2.84277L6.40698 2.84277C5.37145 2.84277 4.53198 3.68224 4.53198 4.71777L4.53198 15.2178C4.53198 16.2533 5.37145 17.0928 6.40698 17.0928L6.90698 17.0928C7.94252 17.0928 8.78198 16.2533 8.78198 15.2178L8.78198 4.71777ZM6.90698 4.09277C7.25216 4.09277 7.53198 4.3726 7.53198 4.71777L7.53198 15.2178C7.53198 15.563 7.25216 15.8428 6.90698 15.8428L6.40698 15.8428C6.0618 15.8428 5.78198 15.563 5.78198 15.2178L5.78198 4.71777C5.78198 4.37259 6.0618 4.09277 6.40698 4.09277L6.90698 4.09277Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'AlignSpaceBetween'
};
</script>
