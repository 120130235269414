import { inject } from 'vue';
import type { ItemManagementContext } from '../../types';

export const useItemManagementContext = () => {
  const context = inject<ItemManagementContext>('itemManagementContext');

  if (!context) {
    throw new Error('useItemManagementContext must be used within ItemManagementProvider');
  }
  return context;
};
