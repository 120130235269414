import { onMounted, ref, watch } from 'vue';

import type { ItemProps } from '../types';
import { useItemTitle } from './useItemTitle';
import { useItemActions } from './useItemActions';
import { useItemInteraction } from './useItemInteraction';

export const useItem = (props: ItemProps) => {
  const inputRef = ref<HTMLInputElement | null>(null);

  const itemActions = useItemActions(props);
  const itemInteractions = useItemInteraction(props);
  const itemTitleHandler = useItemTitle(props, { inputRef });

  onMounted(() => {
    if (itemTitleHandler.isEditingTitle.value) inputRef.value?.focus();
  });

  watch(
    () => props.item?.title,
    (value) => {
      itemTitleHandler.title.value = value;
    },
  );

  watch(
    () => itemTitleHandler.isEditingTitle.value,
    (value) => {
      if (!value || !inputRef.value) return;
      inputRef.value.focus();
    },
  );

  return {
    inputRef,
    ...itemActions,
    ...itemInteractions,
    ...itemTitleHandler,
  };
};
