import { ID } from '@gem/common';
import { computed, ref, watch } from 'vue';
import { COMPONENT_CONFIGS } from '../../constants';
import type { Item, ItemManagementProps } from '../../types';

export const useItemManagementState = (props: ItemManagementProps) => {
  const settings = computed<string[] | undefined>(() => props?.value);

  const convertData = () => {
    return (
      settings.value?.map((v) => {
        return {
          id: ID(),
          title: v,
        };
      }) ?? []
    );
  };

  const editingId = ref<string>('');
  const activeIds = ref<string[]>([]);
  const items = ref<Item[]>(convertData());
  const blurringTitleList = ref<string[]>([]);

  const itemInfo = computed(() => ({
    tag: props?.childTemplate?.tag ?? '',
    label: props?.childTemplate?.label ?? '',
    titleItem: COMPONENT_CONFIGS[props?.tag ?? '']?.title ?? props?.titleItem ?? '',
  }));

  watch(
    () => settings.value,
    () => {
      items.value = convertData();
    },
  );

  return {
    items,
    itemInfo,
    activeIds,
    editingId,
    blurringTitleList,
  };
};
