import {
  cloneDeepObject,
  convertComponentToJSON,
  getCompoDefaultValueByDevice,
  getCurrentValueFromControl,
  isObject,
  useControlSelectByCondition,
} from '@gem/common';
import type { ControlBaseProps, ControlChangeValueType, ControlProps, SettingUIControl } from '@gem/element-setting-ui';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import useSettingSideBarStore from './useSettingSideBarStore';

const useControlData = (data: { controlData: ComputedRef<SettingUIControl> }) => {
  const controlData = computed(() => data.controlData.value);
  const settingSideBarStore = useSettingSideBarStore();
  const controlComponents = computed(() => settingSideBarStore.getControlComponents);
  const editingPageType = computed(() => settingSideBarStore.getEditingPageType);
  const activeScreenId = computed(() => settingSideBarStore.currentDevice);
  const pickedDynamicProduct = computed(() => settingSideBarStore.pickedDynamicProduct);
  const pickedDynamicCollection = computed(() => settingSideBarStore.pickedDynamicCollection);
  const editingComponentUID = computed(() => settingSideBarStore.editingComponentUid);
  const themePageStatus = computed(() => settingSideBarStore.themePageStatus);
  const searchKeyword = computed(() => settingSideBarStore.searchKeyword);

  const controlSetting = computed(() => controlData.value.mapTo?.control);
  const section = computed(() => settingSideBarStore.section);
  const elementData = computed(() => settingSideBarStore.elementData);
  const controlType = computed(() => controlSetting?.value?.type);
  const sectionComponents = computed(() => {
    if (section?.value?.component) {
      const stringComponent = section.value.component;
      return convertComponentToJSON(stringComponent);
    }
    return null;
  });

  const currentComponentUid = computed(() => {
    return controlSetting?.value?.componentUid ?? editingComponentUID.value;
  });

  const getControlNameByType = computed(() => {
    if (controlType.value) {
      return controlComponents.value[controlType.value];
    }
    return '';
  });

  const componentStyles = computed(() => {
    return elementData.value?.styles;
  });

  const getRenderProps = computed((): ControlProps => {
    const controlChangeValueType: ControlChangeValueType = {
      controlType: '',
      hasDevices: false,
      val: undefined,
      options: undefined,
      devices: undefined,
      default: undefined,
      customizeOldData: undefined,
      componentUid: currentComponentUid.value,
      sectionId: section.value?.cid || '',
      id: controlData.value.setting?.id || controlData.value.id || '',
      groupType: controlData.value.mapTo?.tabId || 'setting',
      screen: activeScreenId.value,
      controlChangeTrigger: controlData.value.controlChangeTrigger,
      linkWithSetting: controlData.value.mapTo?.control.linkWithSetting,
      state: controlData.value.mapTo?.state,
      validate: controlData.value.mapTo?.validate,
    };
    const controlBaseProps: ControlBaseProps = {
      controlChangeData: controlChangeValueType,
    };
    if (!controlSetting.value) return controlBaseProps;
    const control = cloneDeepObject(controlSetting.value);
    controlChangeValueType.hasDevices = !!(control.devices && isObject(control.devices));
    controlChangeValueType.controlType = control.type;
    const appendProps: ControlProps = {
      ...controlBaseProps,
      componentTag: elementData.value?.tag,
      componentUid: currentComponentUid.value,
      componentLabel: elementData.value?.label,
      componentStyles: componentStyles.value,
      themePageStatus: themePageStatus.value,
      searchKeyword: searchKeyword.value,
      label: controlData.value?.label,
      level: controlData.value?.level,
      comboID: controlData.value?.comboID,
      comboParentID: controlData.value?.comboParentID,
      comboRootParentID: controlData.value?.comboRootParentID,
      ...(controlData.value?.options || {}),
    };

    // Merge control attrs to props
    const ignoreKeys = ['type', 'controls', 'links', 'hide', 'simple', 'allowReset', 'state', 'show', 'label'];
    Object.entries(control).forEach(([key, value]) => {
      if (ignoreKeys.includes(key)) return;
      appendProps[key] = value;
    });

    const controlValue = getCurrentValueFromControl({
      control: control,
      screenId: activeScreenId.value,
    });

    if (control.compoDefaultValue) {
      appendProps.compoDefaultValue = getCompoDefaultValueByDevice(control.compoDefaultValue, activeScreenId.value);
    }

    if (appendProps.controlChangeData) {
      appendProps.controlChangeData.default = control.default;
      appendProps.controlChangeData.devices = control.devices;
    }
    appendProps.value = controlValue;
    if (['visibility', 'layout', 'grid'].includes(control.type)) {
      appendProps.devices = control.devices;
    }
    modifyValueByState(appendProps, controlValue);
    modifyCompoDefaultValueByState(appendProps, appendProps.compoDefaultValue);
    modifyValueProductSetting(appendProps);
    modifyValueByOptions(appendProps);

    return appendProps;
  });

  const modifyCompoDefaultValueByState = (sourceProps: Record<string, any>, compoDefaultValue?: any) => {
    if (controlData?.value?.mapTo?.state) {
      let stateVal = compoDefaultValue?.[controlData?.value.mapTo?.state];
      if (stateVal == undefined) {
        stateVal = compoDefaultValue?.normal;
      }
      sourceProps.compoDefaultValue = stateVal;
    }
  };

  const modifyValueByState = (sourceProps: Record<string, any>, defaultValue: any) => {
    if (controlData?.value?.mapTo?.state) {
      let stateVal = defaultValue?.[controlData?.value.mapTo?.state];
      if (stateVal == undefined) {
        stateVal = defaultValue?.normal;
      }
      sourceProps.value = stateVal;
    }
  };

  const modifyValueByOptions = (sourceProps: Record<string, any>) => {
    if (sourceProps.options?.length) {
      const { newOptions, isChangeOption } = useControlSelectByCondition(
        sourceProps.options,
        sectionComponents.value,
        currentComponentUid.value,
      );
      sourceProps.options = newOptions;
      if (sourceProps.value && isChangeOption) {
        const existValue = sourceProps.options.find((item: any) => item.value == sourceProps.value);
        if (!existValue) {
          sourceProps.value = sourceProps.options?.[0]?.value;
          sourceProps.triggerControlChange = true;
        }
      }
    }
  };

  const modifyValueProductSetting = (sourceProps: Record<string, any>) => {
    const pageType = editingPageType.value;
    if (
      pageType == 'GP_PRODUCT' &&
      sourceProps.componentTag == 'Product' &&
      sourceProps.id == 'productSetting' &&
      sourceProps.default?.productId == 'latest' &&
      sourceProps.default?.productStatus == 'dynamic' &&
      pickedDynamicProduct.value?.productId
    ) {
      sourceProps.value = pickedDynamicProduct;
      sourceProps.value.isUpdatedProduct = true;
    } else if (
      pageType == 'GP_COLLECTION' &&
      sourceProps.componentTag == 'ProductList' &&
      sourceProps.id == 'collectionId'
    ) {
      sourceProps.value = pickedDynamicCollection.value?.collectionId;
    }
  };

  return {
    controlName: getControlNameByType,
    controlProps: getRenderProps,
  };
};

export default useControlData;
