<template>
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.63478 1.39265C9.79196 1.57861 9.78781 1.87537 9.62551 2.05547L3.71176 8.61797C3.55312 8.79401 3.30118 8.79401 3.14255 8.61797L0.874484 6.10098C0.712189 5.92088 0.708046 5.62412 0.86523 5.43815C1.02241 5.25219 1.2814 5.24744 1.4437 5.42755L3.42716 7.6287L9.05631 1.38203C9.21861 1.20192 9.4776 1.20668 9.63478 1.39265Z"
      fill="#F9F9F9"
      stroke="#F9F9F9"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'Checkbox',
};
</script>
