import { computed, ref } from 'vue';
import type { ContentRelatedType, RelatedPickerEmit, RelatedPickerProps } from '../type';
export const useHandleDataRelatedPicker = (emit: RelatedPickerEmit, props: RelatedPickerProps) => {
  const isShowNotice = ref<boolean>(true);
  const contentRelated = computed<ContentRelatedType>(() => {
    switch (relatedType.value) {
      case 'collection':
        return {
          tooltip: 'Related products are in the same collection as the assigned product',
          placeholder: 'List collection handles to exclude (separate with commas)',
        };
      case 'vendor':
        return {
          tooltip: 'Related products are in the same vendor as the assigned product',
          placeholder: '',
        };
      case 'tags':
        return {
          tooltip: 'Related products are in the same tags as the assigned product',
          placeholder: 'List product tags to exclude (separate with commas)',
        };
      default:
        return {
          tooltip: '',
          placeholder: '',
        };
    }
  });

  const relatedTag = computed(() => props.value?.relatedTag);
  const relatedExclude = computed(() =>
    relatedTag.value && relatedTag.value[0] === 'tags'
      ? props.value?.relatedTagsExclude
      : props.value?.relatedCollectionExclude,
  );
  const relatedType = computed(() => {
    if (!relatedTag.value) return 'collection';
    return relatedTag.value?.[0];
  });

  // Handle change condition, exclude
  const handleChange = (settingID?: string | number, value?: string | any) => {
    if (!settingID) return;
    if (settingID === 'relatedTag') {
      emit('controlChange', { [settingID]: [value] });
      return;
    }
    if (settingID === 'relatedExclude') {
      const settingIdExclude =
        relatedTag.value && relatedTag.value[0] === 'tags' ? 'relatedTagsExclude' : 'relatedCollectionExclude';
      emit('controlChange', { [settingIdExclude]: value, hasRelatedExclude: !!value });
      return;
    }
  };

  const onCloseNotice = () => {
    isShowNotice.value = false;
  };
  return {
    isShowNotice,
    relatedExclude,
    handleChange,
    onCloseNotice,
    relatedType,
    relatedTag,
    contentRelated,
  };
};
