import { inject } from 'vue';
import type { ImageSelectContext } from '../types';

export const useImageSelectContext = () => {
  const context = inject<ImageSelectContext>('imageSelectContext');

  if (!context) {
    throw new Error('useImageSelectContext must be used within ImageSelectProvider');
  }
  return context;
};
