<script lang="ts" setup>
import { OptionValue, SettingTitle } from '@gem/element-setting-ui';
import { GButton, GPopover } from '@gem/uikit-v2';
import CollectionPicker from './components/CollectionPicker.vue';
import MultiProductPicker from './components/MultiProductPicker.vue';
import RelatedPicker from './components/RelatedPicker.vue';
import SearchComponent from './components/SearchComponent.vue';
import { useHandleDataMultiProductModal } from './hooks/useHandleDataMultiProductModal';
import type { ProductListPickerProps, ProductListPickerTypesEmit } from './type';

const props = defineProps<ProductListPickerProps>();
const emit = defineEmits<ProductListPickerTypesEmit>();

const {
  close: handleClose,
  openModal,
  searchKeyword,
  searchProductListReference,
  multiProductPickerReference,
  handleSave,
  isDisabledSave,
  handleSelectTypePicker,
  setValueSelectedProducts,
  setValueSelectedCollection,
  isShowBtnSave,
  isShowSearch,
  optionList,
} = useHandleDataMultiProductModal(emit, props);
</script>
<template>
  <div>
    <div ref="reference">
      <GPopover
        ref="popoverReference"
        cls="bg-dark-500"
        button-class="!w-full"
        wrap-content-class="!w-full"
        placement="right-start"
        :offset-top="-8"
        :no-padding="true"
        :placement-offset="20"
        :enable-flip="false"
        :overlay-container="'#sidebar'"
        :ignore-outside-class="['gp-combo-layout', 'popover-control-modal', 'gallery-model-container']"
        :detect-side-bar="true"
        :force-open="open"
        @open="openModal"
        @close="handleClose"
        @click-out-side="handleClose">
        <slot name="btn-popover"></slot>
        <template #content="{ close }">
          <div class="max-h-[516px] w-[280px]">
            <div class="border-dark-300 flex h-[52px] items-center justify-between border-b pl-8 pr-10">
              <div class="flex h-full items-center gap-8 px-8">
                <SettingTitle :label="{ en: 'Select product list' }" variant="primary" />
              </div>
              <div class="flex h-full w-fit items-center justify-center gap-4">
                <g-tooltip placement="top" :is-teleport="true">
                  <GButton
                    :loading="isLoading"
                    size="semi-medium"
                    type="ghost"
                    only-icon="polaris-refresh"
                    :disable="isLoading"
                    @click.stop="emit('refresh')" />
                  <template #content>Sync product</template>
                </g-tooltip>
                <GButton only-icon="polaris-x" type="ghost" size="semi-medium" @click="close()" />
              </div>
            </div>
            <div class="flex flex-col gap-8 py-16">
              <div class="px-16">
                <OptionValue
                  id="productSrc"
                  :value="productSrcType"
                  :options="optionList"
                  @control-change="handleSelectTypePicker" />
              </div>
              <SearchComponent
                v-if="isShowSearch"
                ref="searchProductListReference"
                :product-src-type="productSrcType"
                @on-search="(value) => emit('onSearch', value)" />
              <MultiProductPicker
                v-if="productSrcType === 'PickProduct'"
                ref="multiProductPickerReference"
                :value="valueProductIds"
                :list="list"
                :page-type="pageType"
                :max-allow-selected="maxAllowSelected"
                :variant-selected="variantSelected"
                :search-keyword="searchKeyword"
                :is-post-purchase="isPostPurchase"
                :is-load-more="isFetchingNextPage"
                :is-not-have-product="isNoProductInStore"
                :navigate-to-product-shopify="navigateToProductShopify"
                @close="close"
                @set-value-selected="setValueSelectedProducts"
                @show-more="emit('showMore', 'fetch')" />
              <CollectionPicker
                v-if="productSrcType === 'Collection'"
                :value="valueCollection"
                :list="collectionList"
                :is-load-more="isFetchingNextPage"
                :search-keyword="searchKeyword"
                :navigate-to-collection-shopify="navigateToCollectionShopify"
                :is-not-have-collection="isNoCollectionInStore"
                @set-value-selected-collection="setValueSelectedCollection"
                @show-more="emit('showMore', 'fetch')" />
              <RelatedPicker
                v-if="productSrcType === 'RelatedProduct'"
                :value="value"
                @control-change="(value) => emit('controlChangeRelated', value)"></RelatedPicker>
            </div>
            <div v-if="isShowBtnSave" class="border-dark-300 border-t px-16 py-14">
              <GButton
                type="secondary"
                :disable="isDisabledSave"
                size="medium"
                class="text-12 font-regular bg-dark-300 relative w-full"
                @click.stop="handleSave">
                Save
              </GButton>
            </div>
          </div>
        </template>
      </GPopover>
    </div>
  </div>
</template>
<style lang="css">
@media (max-height: 580px) {
  .max-height-list {
    max-height: calc(100vh - 300px) !important;
  }
}
@media (max-height: 520px) {
  .max-height-collection-list {
    max-height: calc(100vh - 250px) !important;
  }
}

.max-height-list {
  max-height: 282px;
}

.max-height-collection-list {
  max-height: 282px;
}
</style>
