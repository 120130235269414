<script setup lang="ts">
import { GBanner } from '@gem/uikit-v2';
import FontListItem from './FontListItem.vue';
import FontListHeader from './FontListHeader.vue';
import { useFontList } from '../hooks/useFontList';
import type { FontListProps, FontListEmits } from '../types';

const emit = defineEmits<FontListEmits>();
const props = defineProps<FontListProps>();

const {
  fontList,
  wrapperProps,
  containerProps,
  resetScroll,
  handleSelectFont,
  getFontListItemProps,
  handleOpenDeleteModal,
  handleChangeSourceFont,
  getFontListHeaderLabel,
} = useFontList(props, emit);

defineExpose({ resetScroll });
</script>

<template>
  <div
    v-bind="containerProps"
    class="scrollbar:!w-[4px] scrollbar:bg-transparent scrollbar-track:!rounded-full scrollbar-thumb:!rounded-full scrollbar-track:!cursor-grabbing scrollbar-thumb:hover:cursor-pointer scrollbar-thumb:!bg-dark-200 h-full max-h-[calc(100vh-350px)] overflow-x-hidden pr-[6px]">
    <div v-bind="wrapperProps">
      <GBanner :closable="false" message="You should use up to 3 fonts max." />
      <template v-for="font in fontList" :key="font.index">
        <FontListHeader
          v-if="font.data?.isLabel"
          :label="getFontListHeaderLabel(font.data?.label)"
          @handle-change-source="handleChangeSourceFont" />
        <FontListItem
          v-else
          v-bind="getFontListItemProps(font.data)"
          @click="handleSelectFont(font.data.value, font.data.type)"
          @open-modal-delete="(e) => handleOpenDeleteModal(e, font.data)" />
      </template>
    </div>
  </div>
</template>
