export const replaceInvalidHandleCharacters = (string: string, replaceValue?: string) => {
  return string?.replace(/[^-_A-Za-z0-9ぁ-んァ-ンwа-я\u4E00-\u9FA5\u3131-\uD79D]/g, replaceValue ?? '-');
};

export const validateUrlHandle = (string?: string) =>
  !!string &&
  /^[A-Za-z0-9ぁ-んァ-ンwа-я\u4E00-\u9FA5\u3131-\uD79D_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+(?:-[A-Za-z0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+)*$/.test(
    string,
  );

export const capitalizeSentences = (text: string) => {
  return text
    .split(/([.!?]\s*)/)
    .map((sentence) => sentence.charAt(0).toUpperCase() + sentence.slice(1))
    .join('');
};
