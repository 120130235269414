<script setup lang="ts">
import type { ImageShapeOption, ImageShapeValue } from './type';
import type { ScreenType } from '../../types';
import FieldLabel from '../FieldLabel.vue';
import Segment from '../Segment.vue';
import InputUnit from '../InputUnit.vue';
import { computed, ref } from 'vue';
import useUpdateDataImageShape from './useUpdateDataImageShape.ts';
type Props = {
  id: string;
  value?: ImageShapeValue;
  currentScreen?: ScreenType;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'onChange', controlId?: string, value?: string): void;
  (e: 'change', controlId?: string, value?: string): void;
  (e: 'changeScreen', screenId: ScreenType): void;
}>();

const inputWidthFocus = ref<boolean>(false);

const shapeOptions = [
  {
    label: 'Square',
    value: 'square',
    type: 'image-shape',
  },
  {
    label: 'Vertical',
    value: 'vertical',
    type: 'image-shape',
  },
  {
    label: 'Horizontal',
    value: 'horizontal',
    type: 'image-shape',
  },
  {
    label: 'Custom',
    value: 'custom',
    type: 'image-shape',
  },
];

const previousShapeValue = ref(props.value?.shape);

const { getValueWhenUpdateSetting } = useUpdateDataImageShape(computed(() => props.value));

const handleUpdateHeight = (type: 'change' | 'onChange', value?: string) => {
  const newData = getValueWhenUpdateSetting('height', value, previousShapeValue);
  if (value && props.value?.shape !== 'custom') {
    emit('change', props.id, newData);
    return;
  }
  if (!value && props.value?.shape === 'custom' && type == 'change') {
    emit('change', props.id, newData);
    return;
  }
  type === 'change' ? emit('change', props.id, newData) : emit('onChange', props.id, newData);
};

const handleUpdateShape = (type: 'change' | 'onChange', value?: ImageShapeOption) => {
  previousShapeValue.value = value;
  inputWidthFocus.value = value === 'custom';
  const newData = getValueWhenUpdateSetting('shape', value, previousShapeValue);
  type === 'change' ? emit('change', props.id, newData) : emit('onChange', props.id, newData);
};

const handleUpdateWidth = (type: 'change' | 'onChange', value?: string) => {
  const newData = getValueWhenUpdateSetting('width', value, previousShapeValue);
  type === 'change' ? emit('change', props.id, newData) : emit('onChange', props.id, newData);
};

const handleUpdateData = (type: 'change' | 'onChange', settingID: string, value?: string) => {
  switch (settingID) {
    case 'height':
      handleUpdateHeight(type, value);
      break;
    case 'shape':
      handleUpdateShape(type, value as ImageShapeOption);
      break;
    default: {
      handleUpdateWidth(type, value);
    }
  }
};

const handleChange = (settingID: string, value?: string) => {
  handleUpdateData('change', settingID, value);
};

const handleOnChange = (settingID: string, value?: string) => {
  handleUpdateData('onChange', settingID, value);
};
</script>
<template>
  <div class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
    <FieldLabel
      label="Shape"
      :has-device="true"
      :current-screen="currentScreen"
      @change-screen="$emit('changeScreen', $event)" />
    <div class="max-w-input-horizontal w-full">
      <Segment
        id="shape"
        :value="value?.shape"
        :options="shapeOptions"
        @control-change="handleChange"
        @control-on-change="handleOnChange" />
    </div>
  </div>
  <div class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
    <FieldLabel
      label="Width"
      :has-device="true"
      :current-screen="currentScreen"
      @change-screen="$emit('changeScreen', $event)" />
    <div class="max-w-input-horizontal w-full">
      <InputUnit
        id="width"
        :focus="inputWidthFocus"
        :value="value?.width"
        :units="['px', '%']"
        :empty-on-clear="true"
        :control-change="handleChange"
        @control-on-change="handleOnChange" />
    </div>
  </div>
  <div class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
    <FieldLabel
      label="Height"
      :has-device="true"
      :current-screen="currentScreen"
      @change-screen="$emit('changeScreen', $event)" />
    <div class="max-w-input-horizontal w-full">
      <InputUnit
        id="height"
        :value="value?.height"
        :units="['px']"
        :empty-on-clear="true"
        :control-change="handleChange"
        @control-on-change="handleOnChange" />
    </div>
  </div>
</template>
