<template>
  <svg width="100%" height="100%" viewBox="0 0 94 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.70327 35.5447C2.23663 34.9365 1.06348 33.7634 0.455283 32.2967L0.917146 32.1052C0.648515 31.4574 0.5 30.7467 0.5 30V28H0V24H0.5V20H0V16H0.5V12H0V8H0.5V6C0.5 5.25333 0.648515 4.5426 0.917146 3.8948L0.455283 3.70327C1.06348 2.23663 2.23663 1.06348 3.70327 0.455283L3.8948 0.917146C4.5426 0.648515 5.25332 0.5 6 0.5H8.05001V0H12.15V0.5H16.25V0H20.35V0.5H24.45V0H28.55V0.5H32.65V0H36.75V0.5H40.85V0H44.95V0.5H49.05V0H53.15V0.5H57.25V0H61.35V0.5H65.45V0H69.55V0.5H73.65V0H77.75V0.5H81.85V0H85.95V0.5H88C88.7467 0.5 89.4574 0.648515 90.1052 0.917147L90.2967 0.455283C91.7634 1.06348 92.9365 2.23663 93.5447 3.70327L93.0829 3.8948C93.3515 4.5426 93.5 5.25333 93.5 6V8H94V12H93.5V16H94V20H93.5V24H94V28H93.5V30C93.5 30.7467 93.3515 31.4574 93.0829 32.1052L93.5447 32.2967C92.9365 33.7634 91.7634 34.9365 90.2967 35.5447L90.1052 35.0829C89.4574 35.3515 88.7467 35.5 88 35.5H85.95V36H81.85V35.5H77.75V36H73.65V35.5H69.55V36H65.45V35.5H61.35V36H57.25V35.5H53.15V36H49.05V35.5H44.95V36H40.85V35.5H36.75V36H32.65V35.5H28.55V36H24.45V35.5H20.35V36H16.25V35.5H12.15V36H8.05V35.5H6C5.25332 35.5 4.5426 35.3515 3.8948 35.0829L3.70327 35.5447Z" stroke="#5E5E5E" stroke-dasharray="4 4"/>
<rect x="8" y="16" width="78" height="4" rx="1.8125" fill="#808080" fill-opacity="0.2"/>
<path d="M48.9768 12.7513C48.3812 12.5915 47.7237 12.4999 47 12.4999C44.5556 12.4999 42.8045 13.5447 41.6746 14.7331C41.1138 15.3229 40.7039 15.9494 40.4315 16.5063C40.1714 17.0381 40 17.5817 40 17.9999C40 18.418 40.1714 18.9617 40.4315 19.4935C40.6042 19.8465 40.8321 20.2275 41.1187 20.6094L42.1929 19.5352C42.0237 19.2916 41.8862 19.0537 41.779 18.8344C41.5576 18.3818 41.5 18.0817 41.5 17.9999C41.5 17.918 41.5576 17.618 41.779 17.1654C41.9881 16.7379 42.3123 16.2394 42.7617 15.7667C43.6522 14.83 45.0261 13.9999 47 13.9999C47.2406 13.9999 47.4713 14.0122 47.6925 14.0356L48.9768 12.7513Z" fill="#F9F9F9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M41.2491 22.6002C40.9639 22.8936 40.9665 23.3627 41.2568 23.653C41.5497 23.9459 42.0246 23.9459 42.3175 23.653L43.3788 22.5917C44.3538 23.1352 45.5559 23.4999 47 23.4999C49.4504 23.4999 51.1417 22.4497 52.2397 21.2581C53.3176 20.0881 53.8276 18.7819 53.9776 18.1818C54.0075 18.0624 54.0075 17.9374 53.9776 17.818C53.8276 17.2178 53.3176 15.9116 52.2397 14.7417C52.0822 14.5708 51.9125 14.4028 51.7301 14.2404L52.6531 13.3174C52.946 13.0245 52.946 12.5497 52.6531 12.2568C52.3627 11.9664 51.8935 11.9639 51.6001 12.2492C51.5975 12.2518 51.595 12.2544 51.5924 12.257L41.2568 22.5926C41.2543 22.5951 41.2517 22.5977 41.2491 22.6002ZM47 21.9999C46.0221 21.9999 45.1915 21.7961 44.4939 21.4766L45.6022 20.3683C46.0117 20.6104 46.4896 20.7497 47 20.7497C48.5188 20.7497 49.75 19.5185 49.75 17.9997C49.75 17.4895 49.6107 17.0114 49.3686 16.6019L50.6674 15.3031C50.8369 15.4495 50.993 15.6023 51.1365 15.7581C51.9054 16.5925 52.3124 17.5138 52.4695 17.9999C52.3124 18.486 51.9054 19.4072 51.1365 20.2417C50.2766 21.175 48.9679 21.9999 47 21.9999ZM47 19.2497C46.9132 19.2497 46.8284 19.2409 46.7465 19.224L48.2244 17.7461C48.2412 17.828 48.25 17.9128 48.25 17.9997C48.25 18.69 47.6904 19.2497 47 19.2497Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'InputHiddenField'
};
</script>
