<script setup lang="ts">
import type { ControlToggleTypes } from '@gem/element-setting-ui';

const props = defineProps<ControlToggleTypes>();
const emit = defineEmits<{
  (e: 'controlChange', value?: boolean): void;
}>();

const toggle = () => {
  if (props.readonly) return;
  emit('controlChange', !props.value);
};
</script>

<template>
  <div class="gemx-toggle-bound flex items-center">
    <div class="gemx-toggle_switch group/toggle select-none" data-test="editor-control-toggle" @click="toggle">
      <div
        class="gemx-toggle_switch_line transition-all"
        :class="{
          active: value,
          disable: readonly,
          'bg-primary-300 group-hover/toggle:!bg-underlay-blue-150 value-true': value,
          'bg-text-dark-100 group-hover/toggle:bg-dark-200': !value,
        }" />
      <span
        class="gemx-toggle_switch_slider bg-text-dark-500"
        :class="{
          active: value,
          disable: readonly,
          'group-hover/toggle:bg-overlay-white value-true': value,
          'group-hover/toggle:bg-text-dark-100': !value,
        }" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.gemx-toggle-bound {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;

  :deep(.control-label) {
    margin: 0;
    padding-right: 5px;
    width: calc(100% - 55px);
    align-items: center;
  }
  .gemx-toggle_switch {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    cursor: pointer;
    --width: 36px;
    width: var(--width);

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    input {
      opacity: 0;
      position: absolute;
      $overflow: 40%;
      top: calc(0% - #{$overflow} / 2);
      bottom: calc(0% - #{$overflow} / 2);
      left: calc(0% - #{$overflow} / 2);
      right: calc(0% - #{$overflow} / 2);
      width: calc(100% + #{$overflow});
      z-index: 2;
      height: calc(100% + #{$overflow});
      display: flex;
      box-sizing: border-box;
      cursor: pointer;
    }
    .gemx-toggle_switch_line {
      position: absolute;
      width: 36px;
      --height: 20px;
      height: var(--height);
      top: calc(50% - var(--height) / 2);
      border-radius: 20px;
    }
    .gemx-toggle_switch_slider {
      --size: 16px;
      // position: absolute;
      width: var(--size);
      height: var(--size);

      top: calc(50% - var(--size) / 2);
      left: 0;
      border-radius: 50%;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      -webkit-transform: translateX(2px);
      transform: translateX(2px);

      &.active {
        -webkit-transform: translateX(calc(var(--width) - var(--size) - 2px));
        transform: translateX(calc(var(--width) - var(--size) - 2px));
      }
      &.disable {
        background-color: #494949;
        &.value-true {
          cursor: not-allowed;
          background-color: #0e3c82;
        }
      }
    }
  }
}
</style>
