<script setup lang="ts">
import { computed } from 'vue';

type PropsType = {
  show: boolean;
  type: 'size' | 'format' | undefined;
  maximumSize: number;
};

const props = defineProps<PropsType>();
const emit = defineEmits<{
  (e: 'close'): void;
}>();

const fileSize = computed(() => {
  return props.maximumSize / (1024 * 1024);
});
</script>
<template>
  <g-modal :is-open="show" :hide-header="true" :hide-actions="true" :click-out-side-close="true">
    <div class="max-w-[430px] p-40 text-center">
      <div v-if="type === 'size'">
        <div class="text-20 mb-16 font-medium">Your file is too heavy!</div>
        <div class="text-14 text-text-light-300 flex flex-col justify-center">
          <span>Your file exceed maximum size ({{ fileSize }}MB)</span>
          <span>Please crop or compress image/gif to reduce file size</span>
        </div>
      </div>
      <div v-else>
        <div class="text-18 mb-16 font-medium">
          Image format not supported. Please upload images in JPG, JPEG, GIF, PNG, SVG, WEBP formats.
        </div>
      </div>
      <GButtonV2
        class="mt-32 w-full justify-center"
        @click="
          () => {
            emit('close');
          }
        "
        >Got it</GButtonV2
      >
    </div>
  </g-modal>
</template>
