<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.625 7.39301C15.625 6.35747 14.7855 5.51801 13.75 5.51801L10.625 5.51801L10.625 1.93439C10.625 1.58921 10.3452 1.30939 10 1.30939C9.65482 1.30939 9.375 1.58921 9.375 1.93439L9.375 5.51801L6.25 5.51801C5.21447 5.51801 4.375 6.35747 4.375 7.39301L4.375 12.893C4.375 13.9285 5.21447 14.768 6.25 14.768L9.375 14.768L9.375 18.3033C9.375 18.6485 9.65482 18.9283 10 18.9283C10.3452 18.9283 10.625 18.6485 10.625 18.3033L10.625 14.768L13.75 14.768C14.7855 14.768 15.625 13.9285 15.625 12.893L15.625 7.39301ZM13.75 6.76801C14.0952 6.76801 14.375 7.04783 14.375 7.39301L14.375 12.893C14.375 13.2382 14.0952 13.518 13.75 13.518L6.25 13.518C5.90482 13.518 5.625 13.2382 5.625 12.893L5.625 7.39301C5.625 7.04783 5.90482 6.76801 6.25 6.76801L13.75 6.76801Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'JustifyContentMiddle'
};
</script>
