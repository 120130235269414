import type { BgAttachmentOptions, BgRepeatOptions, BgSizeOptions } from './types';

export const BG_SIZE_OPTIONS: BgSizeOptions = [
  { label: 'Cover', value: 'cover', type: 'image', svgName: 'gp-image-cover' },
  { label: 'Contain', value: 'contain', type: 'image', svgName: 'gp-image-contain' },
];

export const BG_REPEAT_OPTIONS: BgRepeatOptions = [
  {
    value: 'repeat',
    iconName: 'gp-bg-repeat',
  },
  {
    value: 'repeat-y',
    iconName: 'gp-bg-repeat-y',
  },
  {
    value: 'repeat-x',
    iconName: 'gp-bg-repeat-x',
  },
  {
    value: 'no-repeat',
    iconName: 'polaris-disabled',
  },
];

export const BG_ATTACHMENT_OPTIONS: BgAttachmentOptions = [
  { value: 'scroll', title: 'Scroll' },
  { value: 'fixed', title: 'Fixed' },
  { value: 'local', title: 'Local' },
];

export const BG_QUALITY = [{ value: 'finest', label: 'Finest' }];

export const BG_VIDEO_TYPE = [
  { value: 'html5', title: 'Video hosting' },
  { value: 'youtube', title: 'Youtube' },
];
