import * as Sentry from '@sentry/vue';
import { Transaction } from '@sentry/vue';

import { usePerformanceStore } from '../store';
import { TransactionOptions } from '../type';

// Only use childOperationNameList when you want to root transaction to automatically finish when all child operations are finished
export const sentryTrackingStartTransaction = (name: string, options?: TransactionOptions) => {
  const performanceStore = usePerformanceStore();
  if (performanceStore.getTransactionMap.has(name)) {
    return console.warn(`Transaction with name ${name} already started`);
  }

  const transaction = Sentry.startTransaction({
    name,
    op: options?.operationName,
  });
  const childOperationMap = options?.childOperationNameList?.reduce((map, operationName) => {
    map.set(operationName, undefined);
    return map;
  }, new Map<string, Transaction | undefined>());

  performanceStore.addTransaction(transaction, childOperationMap);
};

export const sentryTrackingFinishTransaction = (name: string, options?: Record<string, any>) => {
  const performanceStore = usePerformanceStore();
  const transactionObject = performanceStore.getTransactionMap.get(name);
  if (!transactionObject) {
    return console.warn(`Transaction with name ${name} not found`);
  }

  if (options?.forceFinish) {
    transactionObject.transaction.finish();
    performanceStore.transactionMap.delete(name);
    return;
  }

  if (transactionObject.childOperationList?.size) {
    return console.warn(`All child operations must be finished before finishing transaction`);
  }

  transactionObject.transaction.finish();
  performanceStore.transactionMap.delete(name);
};

export const sentryTrackingStartChildOp = (rootTransactionName: string, operationName: string) => {
  const performanceStore = usePerformanceStore();
  const transactionObject = performanceStore.getTransactionMap.get(rootTransactionName);
  if (!transactionObject) {
    return console.warn(`Transaction with name ${rootTransactionName} not found`);
  }

  if (!transactionObject.childOperationList?.has(operationName)) {
    return console.warn(`Child operation with name ${operationName} not found`);
  }

  const childOperation = transactionObject.transaction.startChild({ op: operationName });
  transactionObject.childOperationList?.set(operationName, childOperation);
  performanceStore.updateTransaction(rootTransactionName, transactionObject);
};

export const sentryTrackingFinishChildOp = (
  rootTransactionName: string,
  operationName: string,
  options?: TransactionOptions,
) => {
  const performanceStore = usePerformanceStore();
  const transactionObject = performanceStore.getTransactionMap.get(rootTransactionName);
  if (!transactionObject) {
    return console.warn(`Transaction with name ${rootTransactionName} not found`);
  }

  const childOperation = transactionObject?.childOperationList?.get(operationName);
  if (!childOperation) {
    transactionObject.childOperationList?.delete(operationName);
    return;
  }

  options?.status && childOperation.setTag('status', options.status);
  childOperation.finish();
  transactionObject.childOperationList?.delete(operationName);
  if (transactionObject.childOperationList?.size !== 0) {
    performanceStore.updateTransaction(rootTransactionName, transactionObject);
    return;
  }

  sentryTrackingFinishTransaction(rootTransactionName);
  if (options?.finishCallback) {
    options.finishCallback();
  }
};
