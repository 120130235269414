<script lang="ts" setup>
import type { ControlOptionValueTypes, OptionItem } from '@gem/element-setting-ui';
import { GIcon } from '@gem/icons';
import { GTooltip } from '@gem/uikit';

const props = defineProps<ControlOptionValueTypes>();
const emit = defineEmits<{
  (e: 'controlChange', value?: OptionItem['value']): void;
}>();

const change = (v?: OptionItem['value']) => {
  if (props.value === v || props.disableToggle) {
    return;
  }

  emit('controlChange', v);
};

const isActiveOptionItem = (item: OptionItem) => {
  return props.value === item.value && !props.disabled;
};
</script>

<template>
  <div
    data-test="editor-control-option-value"
    class="bg-dark-400 flex h-36 w-full items-center gap-4 rounded-xl p-[2px]">
    <template v-for="item in options?.filter((item) => !item.hide)" :key="item.value">
      <div
        class="text-12 hover:text-light-200 flex h-full flex-1 cursor-pointer select-none items-center justify-center rounded-[6px] text-center font-medium transition-all"
        :class="
          isActiveOptionItem(item)
            ? 'bg-dark-200 text-text-dark-500 cursor-default'
            : `text-text-dark-300 bg-dark-400 hover:bg-dark-150`
        "
        @click.stop="change(item.value)">
        <GTooltip
          placement="top"
          :disabled="!item.tooltip"
          class="!flex h-full w-full items-center justify-center"
          :class="{
            'w-[86px]': item.label === 'Net Discount',
          }">
          <GIcon v-if="item.iconName" :name="item.iconName" />
          <span v-else v-html="item.label" />
          <template v-if="item.tooltip" #content>
            <div class="text-light-100 text-12">{{ item.tooltip }}</div>
          </template>
        </GTooltip>
      </div>
    </template>
  </div>
</template>
