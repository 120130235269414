<script setup lang="ts">
import TypographyFormEdit from './TypographyFormEdit.vue';
import type { TypoControlProps } from './types';
import { useTypoGraphyControl } from './hooks/useTypographyControl';
import { SettingLayout } from '@gem/element-setting-ui';
import TypographyStyleSelect from './TypographyStyleSelect.vue';

const props = defineProps<TypoControlProps>();

const emit = defineEmits<{
  (e: 'controlChange', value: TypoControlProps['value']): void;
  (e: 'controlOnChange', value: TypoControlProps['value']): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'changeTypo', type: string): void;
  (e: 'saveColor', newColorList: {}): void;
}>();

const {
  displayValue,
  displayAttr,
  typoName,
  fontWeightOptions,
  handleSaveColor,
  handleControlChange,
  handleControlOnChange,
  handleChangeTypo,
} = useTypoGraphyControl(props, emit);
</script>

<template>
  <div class="flex w-full flex-col gap-16">
    <SettingLayout :label="{ en: 'Styles' }" :label-variant="'secondary'">
      <template #control>
        <TypographyStyleSelect
          :options="typos"
          data-test="editor-control-typo-select"
          :active-id="value?.type"
          @on-click-sub-action="(type: string) => emit('onClickSubAction', type)"
          @select="handleChangeTypo">
          <template #button-content>
            <span
              class="text-12 truncate whitespace-nowrap"
              :class="{
                italic: isCustom,
              }">
              {{ typoName }}{{ isCustom ? '*' : '' }}
            </span>
          </template>
        </TypographyStyleSelect>
      </template>
    </SettingLayout>

    <TypographyFormEdit
      v-bind="props"
      :font-options="fontOptions"
      :value="displayValue"
      :attrs="displayAttr"
      :current-screen="currentScreen"
      :hidden-setting="hiddenSetting"
      :font-weight-options="fontWeightOptions"
      :search-query="searchQuery"
      :global-colors="globalColors"
      :global-style-font="globalStyleFont"
      :font-backup="fontBackup"
      :font-uploads="fontUploads"
      :theme-fonts="themeFonts"
      :tag="tag"
      :disable-collapse="disableCollapse"
      @save-color="handleSaveColor"
      @change="handleControlChange"
      @on-change="handleControlOnChange" />
  </div>
</template>
