<script lang="ts" setup>
import { SettingLayout, InputText } from '@gem/element-setting-ui';
import { ref, computed, onMounted } from 'vue';

const props = defineProps<{ value?: string }>();
const refInputPhone = ref<any>();
const phoneNumberValue = ref('');
const val = computed(() => props.value);

const emit = defineEmits<{
  (e: 'change-phone', value: string): void;
}>();

const setPhoneValue = () => {
  phoneNumberValue.value = val.value?.replace('tel:', '') || '';
};

const changePhoneNumber = (value: string) => {
  const validValue = value.replace(/[^0-9]/g, '');
  emit('change-phone', `tel:${validValue}`);
  phoneNumberValue.value = validValue;
  refInputPhone.value && refInputPhone.value?.updateValue(validValue);
};

onMounted(() => {
  setPhoneValue();
});
</script>

<template>
  <SettingLayout
    :label="{ en: 'Number' }"
    :help="{ content: 'People who reach your <br> site on mobile or tablet <br> can click this to call you ' }">
    <template #control>
      <InputText
        id="phone"
        ref="refInputPhone"
        type="number"
        placeholder="Eg: 0123456789"
        :min="0"
        :value="phoneNumberValue"
        @click-out-side="(value: string) => changePhoneNumber(value)" />
    </template>
  </SettingLayout>
</template>
