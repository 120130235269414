import type { UseQueryOptions } from 'vue-query';
import { useQuery } from 'vue-query';
import type { State as ShopState } from '../../../stores/shop';
import type { ShopLoginResponse } from '@/api/auth/queries/shopLogin';
import { fetchShopLogin } from '@/api/auth/queries/shopLogin';
import { TrackInitTimeChildOp, trackInitTimeFinishChildOp, TrackInitTimeChildOpStatus } from '@/core/performance';

export default function useShopLoginQuery(
  variable: {
    connectedType: ShopState['connectedType'];
    key?: string;
  },
  configOptions?: UseQueryOptions<any>,
) {
  return useQuery<ShopLoginResponse | undefined>(
    ['InstantShopLogin', variable],
    async () => {
      try {
        const res = await fetchShopLogin(variable.connectedType);
        return res;
      } catch (e) {
        trackInitTimeFinishChildOp(TrackInitTimeChildOp.AUTHENTICATE, {
          trackInitTimeChildOpStatus: TrackInitTimeChildOpStatus.FAIL,
        });
        if (configOptions && configOptions['onError']) {
          Object(configOptions)['onError'](e);
        }
        return;
      }
    },
    {
      ...configOptions,
      cacheTime: 0,
    },
  );
}
