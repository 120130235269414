import { computed } from 'vue';
import type { PositionControlTypes } from '../types';

export const usePositionSetting = (props: PositionControlTypes, emit: any) => {
  const val = computed(() => props.value);
  const slots = ['top-left', 'top', 'top-right', 'left', 'center', 'right', 'bottom-left', 'bottom', 'bottom-right'];
  const hideIndex = props.ignoreValue?.map((v) => slots.indexOf(v));

  const handleChange = (value?: string) => {
    emit('controlChange', value);
  };
  return { val, slots, hideIndex, handleChange };
};
