<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path d="M1.24951 5.8999C1.24951 5.62376 1.47337 5.3999 1.74951 5.3999H18.2505C18.5266 5.3999 18.7505 5.62376 18.7505 5.8999V7.8999C18.7505 8.17604 18.5266 8.3999 18.2505 8.3999H1.74951C1.47337 8.3999 1.24951 8.17604 1.24951 7.8999V5.8999Z" fill="currentColor"/>
  <path d="M1.24951 12.0999C1.24951 11.8238 1.47337 11.5999 1.74951 11.5999H18.2505C18.5266 11.5999 18.7505 11.8238 18.7505 12.0999V14.0999C18.7505 14.376 18.5266 14.5999 18.2505 14.5999H1.74951C1.47337 14.5999 1.24951 14.376 1.24951 14.0999V12.0999Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'LineDouble'
};
</script>
