<script lang="ts" setup>
import { GModal } from '@gem/uikit';
import type { GalleryProps } from '../../types';
import GalleryTabList from './GalleryTabList.vue';
import GalleryImageList from './GalleryImageList.vue';
import { useGallery } from '../../hooks/gallery/useGallery';
import GalleryDeletedImageList from './GalleryDeletedImageList.vue';

const props = defineProps<GalleryProps>();

const { loadedList, selectedTab, handleClose, handleSelectTab } = useGallery();
</script>

<template>
  <Teleport to="body">
    <g-modal
      :is-open="isOpen"
      hide-actions
      show-btn-close
      :show-line-top="false"
      body-class="pt-0 bg-white overflow-hidden h-full"
      modal-class="rounded-[16px] max-h-[calc(100vh_-_72px)]"
      :container-class="'gallery-model-container w-[600px] max-h-[calc(100vh_-_72px)] max-w-[90%]'"
      @close="handleClose">
      <template #title>
        <div class="flex items-center justify-between p-16 py-12 pr-8">
          <div class="text-16 text-text-light-500 font-semibold tracking-[-0.32px]">Choose from gallery</div>
        </div>
      </template>
      <template #default>
        <GalleryTabList :selected-tab="selectedTab" @select-tab="handleSelectTab" />
        <div class="bg-light-400 h-full flex-grow overflow-hidden">
          <GalleryImageList v-if="selectedTab === 'gallery'" v-bind="props" :loaded-list="loadedList" />
          <GalleryDeletedImageList v-else v-bind="props" :loaded-list="loadedList" />
        </div>
      </template>
    </g-modal>
  </Teleport>
</template>

<style lang="scss" scoped>
.custom-image-upload-modal-display {
  background-color: white;
  border-radius: 3px;
}
</style>
