import { ref, watch } from 'vue';
import type { ImageSelectorProps } from '../types';
import type { FileSelectFragment } from '@gem/control/src/type/graphql';

const defaultImage = 'https://via.placeholder.com/600';

export const useImageSelectState = (props: ImageSelectorProps) => {
  const imageList = ref<FileSelectFragment[]>([]);
  const deletedImageList = ref<FileSelectFragment[]>([]);

  const imageWidth = ref(props.value?.width);
  const imageHeight = ref(props.value?.height);
  const imageFileStorage = ref(props.value?.storage);
  const imageBackupFileKey = ref(props.value?.backupFileKey);
  const imageBackupFilePath = ref(props.value?.backupFilePath);
  const imageSrc = ref(props.value?.src === defaultImage ? '' : props.value?.src ?? '');

  const wrapImage = ref();
  const showAlert = ref(false);
  const isOpenGallery = ref(false);
  const isLoaded = ref<boolean>(false);
  const isLinkValid = ref<boolean>(true);
  const isLinkHttps = ref<boolean>(true);
  const isUploading = ref(props.isUploading);
  const alertType = ref<'size' | 'format'>();

  watch(
    () => props.value?.src,
    (value) => (imageSrc.value = value ?? ''),
  );

  return {
    imageSrc,
    wrapImage,
    showAlert,
    isLoaded,
    alertType,
    imageList,
    imageWidth,
    isUploading,
    imageHeight,
    isLinkHttps,
    isLinkValid,
    isOpenGallery,
    imageFileStorage,
    deletedImageList,
    imageBackupFileKey,
    imageBackupFilePath,
  };
};
