<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path d="M1.25 17.8125C1.25 17.4673 1.52982 17.1875 1.875 17.1875L18.125 17.1875C18.4702 17.1875 18.75 17.4673 18.75 17.8125C18.75 18.1577 18.4702 18.4375 18.125 18.4375L1.875 18.4375C1.52982 18.4375 1.25 18.1577 1.25 17.8125Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 16.5625C5.21447 16.5625 4.375 15.723 4.375 14.6875L4.375 8.4375C4.375 7.40196 5.21447 6.5625 6.25 6.5625L7.5 6.5625C8.53553 6.5625 9.375 7.40197 9.375 8.4375L9.375 14.6875C9.375 15.723 8.53553 16.5625 7.5 16.5625L6.25 16.5625ZM5.625 14.6875C5.625 15.0327 5.90482 15.3125 6.25 15.3125L7.5 15.3125C7.84518 15.3125 8.125 15.0327 8.125 14.6875L8.125 8.4375C8.125 8.09232 7.84518 7.8125 7.5 7.8125L6.25 7.8125C5.90482 7.8125 5.625 8.09232 5.625 8.4375L5.625 14.6875Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.625 14.6875C10.625 15.723 11.4645 16.5625 12.5 16.5625L13.75 16.5625C14.7855 16.5625 15.625 15.723 15.625 14.6875L15.625 4.6875C15.625 3.65196 14.7855 2.8125 13.75 2.8125L12.5 2.8125C11.4645 2.8125 10.625 3.65197 10.625 4.6875L10.625 14.6875ZM12.5 15.3125C12.1548 15.3125 11.875 15.0327 11.875 14.6875L11.875 4.6875C11.875 4.34232 12.1548 4.0625 12.5 4.0625L13.75 4.0625C14.0952 4.0625 14.375 4.34232 14.375 4.6875L14.375 14.6875C14.375 15.0327 14.0952 15.3125 13.75 15.3125L12.5 15.3125Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'AlignBottom'
};
</script>
