<script setup lang="ts">
import type { VisibilityProps } from './types';
import type { ActionSettingInput } from '../../sidebar-setting/types';
import { useVisibility } from './hooks/useVisibility';
import { SettingLayout } from '../..';
import { Toggle } from '@gem/element-setting-ui';

const props = defineProps<VisibilityProps>();
const emit = defineEmits<{
  (e: 'controlChange', input: Pick<ActionSettingInput, 'screenId' | 'newValue'>[]): void;
}>();

const { devicesValue, handleChange } = useVisibility(props, emit);
</script>

<template>
  <div class="flex flex-col gap-16">
    <SettingLayout :label="{ en: 'Desktop' }" label-variant="secondary">
      <template #control>
        <Toggle
          id="desktop"
          :value="devicesValue?.desktop?.default"
          @control-change="(value) => handleChange('desktop', value)" />
      </template>
    </SettingLayout>
    <SettingLayout :label="{ en: 'Tablet' }" label-variant="secondary">
      <template #control>
        <Toggle
          id="tablet"
          :value="devicesValue?.tablet?.default"
          @control-change="(value) => handleChange('tablet', value)" />
      </template>
    </SettingLayout>
    <SettingLayout :label="{ en: 'Mobile' }" label-variant="secondary">
      <template #control>
        <Toggle
          id="mobile"
          :value="devicesValue?.mobile?.default"
          @control-change="(value) => handleChange('mobile', value)" />
      </template>
    </SettingLayout>
  </div>
</template>
