<script lang="ts" setup>
import type { ItemProps } from '../types';
import { useItem } from '../hooks/useItem';
import { ItemIcon, ItemTitle, ItemInput, ItemActions, ItemEditTitleDropdown } from './item-components';

const props = defineProps<ItemProps>();

const {
  // Title handler
  title,
  inputRef,
  isActive,
  isEditingTitle,
  handleOnBlur,
  handleOnChangeTitle,
  handleEditTitleItem,
  handleCancelEditTitle,
  handleConfirmEditTitle,

  // Interaction handler
  hasHover,
  handleHoverItem,
  handleLeaveItem,
  handleClickItem,
  handleActivateItem,
  handlePreventDragItem,
  handleSlideItemToIndex,

  // Action handler
  showAction,
  isEditable,
  isDisabledDelete,
  handleDuplicateItem,
  handleCancelDeleteItem,
  handleConfirmDeleteItem,
  handleShowDeleteActions,
} = useItem(props);
</script>

<template>
  <div :class="{ 'border-dark-300 mb-8 overflow-hidden rounded-xl border': isActive }" @click="handleSlideItemToIndex">
    <div
      :class="{
        'px-8': !isEditingTitle,
        'mb-8 rounded-xl': !isActive,
        'pl-[2px] pr-8': isEditingTitle,
        'rounded-t-xl border-b-0': isActive,
      }"
      class="bg-dark-400 group-[.sortable-fallback]/sortable-item:bg-dark-400 group-[.sortable-ghost]/sortable-item:bg-dark-250 group-[&:not(.dragging)]/sortable:hover:bg-dark-300 relative flex h-36 cursor-pointer select-none items-center justify-between overflow-hidden group-[.sortable-ghost]/sortable-item:cursor-grabbing group-[.dragging]/sortable-item:cursor-grabbing group-[.sortable-chosen]/sortable-item:hover:cursor-grabbing"
      @click="handleClickItem"
      @mouseover="handleHoverItem"
      @mouseleave="handleLeaveItem">
      <div class="relative z-10 flex w-full flex-1 items-center overflow-hidden">
        <ItemIcon
          :tag="tag"
          :is-active="isActive"
          :is-expand="isExpand"
          :is-editing-title="isEditingTitle"
          @activate-item="handleActivateItem" />
        <ItemTitle :title="title" :show-action="showAction" :has-hover="hasHover" :is-editing-title="isEditingTitle" />
        <ItemInput
          ref="inputRef"
          :title="title"
          :is-editing-title="isEditingTitle"
          @blur="handleOnBlur"
          @confirm="handleConfirmEditTitle"
          @on-change="handleOnChangeTitle" />
      </div>
      <ItemActions
        :title="title"
        :has-hover="hasHover"
        :show-action="showAction"
        :index="index?.toString()"
        :is-editable="isEditable"
        :is-editing-title="isEditingTitle"
        :is-disabled-delete="isDisabledDelete"
        @edit="handleEditTitleItem"
        @duplicate="handleDuplicateItem"
        @cancel-edit="handleCancelEditTitle"
        @confirm-edit="handleConfirmEditTitle"
        @cancel-delete="handleCancelDeleteItem"
        @confirm-delete="handleConfirmDeleteItem"
        @show-delete-action="handleShowDeleteActions" />
    </div>
    <ItemEditTitleDropdown
      v-if="isActive"
      :title="title"
      @on-blur="handleOnBlur"
      @prevent-drag="handlePreventDragItem"
      @on-change-title="handleOnChangeTitle" />
  </div>
</template>
