<template>
  <svg width="100%" height="100%" viewBox="0 0 94 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H88C91.0376 0.5 93.5 2.96243 93.5 6V38C93.5 41.0376 91.0376 43.5 88 43.5H6C2.96243 43.5 0.5 41.0376 0.5 38V6Z" stroke="#5E5E5E"/>
<rect x="8" y="8" width="78" height="4" rx="1.8125" fill="#AAAAAA"/>
<rect x="8" y="16" width="50" height="4" rx="1.8125" fill="#AAAAAA"/>
<path d="M87.2236 28L77.5 38" stroke="#F9F9F9" stroke-width="1.8" stroke-linecap="round"/>
<path d="M87.5003 33.5103L83.1787 37.9547" stroke="#F9F9F9" stroke-width="1.8" stroke-linecap="round"/>
</svg>
</template>

<script>
export default {
  name: 'InputTextArea'
};
</script>
