<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path d="M19 2.8125C19 3.15768 18.7202 3.4375 18.375 3.4375L2.125 3.4375C1.77982 3.4375 1.5 3.15768 1.5 2.8125C1.5 2.46732 1.77982 2.1875 2.125 2.1875L18.375 2.1875C18.7202 2.1875 19 2.46732 19 2.8125Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 4.0625C15.0355 4.0625 15.875 4.90197 15.875 5.9375L15.875 12.1875C15.875 13.223 15.0355 14.0625 14 14.0625L12.75 14.0625C11.7145 14.0625 10.875 13.223 10.875 12.1875L10.875 5.9375C10.875 4.90197 11.7145 4.0625 12.75 4.0625L14 4.0625ZM14.625 5.9375C14.625 5.59232 14.3452 5.3125 14 5.3125L12.75 5.3125C12.4048 5.3125 12.125 5.59232 12.125 5.9375L12.125 12.1875C12.125 12.5327 12.4048 12.8125 12.75 12.8125L14 12.8125C14.3452 12.8125 14.625 12.5327 14.625 12.1875L14.625 5.9375Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.625 5.9375C9.625 4.90197 8.78553 4.0625 7.75 4.0625L6.5 4.0625C5.46447 4.0625 4.625 4.90197 4.625 5.9375L4.625 15.9375C4.625 16.973 5.46447 17.8125 6.5 17.8125L7.75 17.8125C8.78553 17.8125 9.625 16.973 9.625 15.9375L9.625 5.9375ZM7.75 5.3125C8.09518 5.3125 8.375 5.59232 8.375 5.9375L8.375 15.9375C8.375 16.2827 8.09518 16.5625 7.75 16.5625L6.5 16.5625C6.15482 16.5625 5.875 16.2827 5.875 15.9375L5.875 5.9375C5.875 5.59232 6.15482 5.3125 6.5 5.3125L7.75 5.3125Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'AlignTop'
};
</script>
