<script setup lang="ts">
import type { GalleryImageListProps } from '../../types';
import { useGalleryImageList } from '../../hooks/gallery/useGalleryImageList';
import GalleryImageItem from './GalleryImageItem.vue';

const props = defineProps<GalleryImageListProps>();

const { imageList, listContainer, handleChangeFile, handleClickImage, handleDeleteImage, handleSetImageLoaded } =
  useGalleryImageList(props);
</script>

<template>
  <div class="ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none">
    <div class="low:h-[70vh] h-[614px]">
      <perfect-scrollbar ref="listContainer" class="p-16">
        <div class="hide-scrollbar grid grid-cols-3 gap-16" :class="{ 'h-[178px]': !imageList?.length }">
          <GalleryImageItem is-uploader @upload="handleChangeFile" />
          <GalleryImageItem
            v-for="image in imageList"
            :key="image.id"
            :item="image"
            @click="handleClickImage"
            @delete="handleDeleteImage"
            @set-loaded="handleSetImageLoaded" />
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
