<script lang="ts" setup>
import type { ControlOptionIllustrationTypes, OptionItem } from '@gem/element-setting-ui';
import { GSvg, GIcon } from '@gem/icons';
import { GTooltip } from '@gem/uikit';

const props = defineProps<ControlOptionIllustrationTypes>();
const emit = defineEmits<{
  (e: 'controlChange', value?: OptionItem['value']): void;
}>();

const change = (v?: OptionItem['value']) => {
  if (props.value === v || props.disableToggle) {
    return;
  }

  emit('controlChange', v);
};

const isActiveOptionItem = (item: OptionItem) => {
  return props.value === item.value && !props.disabled;
};
</script>

<template>
  <div
    data-test="editor-control-option-illustration"
    class="bg-dark-400 flex h-48 w-full items-center gap-4 rounded-xl p-[2px]">
    <template v-for="item in options?.filter((item) => !item.hide)" :key="item.value">
      <div
        class="text-12 group/icon hover:bg-dark-150 flex h-full flex-1 cursor-pointer select-none items-center justify-center rounded-[6px] px-8 py-4 text-center font-medium transition-all"
        :class="isActiveOptionItem(item) ? '!bg-dark-250 cursor-default' : ``"
        @click.stop="!item.disabled && change(item.value)">
        <GTooltip
          placement="top"
          :disabled="!enableTooltip && !item.label"
          class="!flex h-full w-full items-center justify-center group-hover:opacity-60"
          :class="{
            'opacity-20': item.disabled,
            'opacity-50': isActiveOptionItem(item),
          }">
          <GSvg v-if="item.svgName" :name="item.svgName" />
          <GIcon v-else-if="item.iconName" :name="item.iconName" />
          <span v-else v-html="item.label"></span>
          <template #content>
            <div class="text-12 text-light-100">{{ item.label }}</div>
          </template>
        </GTooltip>
      </div>
    </template>
  </div>
</template>
