<script setup lang="ts">
import { GInput } from '@gem/uikit-v2';
import type { ControlInputTextTypes } from './type';
import { useInputText } from './hooks/useInputText';

const props = defineProps<ControlInputTextTypes>();
const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
  (e: 'enter', value?: any): void;
}>();
const { inputRef, updateValue, handleControlChange, handleControlOnChange } = useInputText(props, emit);

defineExpose({ updateValue });
</script>

<template>
  <GInput
    ref="inputRef"
    v-bind="props"
    @enter="() => emit('enter', inputRef.value)"
    @change="handleControlChange"
    @on-change="handleControlOnChange" />
</template>
