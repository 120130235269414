<script lang="ts" setup>
import type { Ref } from 'vue';
import type { OptionSelect } from '@gem/uikit';
import type { Placement } from '@floating-ui/vue';
import { ref, watch } from 'vue';

import { GPopover, GButton } from '@gem/uikit-v2';
import SelectButton from './typo-style-select/SelectButton.vue';
import SelectOptions from './typo-style-select/SelectOptions.vue';
import { ID } from '@gem/common';

type Props = {
  id?: string;
  name?: string;
  disabled?: boolean;
  floating?: boolean;
  placement?: Placement;
  displayValue?: string;
  options: OptionSelect[];
  activeId?: string | number;
};

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  disabled: false,
  floating: true,
  placement: 'bottom-end',
});

const emit = defineEmits<{
  (e: 'select', value: any, id: string, selectName?: string): void;
  (e: 'on-click-sub-action', type: string): void;
}>();

const filteredOptions = ref(props.options);
const isHoverSelect: Ref<boolean> = ref(false);
const dropdownID = `btn-select-${ID()}`;

const onSelect = (item: OptionSelect) => {
  if (!isActiveOption(item)) {
    emit('select', item.value, item.id, props.name);
  }
};

const isActiveOption = (item: OptionSelect) => {
  return String(props.activeId) === String(item.id);
};

const handleEditGlobalStyle = () => {
  emit('on-click-sub-action', 'editGlobalStyle');
};

watch(
  () => props.options,
  (newValue) => {
    filteredOptions.value = newValue;
  },
);
</script>

<template>
  <div class="bg-dark-400 relative w-full rounded-xl">
    <GPopover
      :overlay="false"
      cls="bg-dark-400 p-0"
      :has-arrow="false"
      :closeable="true"
      :placement-offset="4"
      placement="bottom-end"
      button-class="!w-full "
      wrap-content-class="!w-full"
      :ignore-outside-class="[dropdownID]">
      <template #default="{ open: isOpenDropdown }">
        <SelectButton :id="dropdownID" :disabled="disabled" :is-open="isOpenDropdown">
          <template #button-content="{ isOpen }">
            <slot name="button-content" :is-open="isOpen" />
          </template>
        </SelectButton>
      </template>
      <template v-if="filteredOptions?.length" #content="{ close: closeDropdown }">
        <SelectOptions
          :active-id="activeId"
          :is-hover-select="isHoverSelect"
          :filtered-options="filteredOptions"
          @select="
            (item) => {
              onSelect(item);
              closeDropdown();
            }
          " />
        <div class="mx-8 flex flex-col gap-8 p-8">
          <span class="border-dark-200 border-b" />
          <GButton type="link" size="semi-medium" @click="handleEditGlobalStyle"> Edit global style</GButton>
        </div>
      </template>
    </GPopover>
  </div>
</template>
