<template>
  <svg width="100%" height="100%" viewBox="0 0 50 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="50" height="16" rx="2" fill="#5E5E5E"/>
  <circle cx="18" cy="22" r="2" fill="#F9F9F9"/>
  <circle opacity="0.5" cx="25" cy="22" r="2" fill="#F9F9F9"/>
  <circle opacity="0.5" cx="32" cy="22" r="2" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'CarouselDotOutside'
};
</script>
