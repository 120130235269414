<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path d="M1.87891 1.22485C1.53373 1.22485 1.25391 1.50468 1.25391 1.84985C1.25391 2.19503 1.53373 2.47485 1.87891 2.47485L18.1298 2.47485C18.475 2.47485 18.7548 2.19503 18.7548 1.84985C18.7548 1.50468 18.475 1.22485 18.1298 1.22485H1.87891Z" fill="currentColor"/>
  <path d="M1.93433 17.5249C1.58915 17.5249 1.30933 17.8047 1.30933 18.1499C1.30933 18.4951 1.58915 18.7749 1.93433 18.7749L18.1852 18.7749C18.5304 18.7749 18.8102 18.4951 18.8102 18.1499C18.8102 17.8047 18.5304 17.5249 18.1852 17.5249L1.93433 17.5249Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.875 6.375C2.875 5.33947 3.71447 4.5 4.75 4.5H15.25C16.2855 4.5 17.125 5.33947 17.125 6.375V6.875C17.125 7.91053 16.2855 8.75 15.25 8.75H4.75C3.71447 8.75 2.875 7.91053 2.875 6.875V6.375ZM4.75 5.75C4.40482 5.75 4.125 6.02982 4.125 6.375V6.875C4.125 7.22018 4.40482 7.5 4.75 7.5H15.25C15.5952 7.5 15.875 7.22018 15.875 6.875V6.375C15.875 6.02982 15.5952 5.75 15.25 5.75H4.75Z" fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.75 11.25C3.71447 11.25 2.875 12.0895 2.875 13.125V13.625C2.875 14.6605 3.71447 15.5 4.75 15.5H15.25C16.2855 15.5 17.125 14.6605 17.125 13.625V13.125C17.125 12.0895 16.2855 11.25 15.25 11.25H4.75ZM4.125 13.125C4.125 12.7798 4.40482 12.5 4.75 12.5H15.25C15.5952 12.5 15.875 12.7798 15.875 13.125V13.625C15.875 13.9702 15.5952 14.25 15.25 14.25H4.75C4.40482 14.25 4.125 13.9702 4.125 13.625V13.125Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'JustifyContentSpaceBetween'
};
</script>
