import type { Ref } from 'vue';
import { ref } from 'vue';
import { nextTick } from 'vue';

export const useHandleSearch = (
  isSearchSetting: Ref<Boolean>,
  inputSearch: Ref<HTMLElement | undefined>,
  searchKeyword: Ref<string>,
  emit: { (e: 'onSearch', value?: string): void },
) => {
  const openSearchSetting = () => {
    isSearchSetting.value = true;
    nextTick(() => {
      if (inputSearch.value) {
        inputSearch.value.focus();
      }
    });
  };

  const timeDebounceSearch = ref<ReturnType<typeof setTimeout> | null>(null);
  const onChangeSearch = (value: string) => {
    searchKeyword.value = value;
    if (timeDebounceSearch.value) {
      clearTimeout(timeDebounceSearch.value);
    }

    timeDebounceSearch.value = setTimeout(() => {
      emit('onSearch', searchKeyword.value);
    }, 200);
  };

  const closeSearchSetting = () => {
    isSearchSetting.value = false;
    searchKeyword.value = '';
    emit('onSearch', '');
  };
  return {
    openSearchSetting,
    onChangeSearch,
    closeSearchSetting,
  };
};
