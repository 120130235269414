const ADD_ITEM_BUTTON_HEIGHT = 70;

export const scrollItemIntoView = (index: string) => {
  const container = document.querySelector<HTMLElement>('.items-list-wrapper');
  if (!container) return;

  const $item = container.querySelector<HTMLElement>(`[id="${index}"]`);
  if (!$item) return;

  $item.scrollIntoView({ block: 'nearest' });

  const currentScroll = container.scrollTop;
  const itemBottom = $item.getBoundingClientRect().bottom;
  const containerBottom = container.getBoundingClientRect().bottom - ADD_ITEM_BUTTON_HEIGHT;

  if (itemBottom > containerBottom) {
    container.scrollTop = currentScroll + (itemBottom - containerBottom);
  }
};
