import { useInfiniteScroll } from '@vueuse/core';
import { ref } from 'vue';
import type { CollectionPickerEmit } from '../type';
export const useHandleDataCollectionPicker = (emit: CollectionPickerEmit) => {
  // Handle show more
  const productInfinityScroll = ref<HTMLElement | null>(null);
  useInfiniteScroll(
    productInfinityScroll,
    () => {
      emit('showMore');
    },
    { distance: 100 },
  );

  // Set collection
  const setValueSelected = (id?: string) => {
    if (id) {
      emit('setValueSelectedCollection', id);
    }
  };

  return {
    setValueSelected,
    productInfinityScroll,
  };
};
