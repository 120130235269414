<script setup lang="ts">
import type { FontPickerProps, FontPickerEmits } from './types';

import { GInput } from '@gem/uikit-v2';
import FontList from './components/FontList.vue';
import { useFontPicker } from './hooks/useFontPicker';
import { flip, offset, shift } from '@floating-ui/core';
import DeleteFontModal from './components/DeleteFontModal.vue';
import UploadFontButton from './components/UploadFontButton.vue';
import EmptySearchState from './components/EmptySearchState.vue';
import { InputComboLayout, SettingLayout } from '@gem/element-setting-ui';

const emit = defineEmits<FontPickerEmits>();

const props = withDefaults(defineProps<FontPickerProps>(), {
  middleware: () => [flip(), shift(), offset(4)],
});

const {
  mixedFonts,
  fontListRef,
  deleteModal,
  searchKeyword,
  noSearchFound,
  inputComboLayoutStyle,
  inputComboLayoutContent,
  handleSearch,
  handleSelectFont,
  handleUploadFont,
  handleClearSearch,
  handleLoadSourceFont,
  handleChangeSourceFont,
} = useFontPicker(props, emit);
</script>

<template>
  <div>
    <InputComboLayout
      :id="id"
      placeholder="Add..."
      :is-parent="true"
      :show-label="false"
      :is-full-width="true"
      :is-hide-clear="true"
      :disable-scrollbar="true"
      :label="{ en: 'Font picker' }"
      :input-style="inputComboLayoutStyle"
      :content-display="inputComboLayoutContent"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-icon="'polaris-text-font'"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''">
      <SettingLayout layout="vertical">
        <template #control>
          <div class="bg-dark-300 mb-16 flex h-36 rounded-xl">
            <GInput
              clear-button
              :value="searchKeyword"
              placeholder="Search font"
              :prefix-icon="'polaris-search'"
              @on-change="handleSearch"
              @clear="handleClearSearch" />
          </div>
          <div class="max-h-[296px] overflow-hidden">
            <EmptySearchState v-if="noSearchFound" />
            <template v-else>
              <FontList
                ref="fontListRef"
                v-bind="props"
                :fonts="mixedFonts"
                @select="handleSelectFont"
                @change-source="handleChangeSourceFont"
                @load-source-font="handleLoadSourceFont"
                @open-modal-delete="deleteModal.handleOpen" />
            </template>
          </div>
          <UploadFontButton @upload="handleUploadFont" />
          <DeleteFontModal
            :is-show="deleteModal.isShow.value"
            :global-style-font="globalStyleFont"
            @close="deleteModal.handleClose"
            @delete="deleteModal.handleDeleteFont" />
        </template>
      </SettingLayout>
    </InputComboLayout>
  </div>
</template>
