import { ref } from 'vue';
import type { GalleryTab } from '../../types';
import { useImageSelectContext } from '../useImageSelectContext';

export const useGallery = () => {
  const { actions } = useImageSelectContext();

  const loadedList = ref<string[]>([]);
  const selectedTab = ref<GalleryTab>('gallery');

  const handleSelectTab = (tab: string) => {
    if (selectedTab.value === tab) return;
    tab === 'gallery' ? handleSelectGallery() : handleSelectRecentlyDeleted();
  };

  const handleSelectGallery = () => {
    loadedList.value.length = 0;
    selectedTab.value = 'gallery';
  };

  const handleSelectRecentlyDeleted = () => {
    loadedList.value.length = 0;
    selectedTab.value = 'recentlyDeleted';
    actions.handleLoadDeletedImage();
  };

  const handleClose = () => {
    actions.closeGallery();
  };

  return {
    loadedList,
    selectedTab,
    handleClose,
    handleSelectTab,
  };
};
