import useShopStore from '@/stores/shop';
import useNotification from './useNotification';
import { useShopQuery } from './useShopQuery';
import { backToDashboard } from '@/utils/backToDashboard';
import useShopLoginQuery from '@/api/auth/queries/useShopLoginQuery';
import type { ShopLoginResponse } from '@/api/auth/queries/shopLogin';
import { useAdmin } from './useAdmin';
import {
  TrackInitTimeChildOp,
  trackInitTimeFinishChildOp,
  TrackInitTimeChildOpStatus,
  trackInitTimeStartChildOp,
} from '@/core/performance';

export const useGemPagesAuthentication = () => {
  const shopStore = useShopStore();
  const { handleError } = useNotification();
  const { adminToken, adminShopID, adminShopifyDomain } = useAdmin();

  const shopID = computed(() => shopStore.getShopId);

  trackInitTimeStartChildOp(TrackInitTimeChildOp.AUTHENTICATE);
  useShopLoginQuery(
    {
      connectedType: 'gempagesv7',
    },
    {
      enabled: !adminToken,
      onError: (error) => {
        handleError(error);
        trackInitTimeFinishChildOp(TrackInitTimeChildOp.AUTHENTICATE, {
          trackInitTimeChildOpStatus: TrackInitTimeChildOpStatus.FAIL,
        });
      },
      onSuccess: (v) => {
        handleSuccess(v);
      },
    },
  );

  trackInitTimeStartChildOp(TrackInitTimeChildOp.GET_SHOP_SHOPIFY);
  useShopQuery({
    shopID,
  });

  const handleSuccess = (v: ShopLoginResponse) => {
    updateShopInfoInStore(v);

    if (v.shopID === '0' || !v.shopID) {
      backToDashboard({
        delayRedirect: 8000,
      });
    }
  };

  const updateShopInfoInStore = (v: ShopLoginResponse) => {
    shopStore.setShopId(v?.shopID);
    shopStore.setToken(v?.token);
    shopStore.setShopDomain(v?.shopifyDomain);
    shopStore.setUserHash(v?.userHash);
    shopStore.setFirstName(v?.firstName);
    const isConnected = v?.shopID && v?.shopID !== '0' && v?.status == 'CONNECTED';
    shopStore.setStatus(!!isConnected);
    shopStore.setShopType(v.isGemPagesV7);
    shopStore.setShopPlan(v.plan);
    shopStore.setShopifyPlan(v.shopifyPlan);
    trackInitTimeFinishChildOp(TrackInitTimeChildOp.AUTHENTICATE, {
      trackInitTimeChildOpStatus: TrackInitTimeChildOpStatus.SUCCESS,
    });
  };

  onMounted(() => {
    // admin auth
    if (adminShopID && adminShopifyDomain && adminToken) {
      shopStore.setShopId(adminShopID);
      shopStore.setToken(adminToken);
      shopStore.setShopDomain(adminShopifyDomain);
      shopStore.setStatus(true);
      shopStore.setShopType('true');
      shopStore.setShopPlan('optimize');
      shopStore.setShopifyPlan('Developer Preview');
    }
  });
};
