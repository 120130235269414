<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.24976 3.125C1.24976 2.08947 2.08922 1.25 3.12476 1.25H4.99976C5.34493 1.25 5.62476 1.52982 5.62476 1.875C5.62476 2.22018 5.34493 2.5 4.99976 2.5H3.12476C2.77958 2.5 2.49976 2.77982 2.49976 3.125V5C2.49976 5.34518 2.21993 5.625 1.87476 5.625C1.52958 5.625 1.24976 5.34518 1.24976 5V3.125Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1875 9.99821C12.1868 8.79037 11.2072 7.81174 9.99938 7.81229C8.79156 7.8127 7.81247 8.79195 7.81247 9.99979C7.81247 11.2079 8.79184 12.1873 9.99997 12.1873C11.2079 12.1873 12.1875 11.2085 12.1875 10.0002V9.99821ZM9.99988 9.06229C10.5173 9.06201 10.937 9.48116 10.9375 9.99854V10.0002C10.9375 10.5178 10.518 10.9373 9.99997 10.9373C9.4822 10.9373 9.06247 10.5176 9.06247 9.99979C9.06247 9.48212 9.48221 9.06243 9.99988 9.06229Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0237 9.65898C18.0239 9.65919 18.024 9.65938 17.5 10C18.024 10.3406 18.0239 10.3408 18.0237 10.341L18.0225 10.3429L18.0199 10.3468L18.0115 10.3596L17.9814 10.4042C17.9556 10.442 17.9182 10.4959 17.8696 10.5634C17.7724 10.6984 17.6303 10.8885 17.446 11.1153C17.0781 11.568 16.5386 12.1719 15.8501 12.7771C14.4839 13.9782 12.4665 15.2404 9.99997 15.2404C7.53338 15.2404 5.51601 13.9782 4.14981 12.7771C3.46136 12.1719 2.92182 11.568 2.55396 11.1153C2.36967 10.8885 2.22751 10.6984 2.13037 10.5634C2.08177 10.4959 2.04437 10.442 2.01853 10.4042L1.98845 10.3596L1.97998 10.3468L1.97741 10.3429L1.97653 10.3415C1.9764 10.3413 1.97594 10.3406 2.49997 10C1.97594 9.65938 1.97606 9.65919 1.9762 9.65898L1.97741 9.65713L1.97998 9.6532L1.98845 9.64041L2.01853 9.59584C2.04437 9.55798 2.08177 9.50414 2.13037 9.43661C2.22751 9.30163 2.36967 9.11154 2.55396 8.88473C2.92182 8.43197 3.46136 7.82814 4.14981 7.22291C5.51601 6.02185 7.53338 4.75962 9.99997 4.75962C12.4665 4.75962 14.4839 6.02185 15.8501 7.22291C16.5386 7.82814 17.0781 8.43197 17.446 8.88473C17.6303 9.11154 17.7724 9.30163 17.8696 9.43661C17.9182 9.50414 17.9556 9.55798 17.9814 9.59584L18.0115 9.64041L18.0199 9.6532L18.0225 9.65713L18.0237 9.65898ZM3.5241 10.327C3.42427 10.2042 3.33865 10.0939 3.26788 10C3.33865 9.9061 3.42427 9.79584 3.5241 9.67297C3.85936 9.26034 4.35107 8.71033 4.97513 8.16171C6.23392 7.05507 7.96655 6.00962 9.99997 6.00962C12.0334 6.00962 13.766 7.05507 15.0248 8.16171C15.6489 8.71033 16.1406 9.26034 16.4758 9.67297C16.5757 9.79583 16.6613 9.9061 16.7321 10C16.6613 10.0939 16.5757 10.2042 16.4758 10.327C16.1406 10.7397 15.6489 11.2897 15.0248 11.8383C13.766 12.9449 12.0334 13.9904 9.99997 13.9904C7.96655 13.9904 6.23392 12.9449 4.97513 11.8383C4.35107 11.2897 3.85936 10.7397 3.5241 10.327Z"
      fill="currentColor" />
    <path d="M17.5 10L18.0237 9.65898C18.1584 9.8661 18.1584 10.1339 18.0237 10.341L17.5 10Z" fill="currentColor" />
    <path
      d="M1.9762 9.65898L2.49997 10L1.97653 10.3415C1.8419 10.1344 1.84157 9.8661 1.9762 9.65898Z"
      fill="currentColor" />
    <path
      d="M14.9998 1.25C14.6546 1.25 14.3748 1.52982 14.3748 1.875C14.3748 2.22018 14.6546 2.5 14.9998 2.5H16.8748C17.2199 2.5 17.4998 2.77982 17.4998 3.125V5C17.4998 5.34518 17.7796 5.625 18.1248 5.625C18.4699 5.625 18.7498 5.34518 18.7498 5V3.125C18.7498 2.08947 17.9103 1.25 16.8748 1.25H14.9998Z"
      fill="currentColor" />
    <path
      d="M1.87486 14.3747C2.22004 14.3747 2.49986 14.6545 2.49986 14.9997V16.8747C2.49986 17.2199 2.77968 17.4997 3.12486 17.4997H4.99986C5.34504 17.4997 5.62486 17.7795 5.62486 18.1247C5.62486 18.4699 5.34504 18.7497 4.99986 18.7497H3.12486C2.08933 18.7497 1.24986 17.9102 1.24986 16.8747V14.9997C1.24986 14.6545 1.52968 14.3747 1.87486 14.3747Z"
      fill="currentColor" />
    <path
      d="M18.75 14.9998C18.75 14.6546 18.4701 14.3748 18.125 14.3748C17.7798 14.3748 17.5 14.6546 17.5 14.9998V16.8748C17.5 17.2199 17.2201 17.4998 16.875 17.4998H15C14.6548 17.4998 14.375 17.7796 14.375 18.1248C14.375 18.4699 14.6548 18.7498 15 18.7498H16.875C17.9105 18.7498 18.75 17.9103 18.75 16.8748V14.9998Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'PreviewMajor',
};
</script>
