import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
import { FileSelectFragmentDoc } from '../fragments/file.generated';

export type FileUploadByURLResponse = Pick<Types.Mutation, 'fileUploadByURL'>;

export const FileUploadByURLDocument = `
  mutation FileUploadByURL($url: String!, $plan: ShopifyPlan!, $background: Boolean) {
    fileUploadByURL(url: $url, plan: $plan, background: $background) {
      ...FileSelect
    }
  }
  ${FileSelectFragmentDoc}`;
export const useFileUploadByURL = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<FileUploadByURLResponse, TError, Types.MutationFileUploadByUrlArgs, TContext>,
) =>
  useMutation<FileUploadByURLResponse, TError, Types.MutationFileUploadByUrlArgs, TContext>(
    ['fileUploadByURL'],
    (
      variables:
        | Types.MutationFileUploadByUrlArgs
        | ComputedRef<Types.MutationFileUploadByUrlArgs>
        | Ref<Types.MutationFileUploadByUrlArgs>,
    ) => appFetcher<FileUploadByURLResponse, Types.MutationFileUploadByUrlArgs>(FileUploadByURLDocument, variables)(),
    options,
  );
useFileUploadByURL.getKey = () => ['fileUploadByURL'];
