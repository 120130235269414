import { useEventStore } from './store';
import type { EventStateKey } from './type';

export const eventGetStatus = (status: EventStateKey) => {
  const eventStore = useEventStore();

  return eventStore.getEventStatus(status);
};

export const eventSetStatus = (status: EventStateKey, value: boolean) => {
  const eventStore = useEventStore();
  eventStore.setEventStatus(status, value);
};
