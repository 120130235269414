export function parseMailto(url: string) {
  // Remove the "mailto:" prefix
  const mailtoPrefix = 'mailto:';
  if (url.startsWith(mailtoPrefix)) {
    url = url.slice(mailtoPrefix.length);
  }

  // Separate the email and query string
  const [email, queryString] = url.split('?');

  // Parse the query parameters
  const params = new URLSearchParams(queryString);
  const subject = params.get('subject');
  const content = params.get('body');

  return { email, subject, content };
}

export function createMailtoLink({ email, subject, content }: { email: string; subject: string; content: string }) {
  const params = new URLSearchParams();

  // Add subject and body if they exist
  if (subject) params.append('subject', subject);
  if (content) params.append('body', content);

  // Build the mailto link
  const queryString = params.toString();
  return email ? (queryString ? `mailto:${email}?${queryString}` : `mailto:${email}`) : '';
}
