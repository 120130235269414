<template>
  <svg width="100%" height="100%" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.5308 20V12H21.7378V18.9609H25.3628V20H20.5308ZM27.9468 12V20H26.7397V12H27.9468ZM36.1616 12V20H35.0522L30.9858 14.1328H30.9116V20H29.7046V12H30.8218L34.8921 17.875H34.9663V12H36.1616ZM37.9224 20V12H39.1294V15.8203H39.2271L42.5825 12H44.1021L40.8755 15.6016L44.1138 20H42.6606L40.0747 16.4258L39.1294 17.5117V20H37.9224Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'LinkButton',
};
</script>
