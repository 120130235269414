<script setup lang="ts">
import { GButton, GInput } from '@gem/uikit-v2';
import type { ControlInputStepperTypes } from './type';
import { useInputStepper } from './hooks/useInputStepper';

const props = withDefaults(defineProps<ControlInputStepperTypes>(), {
  min: 1,
});
const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
}>();

const {
  val,
  inputRef,
  isFocus,
  isHoverButton,
  handleOnChange,
  handleChange,
  handleUpDownValue,
  onMouseOverButton,
  onMouseLeaveButton,
} = useInputStepper(props, emit);
</script>

<template>
  <div
    data-test="editor-control-input-stepper"
    class="bg-dark-400 group rounded-xl border border-transparent"
    :class="{ 'border-primary-300': isFocus }">
    <div
      class="flex h-36 items-center justify-between rounded-xl"
      :class="{ 'group-hover:bg-dark-250': !isFocus && !isHoverButton }">
      <div @mouseover="onMouseOverButton" @mouseleave="onMouseLeaveButton">
        <GButton
          type="ghost"
          data-test="editor-control-button-number-down"
          only-icon="polaris-minus"
          size="medium"
          no-rounded="right"
          :disable="readonly || Number(val) <= min"
          @click="(e: Event) => handleUpDownValue(e, 'down')" />
      </div>
      <div>
        <GInput
          ref="inputRef"
          :value="val"
          :no-background="true"
          data-test="editor-control-input-stepper-field"
          align="center"
          no-border="all"
          @focus="() => (isFocus = true)"
          @focusout="() => (isFocus = false)"
          @keydown.up="(e: Event) => handleUpDownValue(e, 'up')"
          @keydown.down="(e: Event) => handleUpDownValue(e, 'down')"
          @click-out-side="handleChange"
          @on-change="handleOnChange" />
      </div>
      <div @mouseover="onMouseOverButton" @mouseleave="onMouseLeaveButton">
        <GButton
          type="ghost"
          data-test="editor-control-button-number-down"
          only-icon="polaris-plus"
          size="medium"
          no-rounded="left"
          :disable="readonly || Number(val) >= (max || Infinity)"
          @click="(e: Event) => handleUpDownValue(e, 'up')" />
      </div>
    </div>
  </div>
</template>
