import type { EventStateKey, EventStateType } from './type';

import { defineStore } from 'pinia';

export const useEventStore = defineStore('event', {
  state: (): EventStateType => ({
    status: {
      isInitEditorSuccess: false,
    },
  }),
  actions: {
    getEventStatus(status: EventStateKey) {
      return this.status[status];
    },
    setEventStatus(status: EventStateKey, value: boolean) {
      this.status[status] = value;
    },
  },
});
