<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.6875 7.60078C18.6118 7.36749 18.4692 7.16162 18.2773 7.00881C18.0855 6.856 17.8529 6.76301 17.6086 6.74141L12.9688 6.34141L11.15 2.01641C11.0553 1.78947 10.8956 1.59562 10.691 1.45926C10.4863 1.32291 10.2459 1.25016 10 1.25016C9.75411 1.25016 9.51371 1.32291 9.30907 1.45926C9.10443 1.59562 8.94471 1.78947 8.85002 2.01641L7.03674 6.34141L2.39142 6.74375C2.14614 6.76436 1.9124 6.85693 1.71952 7.00986C1.52663 7.16278 1.38319 7.36925 1.30718 7.60337C1.23117 7.83749 1.22598 8.08884 1.29225 8.3259C1.35852 8.56296 1.49331 8.77518 1.6797 8.93594L5.20392 12.0156L4.14767 16.5891C4.09182 16.8284 4.10776 17.0789 4.19349 17.3092C4.27923 17.5395 4.43095 17.7395 4.6297 17.884C4.82845 18.0286 5.0654 18.1113 5.31093 18.122C5.55647 18.1326 5.79968 18.0706 6.01017 17.9438L9.99455 15.5219L13.9875 17.9438C14.198 18.0706 14.4412 18.1326 14.6868 18.122C14.9323 18.1113 15.1692 18.0286 15.368 17.884C15.5667 17.7395 15.7185 17.5395 15.8042 17.3092C15.8899 17.0789 15.9059 16.8284 15.85 16.5891L14.7945 12.0109L18.318 8.93594C18.5044 8.77462 18.6389 8.5618 18.7046 8.32423C18.7704 8.08667 18.7644 7.83497 18.6875 7.60078ZM17.4985 7.99141L13.975 11.0664C13.8035 11.2156 13.676 11.4087 13.606 11.6249C13.5361 11.8412 13.5265 12.0724 13.5781 12.2938L14.6367 16.875L10.6469 14.4531C10.4522 14.3346 10.2287 14.2719 10.0008 14.2719C9.77288 14.2719 9.54936 14.3346 9.3547 14.4531L5.37033 16.875L6.42189 12.2969C6.47357 12.0755 6.46393 11.8443 6.394 11.6281C6.32407 11.4118 6.1965 11.2187 6.02502 11.0695L2.50002 7.99609C2.49973 7.99376 2.49973 7.9914 2.50002 7.98906L7.14377 7.5875C7.37049 7.56752 7.58745 7.48601 7.77125 7.35178C7.95505 7.21754 8.09871 7.03567 8.18674 6.82578L10 2.50625L11.8125 6.82578C11.9005 7.03567 12.0442 7.21754 12.228 7.35178C12.4118 7.48601 12.6288 7.56752 12.8555 7.5875L17.5 7.98906C17.5 7.98906 17.5 7.99375 17.5 7.99453L17.4985 7.99141Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'StarRegular',
};
</script>
