import type { GlobalSwatchesData, OfferDynamicDiscount } from '@gem/common';
import { cloneDeepObject } from '@/utils/common';
import type { Component, GroupTypeSetting } from '../../common/utils/types';
import useEmitToFrame from '@/modules/editor/modules/preview/hooks/useEmitToFrame';
import type { ProductOffer } from '../../sale-funnels/utils/types';
import { sentryCaptureException } from '../../common/use-cases/sentry';
import { COLLECTION_PAGE, PRODUCT_PAGE } from '../../header/components/switch-page/const';
import type { Interaction } from '@/modules/interactions/types';

const awaitSelectorKeys: Record<string, { count: number }> = {};
export function waitForElement(doc: Document, selector: string, cb?: () => void) {
  // Check count loop
  awaitSelectorKeys[selector] = awaitSelectorKeys[selector] ?? {
    count: 0,
  };
  awaitSelectorKeys[selector].count = awaitSelectorKeys[selector].count + 1;
  if (awaitSelectorKeys[selector].count == 120) {
    // 1m
    console.error('waitForElement', 'Infinite loop error', selector);
    sentryCaptureException('waitForElement', `Infinite loop error (${selector})`, { selector }, { level: 'error' });
    return;
  }
  // End check count loop

  const element = doc?.querySelector(selector);
  if (element) {
    delete awaitSelectorKeys[selector];
    cb?.();
  } else {
    //hotfix contentDocument not reactive (need to refactor all waitForElement)
    const $iframe = document.querySelector<HTMLIFrameElement>('.iframe');
    doc = $iframe?.contentDocument || ($iframe?.contentWindow as any)?.document;
    setTimeout(() => waitForElement(doc, selector, cb), 500);
  }
}

/**
 * When need emit data to iframe?
 * - First load PageSection
 * - Add new Section
 * - Section change position
 * - Delete a Section
 * - Hide/Show a Section
 * - Add new Component to Section
 * - Component change setting
 * - Move a Component
 * - Remove a Component
 * - Undo/Redo
 * - Active setting a component
 * - Focus outside preview(iframe)
 */

export function emitInitBuilder(data: Component[], callback?: () => void, className = '.iframe') {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // const $body = $doc.querySelector('body');
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('init-builder', {
      bubbles: true,
      detail: {
        data: cloneDeepObject(data),
      },
    });
    if ($win) {
      waitForElement($win.document, '.builder', () => {
        $win.dispatchEvent(event);
        if (callback) {
          callback();
        }
      });
    }
  }
}

export function emitAddEntity(
  {
    entity,
    id,
    position,
  }: {
    entity: Component;
    id?: string;
    position?: number;
  },
  callback?: () => void,
  className = '.iframe',
) {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // const $body = $doc.querySelector('body');
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property

    const event = new CustomEvent('add-entity', {
      bubbles: true,
      detail: {
        entity,
        id,
        position,
      },
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}

export function emitUpdateSelectOnPageInteraction(
  value: boolean,
  callback?: () => void,
  extraData?: {
    mode?: 'PAGE' | 'ELEMENT';
    settingType?: 'TRIGGER' | 'TARGET';
  },
) {
  const $iframe = document.querySelector<HTMLIFrameElement>('.iframe');
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;

    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('update-interaction-is-select-on-page', {
      bubbles: true,
      detail: {
        value,
        mode: 'ELEMENT',
        ...extraData,
      },
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}

export function emitUpdateCurrentInteraction(value?: Interaction, callback?: () => void, className = '.iframe') {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('update-interaction-item', {
      bubbles: true,
      detail: value,
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}

export function emitRemoveEntity(uid: string, callback?: () => void, className = '.iframe') {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('remove-entity', {
      bubbles: true,
      detail: {
        uid: uid,
      },
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}

export function emitUpdateMarquee(
  {
    value,
    id,
    field,
  }: {
    value?: string;
    id?: string;
    field?: 'hoverItem' | 'activeItem';
  },
  callback?: () => void,
  className = '.iframe',
) {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('update-marquee', {
      bubbles: true,
      detail: {
        value,
        id,
        field,
      },
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}

export function emitMoveEntity(
  { uid, to, position }: { uid: string; to: string; position: number },
  callback?: () => void,
  className = '.iframe',
) {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('move-entity', {
      bubbles: true,
      detail: { uid, to, position },
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}

export function emitUpdateItemName(
  detail: { uid: string; name: string },
  callback?: () => void,
  className = '.iframe',
) {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    const $win = $iframe.contentWindow;
    const event = new CustomEvent('update-item-name', {
      bubbles: true,
      detail: cloneDeepObject(detail),
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}

export function emitUpdateItemAttribute(
  detail: { uid: string; value: string | boolean; attr: string },
  callback?: () => void,
  className = '.iframe',
) {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    const $win = $iframe.contentWindow;
    const event = new CustomEvent('update-item-attribute', {
      bubbles: true,
      detail: cloneDeepObject(detail),
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}

export function emitUpdateEntityProp(
  detail: { uid: string; propName: string; propValue: any; group: GroupTypeSetting },
  callback?: () => void,
  className = '.iframe',
) {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // const $body = $doc.querySelector('body');
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('update-entity-prop', {
      bubbles: true,
      detail: cloneDeepObject(detail),
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}
export function emitForceUpdateEntityProps(
  detail: { uid: string; data: Record<string, any>; group: GroupTypeSetting },
  callback?: () => void,
  className = '.iframe',
) {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // const $body = $doc.querySelector('body');
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('force-update-entity-props', {
      bubbles: true,
      detail: cloneDeepObject(detail),
    });
    if ($win) {
      $win.dispatchEvent(event);
      if (callback) {
        callback();
      }
    }
  }
}

export function emitSetGlobalStyle(
  { globalStyle, mobileOnly }: { globalStyle: any; mobileOnly?: boolean },
  callback?: () => void,
  className = '.iframe',
) {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('set-global-style', {
      bubbles: true,
      detail: { data: cloneDeepObject(globalStyle), mobileOnly },
    });
    if ($win) {
      waitForElement($win.document, '.builder', () => {
        $win.dispatchEvent(event);
        if (callback) {
          callback();
        }
      });
    }
  }
}

export function emitUpdateGlobalSwatchesData(
  { swatchesData }: { swatchesData: GlobalSwatchesData[] },
  callback?: () => void,
  className = '.iframe',
) {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('update-global-swatches-data', {
      bubbles: true,
      detail: { data: cloneDeepObject(swatchesData) },
    });
    if ($win) {
      waitForElement($win.document, '.builder', () => {
        $win.dispatchEvent(event);
        if (callback) {
          callback();
        }
      });
    }
  }
}

export function emitUpdateProductOffer(productOffers: ProductOffer, callback?: () => void, className = '.iframe') {
  const $iframe = document.querySelector<HTMLIFrameElement>(className);
  if ($iframe) {
    // const $doc = $iframe.contentDocument || ($iframe.contentWindow && ($iframe.contentWindow as any).document);
    const $win = $iframe.contentWindow;
    // Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
    const event = new CustomEvent('set-product-offer', {
      bubbles: true,
      detail: productOffers,
    });
    if ($win) {
      waitForElement($win.document, '.builder', () => {
        $win.dispatchEvent(event);
        if (callback) {
          callback();
        }
      });
    }
  }
}

export function emitUpdateDynamicDiscountOffer(dynamicDiscountOffer: OfferDynamicDiscount, callback?: () => void) {
  const $iframe = document.querySelector<HTMLIFrameElement>('.iframe');
  if (!$iframe) return;
  const $win = $iframe.contentWindow;
  if (!$win) return;
  const event = new CustomEvent('set-dynamic-discount-offer', {
    bubbles: true,
    detail: dynamicDiscountOffer,
  });
  waitForElement($win.document, '.builder', () => {
    $win.dispatchEvent(event);
    callback?.();
  });
}

function emitEventToBuilder(eventName: string, data: any, callback?: () => void) {
  const $iframe = document.querySelector<HTMLIFrameElement>('.iframe');
  if ($iframe) {
    const $win = $iframe.contentWindow;
    const event = new CustomEvent(eventName, {
      bubbles: true,
      detail: typeof data === 'object' ? cloneDeepObject(data) : data,
    });
    if ($win) {
      waitForElement($win.document, '.builder', () => {
        $win.dispatchEvent(event);
        if (callback) {
          callback();
        }
      });
    }
  }
}
function emitChangePageNameEvent(eventName: string, pageName: string, callback?: () => void) {
  const $iframe = document.querySelector<HTMLIFrameElement>('.iframe');
  if ($iframe) {
    const $win = $iframe.contentWindow;
    const event = new CustomEvent(eventName, {
      bubbles: true,
      detail: {
        pageName,
      },
    });
    if ($win) {
      waitForElement($win.document, '.builder', () => {
        $win.dispatchEvent(event);
        if (callback) {
          callback();
        }
      });
    }
  }
}
function emitChangeAssignedProductEvent(assignedProductTitle: string, pageType: string, callback?: () => void) {
  const $iframe = document.querySelector<HTMLIFrameElement>('.iframe');

  if (![PRODUCT_PAGE, COLLECTION_PAGE].includes(pageType)) return;
  const eventNameEmit = pageType === PRODUCT_PAGE ? 'change-product-assigned' : 'change-collection-assigned';
  if ($iframe) {
    const $win = $iframe.contentWindow;
    const event = new CustomEvent(eventNameEmit, {
      bubbles: true,
      detail: {
        assignedProductTitle,
      },
    });
    if ($win) {
      waitForElement($win.document, '.builder', () => {
        $win.dispatchEvent(event);
        if (callback) {
          callback();
        }
      });
    }
  }
}

export function emitChangeSidebarMode(data: { mode?: string }) {
  emitEventToBuilder('change-sidebar-mode', data);
}

export function emitOnOffHeaderFooter(data?: string) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('on-off-header-footer', JSON.parse(data || '{}'));
}

export function emitUpdateCollectionInfo(data: { baseID?: string | null }, callback?: () => void) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('update-collection-info', data, callback);
}

export function emitUpdateShopInfo(data: { shopHandle: string; shopToken: string }, callback?: () => void) {
  emitEventToBuilder('update-shop-info', data, callback);
}
export function emitChangePageName(pageName: string, callback?: () => void) {
  emitChangePageNameEvent('change-page-name', pageName, callback);
}
export function emitChangeAssignedProduct(assignedProductTitle: string, pageType: string, callback?: () => void) {
  emitChangeAssignedProductEvent(assignedProductTitle, pageType, callback);
}

export function emitRevalidateQueryStorefront(callback?: () => void) {
  emitEventToBuilder('revalidate-query', {}, callback);
}

export function emitSelectModal(modalId?: string, callback?: () => void) {
  emitEventToBuilder(
    'set-active-modal',
    {
      modalId,
    },
    callback,
  );
}

export function emitActiveComponent(
  data: {
    componentUid: string;
    productId?: string;
    articleId?: string;
    marqueeKey?: string;
  } | null,
  callback?: () => void,
) {
  emitEventToBuilder('editor:active-component', data, callback);
}

export function emitHoverComponent(
  data: {
    componentUid: string;
    type?: string;
  } | null,
  callback?: () => void,
) {
  emitEventToBuilder('editor:hover-component', data, callback);
}

export function emitToolbarOnboarding(
  data: {
    isNewUser: boolean;
  } | null,
  callback?: () => void,
) {
  emitEventToBuilder('editor:toolbar-onboarding', data, callback);
}

export function emitFocusOutsidePreview(callback?: () => void) {
  emitEventToBuilder('editor:focus-outside-preview', {}, callback);
}

export function emitUpdateInteractionSettingType(settingType?: 'TRIGGER' | 'TARGET', callback?: () => void) {
  emitEventToBuilder('update-interaction-setting-type', { settingType }, callback);
}

export function emitIsDragging(value: boolean, callback?: () => void) {
  emitEventToBuilder(
    'editor:is-dragging',
    {
      value,
    },
    callback,
  );
}

export function emitIsEditingTextEditor(value: boolean, callback?: () => void) {
  emitEventToBuilder('editor:is-editing-text-editor', { value }, callback);
}

export function emitCloseAddSectionPopup(callback?: () => void) {
  emitEventToBuilder('editor:close-add-section-popup', {}, callback);
}

export function emitUpdateToolbarMarginBottom(callback?: () => void) {
  emitEventToBuilder('editor:toolbar:update-margin-bottom', callback);
}

export function emitUpdateToolbarPaddingLeft(callback?: () => void) {
  emitEventToBuilder('editor:toolbar:update-padding-left', callback);
}

export function emitResizePreviewScreen(data: { width: number }, callback?: () => void) {
  emitEventToBuilder('editor:resize-preview-screen', data, callback);
}

export function emitUpdateCreateThemeSectionCount(count: number, callback?: () => void) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('update-create-theme-section-count', count, callback);
}

export function emitUpdateShopPlan(plan?: string, callback?: () => void) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('update-shop-plan', plan, callback);
}

export function emitUpdateShopifyPlan(plan?: string, callback?: () => void) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('update-shopify-plan', plan, callback);
}

export function emitDynamicProduct(data?: { productId?: string; productHandle?: string }, callback?: () => void) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('set-dynamic-product', data, callback);
}

export function emitDynamicCollection(data?: { collectionId?: string }, callback?: () => void) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('set-dynamic-collection', data, callback);
}

export function emitPreviewAnimation({ uid, isCancel }: { uid: string; isCancel?: boolean }) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('preview-animation', { uid, isCancel });
}

export function emitInitAnimationTarget() {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('init-animation-target', {});
}

export function emitEventGenerateContentStatus(data: {
  status: 'idle' | 'loading' | 'success' | 'error';
  generatingUid: string;
}) {
  emitEventToBuilder('editor:ai-generate-content-status', data);
}

export function emitEventGenerateContentUpdateLimitation(data: {
  isAllow: boolean;
  maxGenerateCount?: number;
  nextQuota?: number;
}) {
  emitEventToBuilder('editor:ai-generate-content-update-limitation', data);
}

export function emitLimitCreateThemeSection(data: any, callback?: () => void) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('limit-create-theme-section', data, callback);
}

export function emitUpdateFontType(fontType?: string, callback?: () => void) {
  const { emitEventToToolbox } = useEmitToFrame();
  emitEventToToolbox('update-font-type', fontType, callback);
}
