<template>
  <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V12C32 16.4183 28.4183 20 24 20H8C3.58172 20 0 16.4183 0 12V8Z"
      fill="white"
      fill-opacity="0.2" />
    <path
      d="M14.6 10.5998C14.6 10.2684 14.8687 9.9998 15.2 9.9998H16.8C17.1314 9.9998 17.4 10.2684 17.4 10.5998C17.4 10.9312 17.1314 11.1998 16.8 11.1998H15.2C14.8687 11.1998 14.6 10.9312 14.6 10.5998Z"
      fill="#AAAAAA" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2 4.7998C11.4269 4.7998 10.8 5.42661 10.8 6.1998V7.7998C10.8 8.27555 11.0374 8.69588 11.4 8.94887V12.9998C11.4 14.2148 12.385 15.1998 13.6 15.1998H18.4C19.6151 15.1998 20.6 14.2148 20.6 12.9998V8.94887C20.9627 8.69588 21.2 8.27555 21.2 7.7998V6.1998C21.2 5.42661 20.5732 4.7998 19.8 4.7998H12.2ZM19.4 9.1998H12.6V12.9998C12.6 13.5521 13.0478 13.9998 13.6 13.9998H18.4C18.9523 13.9998 19.4 13.5521 19.4 12.9998V9.1998ZM12 6.1998C12 6.08935 12.0896 5.9998 12.2 5.9998H19.8C19.9105 5.9998 20 6.08935 20 6.1998V7.7998C20 7.91026 19.9105 7.9998 19.8 7.9998H12.2C12.0896 7.9998 12 7.91026 12 7.7998V6.1998Z"
      fill="#AAAAAA" />
  </svg>
</template>
