import type { useImageSelectState } from './useImageSelectState';
import type { AlertType, ImageSelectorEmits, ImageSelectorProps, ImageUploadType } from '../types';

import { useImageValidation } from './useImageValidation';
import { TIME_OUT_VERIFY_IMAGE } from '../constants';
import type { FileSelectFragment } from '@gem/control/src/type/graphql';

export const useImageSelectActions = (
  props: ImageSelectorProps,
  emit: ImageSelectorEmits,
  state: ReturnType<typeof useImageSelectState>,
) => {
  const { isImageValid, setImageSource } = useImageValidation(state);

  const handleLoadDeletedImage = () => emit('load-deleted-image');
  const handleShowMoreImage = (value: string) => emit('show-more', value);
  const handleSetIsUploading = (isUploading: boolean) => emit('set-is-uploading', isUploading);

  const handleSetImageList = (imageList: FileSelectFragment[]) => {
    state.imageList.value = imageList;
  };

  const handleDeleteImage = (id: string) => {
    emit('delete', id);

    const imageIndex = state.imageList.value.findIndex((image) => image.node?.id === id);

    if (imageIndex === -1) return;

    const deletedImage = state.imageList.value[imageIndex];

    if (deletedImage.node?.isShow) {
      deletedImage.node.isShow = false;
    }

    state.deletedImageList.value?.push(deletedImage);
    state.imageList.value.splice(imageIndex, 1);
  };

  const handleForceDeleteImage = (id: string) => {
    emit('force-delete', id);

    const deletedImageIndex = state.deletedImageList.value.findIndex((image) => image.node?.id === id);

    if (deletedImageIndex === -1) return;

    state.deletedImageList.value.splice(deletedImageIndex, 1);
  };

  const handleRestoreImage = (id: string) => {
    emit('restore', id);

    const deletedImageIndex = state.deletedImageList.value.findIndex((image) => image.node?.id === id);

    if (deletedImageIndex === -1) return;

    const imageToRestore = state.deletedImageList.value[deletedImageIndex];

    state.imageList.value?.push(imageToRestore);
    state.deletedImageList.value.splice(deletedImageIndex, 1);
  };

  const handlePostUploadImage = (urlImage: File) => {
    const formData = new FormData();
    formData.append('file', urlImage);
    formData.append('fileType', 'GALLERY');
    formData.append('mimeType', urlImage.type);

    emit('upload', formData);
  };

  const handleChangeFileFromUploader = (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;

    if (!props.allowedFiles?.includes(file.type)) {
      handleShowAlert('format');
      return;
    }

    if (file.size > (props.maximumSize || 0)) {
      handleShowAlert('size');
      return;
    }

    handlePostUploadImage(file);
    state.isUploading.value = true;
    state.isLoaded.value = false;
    handleSetIsUploading(true);
  };

  const handleShowAlert = (type: AlertType) => {
    state.alertType.value = type;
    state.showAlert.value = true;
  };

  const triggerControlChange = () => {
    const imageFile = {
      src: state.imageSrc.value,
      width: state.imageWidth.value,
      height: state.imageHeight.value,
      storage: state.imageFileStorage.value,
      backupFileKey: state.imageBackupFileKey.value,
    };

    emit('control-change', imageFile);
  };

  const changeImageLink = async (imgLink: string) => {
    setImageSource(imgLink);

    const isValid = await isImageValid(imgLink, TIME_OUT_VERIFY_IMAGE);
    if (!isValid) return;

    state.imageBackupFileKey.value = '';

    triggerControlChange();
  };

  const handleTriggerImageChange = ({
    width,
    height,
    storage,
    filePath,
    backupFileKey,
    backupFilePath,
  }: ImageUploadType) => {
    setImageSource(filePath);

    state.imageWidth.value = width;
    state.imageHeight.value = height;
    state.imageFileStorage.value = storage;
    state.imageBackupFileKey.value = backupFileKey;
    state.imageBackupFilePath.value = backupFilePath;

    triggerControlChange();
  };

  const handleClearUrl = () => {
    state.imageSrc.value = '';
    state.imageBackupFileKey.value = '';
    emit('clear-url');
    triggerControlChange();
  };

  const openGallery = () => {
    state.isOpenGallery.value = true;
  };

  const closeGallery = () => {
    state.isOpenGallery.value = false;
  };

  return {
    openGallery,
    closeGallery,
    changeImageLink,
    handleClearUrl,
    handleShowAlert,
    handleDeleteImage,
    handleSetImageList,
    handleRestoreImage,
    handleShowMoreImage,
    handleSetIsUploading,
    handlePostUploadImage,
    handleLoadDeletedImage,
    handleForceDeleteImage,
    handleTriggerImageChange,
    handleChangeFileFromUploader,
  };
};
