import type { ObjectDevices, ScreenType } from '@gem/common';
import type { VisibilityProps } from '../types';
import { computed } from 'vue';

export const useVisibility = (props: VisibilityProps, emit?: any) => {
  const controlChangeData = computed(() => props.controlChangeData);

  const devicesValue = computed((): ObjectDevices<{ default?: boolean }> | undefined => {
    return {
      desktop: checkToggleAbility({
        ...controlChangeData.value?.devices,
        desktop: { default: controlChangeData.value?.devices?.desktop?.default },
      })
        ? { default: controlChangeData.value?.devices?.desktop?.default }
        : { default: true },
      tablet: checkToggleAbility({
        ...controlChangeData.value?.devices,
        tablet: { default: controlChangeData.value?.devices?.tablet?.default },
      })
        ? { default: controlChangeData.value?.devices?.tablet?.default }
        : { default: true },
      mobile: checkToggleAbility({
        ...controlChangeData.value?.devices,
        mobile: {
          default: controlChangeData.value?.devices?.mobile?.default,
        },
      })
        ? { default: controlChangeData.value?.devices?.mobile?.default }
        : { default: true },
    };
  });

  const checkToggleAbility = (checkObject?: ObjectDevices<{ default?: boolean }>) => {
    if (checkObject === undefined) return;
    return Object.values(checkObject).filter((v) => v.default === true && v.default !== undefined).length >= 1;
  };

  const handleChange = (id?: string, toggleV?: boolean) => {
    if (devicesValue.value) {
      if (toggleV === false && !checkToggleAbility({ ...devicesValue.value, [id as ScreenType]: { default: false } })) {
        alert("You can't hide this element in all screens, better to delete it.");
        return;
      }
      const clone = { ...devicesValue.value, [id as ScreenType]: { default: toggleV } };

      const input = Object.keys(clone).map((key) => {
        if (key !== id)
          return {
            screenId: key as ScreenType,
            newValue: clone[key as ScreenType]?.default,
          };
        return {
          screenId: id as ScreenType,
          newValue: toggleV,
        };
      });
      emit('controlChange', input);
    }
  };

  return { devicesValue, handleChange };
};
