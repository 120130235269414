<template>
  <div class="custom-control-loading h-full w-full">
    <div class="custom-control-loading-icon h-full w-full"></div>
  </div>
</template>

<style scoped lang="scss">
.custom-control-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #0f0f0f;

  &-icon {
    width: 50px;
    height: 50px;
    border: 5px solid;
    border-radius: 50%;
    border-top-color: transparent;
    color: #91d7f2;
    animation: loading 1.2s linear infinite;
  }

  @keyframes loading {
    25% {
      color: #5196a6;
    }
    50% {
      color: #f2f0eb;
    }
    75% {
      color: #f25041;
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
