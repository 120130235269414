<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3203 8.93593L14.7969 12.0109L15.8524 16.5891C15.9082 16.8284 15.8923 17.0789 15.8065 17.3092C15.7208 17.5395 15.5691 17.7395 15.3703 17.884C15.1716 18.0286 14.9346 18.1113 14.6891 18.122C14.4436 18.1326 14.2004 18.0706 13.9899 17.9437L9.99689 15.5219L6.01252 17.9437C5.80202 18.0706 5.55881 18.1326 5.31328 18.122C5.06775 18.1113 4.83079 18.0286 4.63204 17.884C4.4333 17.7395 4.28157 17.5395 4.19584 17.3092C4.1101 17.0789 4.09416 16.8284 4.15002 16.5891L5.20392 12.0156L1.6797 8.93593C1.49331 8.77517 1.35852 8.56295 1.29225 8.32589C1.22598 8.08883 1.23117 7.83748 1.30718 7.60336C1.38319 7.36924 1.52663 7.16278 1.71952 7.00985C1.9124 6.85693 2.14614 6.76436 2.39142 6.74375L7.03674 6.3414L8.85002 2.0164C8.94471 1.78946 9.10443 1.59561 9.30907 1.45926C9.51371 1.32291 9.75411 1.25015 10 1.25015C10.2459 1.25015 10.4863 1.32291 10.691 1.45926C10.8956 1.59561 11.0553 1.78946 11.15 2.0164L12.9688 6.3414L17.6125 6.74375C17.8578 6.76436 18.0915 6.85693 18.2844 7.00985C18.4773 7.16278 18.6207 7.36924 18.6968 7.60336C18.7728 7.83748 18.778 8.08883 18.7117 8.32589C18.6454 8.56295 18.5106 8.77517 18.3242 8.93593H18.3203Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'StarFilled',
};
</script>
