<script setup lang="ts">
import { getBackgroundStyle } from '../helpers';
import { SettingLayout } from '@gem/element-setting-ui';
import { useColorList } from '../hooks/useColorList';
import type { ColorListProps } from '../types';

const props = defineProps<ColorListProps>();
const emits = defineEmits(['onPickColor']);

const { allColors, isActive, onSelected } = useColorList(props, emits);
</script>

<template>
  <SettingLayout :label="{ en: title }" layout="vertical">
    <template #control>
      <div data-test="editor-control-suggested-colors" class="-mt-8 flex w-full flex-wrap gap-8">
        <div v-for="myColor in allColors" :key="myColor">
          <div
            data-test="editor-control-suggested-color-option"
            class="group flex h-28 w-28 cursor-pointer items-center justify-center"
            @click="onSelected(myColor)">
            <div
              class="group-hover:border-dark-50 flex h-28 w-28 items-center justify-center rounded-xl border border-transparent"
              :class="[
                { '!border-primary-300 ': isActive(myColor) },
                { 'group-hover:border-dark-50': !isActive(myColor) },
              ]">
              <div class="relative h-24 w-24 rounded-[6px]">
                <div
                  class="gemx-transparent-bg absolute top-1/2 left-1/2 h-[20px] w-[20px] -translate-x-1/2 -translate-y-1/2 transform rounded-[6px] border border-transparent" />
                <div
                  :style="{
                    ...getBackgroundStyle(myColor),
                  }"
                  class="absolute top-1/2 left-1/2 h-[20px] w-[20px] -translate-x-1/2 -translate-y-1/2 transform rounded-[6px] border" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SettingLayout>
</template>

<style scoped>
.gemx-transparent-bg {
  background-image: url('/images/color-transparent-preview.png');
  background-size: cover;
  background-position: center;
}
</style>
