<template>
  <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#5E5E5E"/>
<circle cx="14.5" cy="16" r="2" fill="#F9F9F9"/>
<path d="M12.6969 24.6453L14.9097 22.0595C14.9846 21.9718 15.0772 21.9008 15.1814 21.8512C15.2856 21.8017 15.3991 21.7746 15.5145 21.7718C15.6299 21.769 15.7446 21.7905 15.8511 21.835C15.9576 21.8795 16.0535 21.9458 16.1326 22.0298L17.1673 23.1271L21.212 18.2946C21.2912 18.1999 21.3908 18.1243 21.5033 18.0733C21.6157 18.0224 21.7383 17.9974 21.8618 18.0002C21.9852 18.0031 22.1065 18.0337 22.2165 18.0898C22.3265 18.1459 22.4224 18.2261 22.4972 18.3243L27.3329 24.6772C27.4254 24.7989 27.482 24.9441 27.4964 25.0962C27.5107 25.2484 27.4823 25.4016 27.4144 25.5385C27.3464 25.6754 27.2415 25.7907 27.1115 25.8713C26.9815 25.952 26.8316 25.9948 26.6786 25.995L13.322 26C13.1649 25.9999 13.0111 25.9549 12.8789 25.8703C12.7466 25.7857 12.6413 25.6649 12.5755 25.5224C12.5098 25.3799 12.4862 25.2216 12.5077 25.0661C12.5292 24.9107 12.5949 24.7646 12.6969 24.6453Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'ImageCircleStyle'
};
</script>
