<template>
  <svg width="100%" height="100%" viewBox="0 0 94 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.2803 12.7803C21.5732 12.4874 21.5732 12.0126 21.2803 11.7197C20.9874 11.4268 20.5126 11.4268 20.2197 11.7197L17.25 14.6893L16.0303 13.4697C15.7374 13.1768 15.2626 13.1768 14.9697 13.4697C14.6768 13.7626 14.6768 14.2374 14.9697 14.5303L16.7197 16.2803C17.0126 16.5732 17.4874 16.5732 17.7803 16.2803L21.2803 12.7803Z" fill="#F9F9F9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 7.5C12.7312 7.5 11.5 8.73122 11.5 10.25V17.75C11.5 19.2688 12.7312 20.5 14.25 20.5H21.75C23.2688 20.5 24.5 19.2688 24.5 17.75V10.25C24.5 8.73122 23.2688 7.5 21.75 7.5H14.25ZM13 10.25C13 9.55964 13.5596 9 14.25 9H21.75C22.4404 9 23 9.55964 23 10.25V17.75C23 18.4404 22.4404 19 21.75 19H14.25C13.5596 19 13 18.4404 13 17.75V10.25Z" fill="#F9F9F9"/>
<rect x="32" y="12" width="54" height="4" rx="1.8125" fill="#AAAAAA"/>
</svg>
</template>

<script>
export default {
  name: 'InputCheckbox'
};
</script>
