import type { GlobalSwatchesData } from '@gem/common';
import { defineStore } from 'pinia';

export type State = {
  data: GlobalSwatchesData[];
  currentData: GlobalSwatchesData[];
};

export const useSwatchesStore = defineStore('swatches', {
  state: (): State => ({
    data: [],
    currentData: [],
  }),
  getters: {
    getDataSwatches(state) {
      return state.data;
    },
    getCurrentDataSwatches(state) {
      return state.currentData;
    },
  },
  actions: {
    setDataSwatches(data: GlobalSwatchesData[]) {
      this.data = data;
    },
    setCurrentDataSwatches(data: GlobalSwatchesData[]) {
      this.currentData = data;
    },
  },
});

export default useSwatchesStore;
