import type { GDropdownMenuItemProps } from '@gem/uikit-v2';
import { computed, ref, watch, nextTick } from 'vue';
import { useGlobalStyleSpacing } from '../../base/hooks/useGlobalStyleSpacing';
import type { PaddingProps, PaddingType } from '../types';

export const usePaddingSetting = (paddingSettingProps: PaddingProps, emit: any) => {
  const defaultEnableValue = {
    top: '16px',
    right: '16px',
    bottom: '16px',
    left: '16px',
  };

  const positionOptions = {
    top: {
      key: 'top',
      icon: 'border-position-top',
      iconPosition: 'first',
    },
    left: {
      key: 'left',
      icon: 'border-position-left',
      iconPosition: 'first',
    },
    right: {
      key: 'right',
      icon: 'border-position-right',
      iconPosition: 'last',
    },
    bottom: {
      key: 'bottom',
      icon: 'border-position-bottom',
      iconPosition: 'first',
    },
  };

  const { getDisplayValue, getStringValueFromGlobal } = useGlobalStyleSpacing(paddingSettingProps.globalSpacingValues);

  const globalSpacingOptions: GDropdownMenuItemProps[] = [
    {
      title: 'Auto',
      value: 'Auto',
    },
    {
      title: 'XXS',
      value: '2px',
    },
    {
      title: 'XS',
      value: '4px',
    },
    {
      title: 'S',
      value: '8px',
    },
    {
      title: 'M',
      value: '12px',
    },
    {
      title: 'L',
      value: '16px',
    },
    {
      title: 'XL',
      value: '24px',
    },
    {
      title: '2XL',
      value: '32px',
    },
    {
      title: '3XL',
      value: '48px',
    },
    {
      title: '4XL',
      value: '80px',
    },
    {
      title: '5XL',
      value: '112px',
    },
  ];

  const dropdownOptions = computed(() => {
    return globalSpacingOptions.map((item) => {
      return { ...item, showValue: item.title !== 'auto' };
    });
  });

  const isFocus = ref(false);
  const val = computed(() => paddingSettingProps.value);
  const paddingTopVal = ref(getDisplayValue(val.value?.top));

  const defaultValue = computed(() => {
    return paddingSettingProps.compoDefaultValue || defaultEnableValue;
  });

  const isSameValue = computed(() => {
    return (
      val.value?.top === val.value?.right &&
      val.value?.right === val.value?.bottom &&
      val.value?.bottom === val.value?.left
    );
  });

  const isZeroValue = computed(() => {
    return (
      (!val.value?.top || val.value?.top === '0px' || val.value?.top === '0' || val.value?.top === 'auto') &&
      isSameValue.value
    );
  });

  const isLinkedValue = computed(() => {
    if (typeof val.value?.linked !== 'undefined') return val.value.linked;
    return isSameValue.value;
  });

  const isLinked = ref(isLinkedValue.value);

  const displayComboValue = computed(() => {
    if (!val.value || Object.keys(val.value).length === 0 || isZeroValue.value) return '';

    const output = [];
    const paddingPositions = ['top', 'right', 'bottom', 'left'];
    for (const key in paddingPositions) {
      const value = val.value[isLinked.value ? 'top' : (paddingPositions[key] as keyof PaddingType)];
      const numberValue = getNumberValue(value?.toString());
      const displayVal = numberValue?.toLocaleLowerCase() === 'auto' ? 'Auto' : parseFloat(numberValue);
      output.push(displayVal);
    }

    return output.join(', ');
  });

  const getNumberValue = (value?: string | undefined) => {
    const stringValue = getStringValueFromGlobal(value);
    return getDisplayValue(stringValue);
  };

  const handleEmit = (type: 'change' | 'onChange', key: string, value?: string) => {
    let dataChange = { ...val.value, [key]: value, link: isLinked.value };
    if (isLinked.value) {
      paddingTopVal.value = value as any;
      dataChange = { top: value, right: value, bottom: value, left: value, link: true };
    }

    if (type === 'onChange') {
      emit('controlOnChange', dataChange);
    } else emit('controlChange', dataChange);
  };

  const onChangeInput = (key: string, value?: string) => {
    handleEmit('onChange', key, value);
  };

  const changeInput = (key: string, value?: string) => {
    handleEmit('change', key, value ?? '0');
  };

  const handleSetDefaultValue = () => {
    (!val.value || !val.value.top || isZeroValue.value) && emit('controlChange', defaultValue.value);
    isFocus.value = false;
    nextTick(() => {
      isFocus.value = true;
    });
  };

  const handleLinked = () => {
    isLinked.value = !isLinked.value;
    const topVal = val.value?.top;
    emit('controlChange', {
      top: topVal,
      left: topVal,
      right: topVal,
      bottom: topVal,
      linked: isLinked.value,
    });
    emit('change-link', isLinked.value);
  };

  const handleClear = () => {
    emit('controlChange', { top: '0px', right: '0px', bottom: '0px', left: '0px' });
  };

  const isDisableInput = (key: string) => {
    return isLinked.value && key !== 'top';
  };

  watch(
    () => val.value,
    () => {
      paddingTopVal.value = getDisplayValue(val.value?.top);
      isLinked.value = isLinkedValue.value;
    },
  );

  return {
    val,
    isLinked,
    defaultValue,
    isZeroValue,
    displayComboValue,
    positionOptions,
    dropdownOptions,
    onChangeInput,
    changeInput,
    isFocus,
    // paddingTopVal,
    handleSetDefaultValue,
    handleLinked,
    handleClear,
    isDisableInput,
    getNumberValue,
  };
};
