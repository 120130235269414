<template>
  <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V12C32 16.4183 28.4183 20 24 20H8C3.58172 20 0 16.4183 0 12V8Z"
      fill="#3C67FF"
      fill-opacity="0.2" />
    <path
      d="M20.7977 2.88278C20.9539 2.72657 21.2071 2.72657 21.3634 2.88278L22.2119 3.73131C22.3681 3.88752 22.3681 4.14079 22.2119 4.297L21.4462 5.06269L20.032 3.64847L20.7977 2.88278Z"
      fill="#8AA4FF" />
    <path
      d="M19.4663 4.21416L20.8805 5.62837L17.816 8.69284C17.529 8.97992 17.1435 9.14713 16.7377 9.16059L16.1201 9.18108C16.0045 9.18491 15.9098 9.09017 15.9136 8.97456L15.9341 8.35694C15.9475 7.95118 16.1148 7.5657 16.4018 7.27863L19.4663 4.21416Z"
      fill="#8AA4FF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5805 5.99994C13.0848 5.99994 12.6639 6.36306 12.5913 6.85339L12.1251 9.99994H14.1117C14.5422 9.99994 14.9243 10.2754 15.0604 10.6837L15.1869 11.0632C15.2141 11.1449 15.2906 11.1999 15.3767 11.1999H16.6234C16.7095 11.1999 16.786 11.1449 16.8132 11.0632L16.9397 10.6837C17.0758 10.2754 17.4579 9.99994 17.8884 9.99994H19.875L19.6065 8.18787C19.558 7.86008 19.7843 7.55498 20.1121 7.50642C20.4399 7.45786 20.745 7.68422 20.7936 8.01201L21.1245 10.2456C21.1748 10.5852 21.2 10.9281 21.2 11.2714V12.5999C21.2 14.0359 20.036 15.1999 18.6 15.1999H13.4C11.9641 15.1999 10.8 14.0359 10.8 12.5999V11.2714C10.8 10.9281 10.8253 10.5852 10.8756 10.2456L11.4042 6.67753C11.564 5.5988 12.49 4.79994 13.5805 4.79994H16.6C16.9314 4.79994 17.2 5.06857 17.2 5.39994C17.2 5.73131 16.9314 5.99994 16.6 5.99994H13.5805ZM12 11.2714C12 11.2476 12.0002 11.2238 12.0005 11.1999H13.9676L14.0485 11.4427C14.2391 12.0143 14.7741 12.3999 15.3767 12.3999H16.6234C17.226 12.3999 17.761 12.0143 17.9516 11.4427L18.0325 11.1999H19.9996C19.9999 11.2238 20 11.2476 20 11.2714V12.5999C20 13.3731 19.3732 13.9999 18.6 13.9999H13.4C12.6269 13.9999 12 13.3731 12 12.5999V11.2714Z"
      fill="#8AA4FF" />
  </svg>
</template>
