import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import type { BorderStyle, BorderType } from '@gem/element-setting-ui';
import type { IconName } from '@gem/icons';

export const useBorderType = (borderValues: ComputedRef<BorderStyle | undefined>) => {
  const typeOptions: {
    label: string;
    value: BorderType;
    iconName?: IconName;
    hide?: boolean;
  }[] = [
    {
      label: '',
      value: 'none',
      hide: true,
    },
    {
      label: 'Line',
      value: 'solid',
      iconName: 'gp-line',
    },
    {
      label: 'Dash',
      value: 'dashed',
      iconName: 'gp-dash',
    },
    {
      label: 'Dot',
      value: 'dotted',
      iconName: 'gp-dots',
    },
  ];

  const borderType = computed(() => {
    if (!borderValues.value?.border) return 'none';
    if (
      borderValues.value?.border !== 'none' &&
      borderValues.value?.borderWidth &&
      ['0px', '0'].includes(borderValues.value?.borderWidth)
    )
      return 'none';
    return borderValues.value.border;
  });

  const borderTypeOptionSelected = computed(() => {
    return typeOptions.find((item) => item.value === borderType.value);
  });

  const getBorderStyleWhenChangeBorderType = (type: BorderType) => {
    return {
      ...borderValues.value,
      border: type,
      borderWidth: ['0px', '0', undefined].includes(borderValues.value?.borderWidth)
        ? '1px'
        : borderValues.value?.borderWidth,
      width: ['0px', '0', undefined].includes(borderValues.value?.borderWidth)
        ? '1px 1px 1px 1px'
        : borderValues.value?.width,
    };
  };

  return {
    typeOptions,
    borderTypeOptionSelected,
    getBorderStyleWhenChangeBorderType,
  };
};
