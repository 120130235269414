import { parseUnit } from '@gem/control';
import type { ControlInputAndSliderTypes } from '@gem/element-setting-ui';
import { computed, onMounted, ref, watch } from 'vue';

export const useInputAndSlider = (props: ControlInputAndSliderTypes, emit: any) => {
  const initValue = (value?: any) => value?.toString() ?? '0';
  const sliderVal = ref<number>(parseFloat(initValue(props.value)));
  const currentUnit = computed(() => parseUnit(props.value?.toString() ?? '0')[1]);
  const inputVal = ref<string>(initValue(props.value));

  const handleControlOnChange = (value: number) => {
    inputVal.value = `${value}${currentUnit.value}`;
    emit('controlOnChange', inputVal.value);
  };

  const handleControlChange = () => {
    emit('controlChange', inputVal.value);
  };

  const onInputChange = (value?: string, type?: string) => {
    if (value === undefined) return;
    const [textValue, unit] = parseUnit(value);
    let newValue = textValue;
    if (props.units?.includes(unit)) newValue = `${newValue}${unit}`;
    inputVal.value = initValue(newValue);

    if (type === 'change') {
      emit('controlChange', inputVal.value);
    } else emit('controlOnChange', inputVal.value);
  };

  watch(
    () => props.value,
    (newValue, oldValue) => {
      if (newValue !== oldValue) {
        inputVal.value = initValue(props.value);
      }
    },
  );
  watch(inputVal, () => {
    sliderVal.value = parseFloat(inputVal.value);
  });

  onMounted(() => {
    inputVal.value = props.value?.toString();
  });

  return {
    inputVal,
    sliderVal,
    handleControlOnChange,
    handleControlChange,
    onInputChange,
  };
};
