<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.85767 12.6902C7.85767 13.7257 8.69713 14.5652 9.73267 14.5652L17.2327 14.5652C17.4518 14.5652 17.6622 14.5276 17.8577 14.4585L17.8577 18.0656C17.8577 18.4108 18.1375 18.6906 18.4827 18.6906C18.8278 18.6906 19.1077 18.4108 19.1077 18.0656L19.1077 12.6902L19.1077 7.19019L19.1077 1.8147C19.1077 1.46952 18.8278 1.1897 18.4827 1.1897C18.1375 1.1897 17.8577 1.46952 17.8577 1.8147L17.8577 5.42188C17.6622 5.35278 17.4518 5.31519 17.2327 5.31519L9.73267 5.31519C8.69713 5.31519 7.85767 6.15465 7.85767 7.19019L7.85767 12.6902ZM17.8577 7.19019C17.8577 6.84501 17.5778 6.56519 17.2327 6.56519L9.73267 6.56519C9.38749 6.56519 9.10767 6.84501 9.10767 7.19019L9.10767 12.6902C9.10767 13.0354 9.38749 13.3152 9.73267 13.3152L17.2327 13.3152C17.5778 13.3152 17.8577 13.0354 17.8577 12.6902L17.8577 7.19019Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'JustifyContentRight'
};
</script>
