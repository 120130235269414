<script setup lang="ts">
import { ComboSetting, Control, ElementSettingsGroup } from '@gem/element-setting-ui';
import type { SettingUIControl } from '@gem/element-setting-ui';
import { computed } from 'vue';
type ControlSettingsProps = {
  controls: SettingUIControl[];
  hiddenDevice?: boolean;
  isTopLevel?: boolean;
};

const props = defineProps<ControlSettingsProps>();

const controlsDisplay = computed(() => props.controls?.filter((item) => !item.hide));
</script>

<template>
  <div v-if="controls.length" class="flex flex-col gap-16">
    <template v-for="(control, index) in controlsDisplay" :key="`${index}`">
      <Control
        v-if="!control.type || control.type === 'control'"
        :data="control"
        :hidden-device="hiddenDevice"
        data-test="control-setting" />
      <ComboSetting v-if="control.type === 'combo'" :data="control" :is-parent="isTopLevel" :is-child="!isTopLevel" />
      <ElementSettingsGroup v-if="control.type === 'group'" :data="control" />
    </template>
  </div>
</template>
