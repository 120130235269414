import { useInfiniteScroll } from '@vueuse/core';
import { onMounted, ref, watch } from 'vue';
import { areEqualArrays } from '../helper';
import type { MultiProductPickerEmit, MultiProductPickerProps } from '../type';
import { useSetValueSelectedProducts } from './useSetValueSelected';
import { useProductVariantSelected } from '../../hooks/useProductVariantSelected';
export const useHandleDataMultiProductPicker = (emit: MultiProductPickerEmit, props: MultiProductPickerProps) => {
  // Handle show more
  const productInfinityScroll = ref<HTMLElement | null>(null);
  useInfiniteScroll(
    productInfinityScroll,
    () => {
      emit('showMore');
    },
    { distance: 100 },
  );

  const { productVariantSelect, handleDeleteVariant, handleAddVariant } = useProductVariantSelected({
    value: props.variantSelected,
  });

  const { listProduct, setValueSelected } = useSetValueSelectedProducts(props, handleAddVariant, handleDeleteVariant);

  const save = () => {
    emit('setValueSelected', listProduct.value);
    if (props.isPostPurchase) emit('setPreVariantSelected', productVariantSelect);
  };

  // Handle disable save
  const productIdsCloneDeep = ref<string[]>([]);
  onMounted(() => {
    const listProductIdsOriginal = (props?.value ?? []).map((item) => item.id);
    productIdsCloneDeep.value = [...listProductIdsOriginal];
  });

  const isDisabledSave = ref<boolean>(true);
  watch(listProduct.value, (listProduct) => {
    const listProductId = (listProduct ?? []).map((item) => item.id);
    isDisabledSave.value = areEqualArrays(productIdsCloneDeep.value, listProductId);
  });

  return {
    productInfinityScroll,
    setValueSelected,
    listProduct,
    save,
    isDisabledSave,
  };
};
