<template>
  <svg width="100%" height="100%" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 6C0 2.68629 2.68629 0 6 0H58C61.3137 0 64 2.68629 64 6V26C64 29.3137 61.3137 32 58 32H6C2.68629 32 0 29.3137 0 26V6Z" fill="#5E5E5E"/>
<circle cx="26" cy="12" r="2" fill="#F9F9F9"/>
<path d="M24.1969 20.6453L26.4097 18.0595C26.4846 17.9718 26.5772 17.9008 26.6814 17.8512C26.7856 17.8017 26.8991 17.7746 27.0145 17.7718C27.1299 17.769 27.2446 17.7905 27.3511 17.835C27.4576 17.8795 27.5535 17.9458 27.6326 18.0298L28.6673 19.1271L32.712 14.2946C32.7912 14.1999 32.8908 14.1243 33.0033 14.0733C33.1157 14.0224 33.2383 13.9974 33.3618 14.0002C33.4852 14.0031 33.6065 14.0337 33.7165 14.0898C33.8265 14.1459 33.9224 14.2261 33.9972 14.3243L38.8329 20.6772C38.9254 20.7989 38.982 20.9441 38.9964 21.0962C39.0107 21.2484 38.9823 21.4016 38.9144 21.5385C38.8464 21.6754 38.7415 21.7907 38.6115 21.8713C38.4815 21.952 38.3316 21.9948 38.1786 21.995L24.822 22C24.6649 21.9999 24.5111 21.9549 24.3789 21.8703C24.2466 21.7857 24.1413 21.6649 24.0755 21.5224C24.0098 21.3799 23.9862 21.2216 24.0077 21.0661C24.0292 20.9107 24.0949 20.7646 24.1969 20.6453Z" fill="#F9F9F9"/>
</svg>
</template>

<script>
export default {
  name: 'ImageRectangleStyle'
};
</script>
