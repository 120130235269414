<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" fill="#151515" />
    <rect x="8" width="64" height="26" rx="4" fill="#333333" />
    <rect x="12" y="10" width="26.006" height="2" rx="1" fill="#5E5E5E" />
    <rect x="12" y="14" width="12" height="2" rx="1" fill="#5E5E5E" />
    <path
      d="M55.8603 17.8108C56.4807 18.5124 55.9804 19.6177 55.0438 19.6145L47.1258 19.588C46.1931 19.5848 45.6988 18.4842 46.316 17.785L50.2597 13.3165C50.6914 12.8273 51.454 12.827 51.8861 13.3157L55.8603 17.8108Z"
      fill="#5E5E5E" />
    <path
      d="M66.4053 17.8032C67.0232 18.5062 66.5208 19.6093 65.5848 19.6046L52.0152 19.5359C51.0786 19.5311 50.5876 18.4216 51.2141 17.7252L58.0471 10.1294C58.4815 9.64655 59.2399 9.65092 59.6687 10.1388L66.4053 17.8032Z"
      fill="#5E5E5E" />
    <path
      d="M52.3178 9.50387C53.6918 9.3475 54.6486 8.14491 54.4923 6.77071C54.3359 5.39651 53.1334 4.43965 51.7594 4.59602C50.3853 4.75238 49.4285 5.95497 49.5849 7.32917C49.7412 8.70337 50.9437 9.66024 52.3178 9.50387Z"
      fill="#5E5E5E" />
    <rect x="8" y="30" width="30" height="26" rx="4" fill="#333333" />
    <rect x="16" y="36" width="6" height="6" rx="1" fill="#5E5E5E" />
    <path
      d="M16 45C16 44.4477 16.4477 44 17 44H21C21.5523 44 22 44.4477 22 45V49C22 49.5523 21.5523 50 21 50H17C16.4477 50 16 49.5523 16 49V45Z"
      fill="#5E5E5E" />
    <rect x="24" y="44" width="6" height="6" rx="1" fill="#5E5E5E" />
    <rect x="42" y="30" width="30" height="26" rx="4" fill="#333333" />
    <rect x="50" y="36" width="6" height="6" rx="1" fill="#5E5E5E" />
    <path
      d="M50 45C50 44.4477 50.4477 44 51 44H55C55.5523 44 56 44.4477 56 45V49C56 49.5523 55.5523 50 55 50H51C50.4477 50 50 49.5523 50 49V45Z"
      fill="#5E5E5E" />
    <rect x="58" y="44" width="6" height="6" rx="1" fill="#5E5E5E" />
    <path d="M0 32H80V72H0V32Z" fill="url(#paint0_linear_7600_58504)" />
    <rect x="52.5" y="44.5" width="27" height="27" rx="13.5" fill="#151515" />
    <rect x="52.5" y="44.5" width="27" height="27" rx="13.5" stroke="#212121" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M68.3226 61.3833C67.3996 62.0841 66.2484 62.5 65 62.5C61.9624 62.5 59.5 60.0376 59.5 57C59.5 53.9624 61.9624 51.5 65 51.5C68.0376 51.5 70.5 53.9624 70.5 57C70.5 58.2484 70.0841 59.3996 69.3833 60.3226L72.2803 63.2197C72.5732 63.5126 72.5732 63.9874 72.2803 64.2803C71.9874 64.5732 71.5126 64.5732 71.2197 64.2803L68.3226 61.3833ZM69 57C69 59.2091 67.2091 61 65 61C62.7909 61 61 59.2091 61 57C61 54.7909 62.7909 53 65 53C67.2091 53 69 54.7909 69 57Z"
      fill="#AAAAAA" />
    <defs>
      <linearGradient id="paint0_linear_7600_58504" x1="40" y1="32" x2="40" y2="69.25" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="0.0001" stop-color="#151515" stop-opacity="0" />
        <stop offset="1" stop-color="#151515" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'NoResult',
};
</script>
