<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0016 2.74805L5.00159 2.74805L5.00159 17.2512L15.0016 17.2512L15.0016 2.74805ZM16.5016 2.49805C16.5016 1.80769 15.9419 1.24805 15.2516 1.24805L4.75159 1.24805C4.06123 1.24805 3.50159 1.80769 3.50159 2.49805L3.50159 17.5012C3.50159 18.1916 4.06123 18.7512 4.75159 18.7512L15.2516 18.7512C15.9419 18.7512 16.5016 18.1916 16.5016 17.5012L16.5016 2.49805Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'ImageShapeVertical',
};
</script>
