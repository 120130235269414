import { computed } from 'vue';
import type { ItemManagementEmits, ItemManagementProps } from '../types';
import { useCreateItemManagementContext } from './context/useCreateItemManagementContext';

export const useItemManagement = (props: ItemManagementProps, emit: ItemManagementEmits) => {
  const context = useCreateItemManagementContext(props, emit);

  const { items } = context.state;
  const { handleReorderItem, handleAppendItem } = context.actions;

  const inputComboLayoutContent = computed(() => {
    if (items.value.length === 1) {
      return '1 Item';
    }

    return `${items.value.length} Items`;
  });

  return {
    items,
    inputComboLayoutContent,
    handleAppendItem,
    handleReorderItem,
  };
};
