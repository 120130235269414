<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2222 5.8445C17.2222 6.30474 16.8491 6.67783 16.3889 6.67783C15.9287 6.67783 15.5556 6.30474 15.5556 5.8445V5.27783C15.5556 4.8176 15.1825 4.4445 14.7222 4.4445L5.27778 4.4445C4.81754 4.4445 4.44445 4.8176 4.44445 5.27783V5.8445C4.44445 6.30474 4.07135 6.67783 3.61111 6.67783C3.15088 6.67783 2.77778 6.30474 2.77778 5.8445V5.27783C2.77778 3.89712 3.89707 2.77783 5.27778 2.77783H14.7222C16.1029 2.77783 17.2222 3.89712 17.2222 5.27783V5.8445Z"
      fill="currentColor" />
    <path
      d="M10.8333 16.6778C10.8333 17.1381 10.4602 17.5112 10 17.5112C9.53976 17.5112 9.16667 17.1381 9.16667 16.6778V9.24523L7.25592 11.156C6.93049 11.4814 6.40285 11.4814 6.07741 11.156C5.75198 10.8305 5.75198 10.3029 6.07741 9.97747L9.41075 6.64413C9.73618 6.3187 10.2638 6.3187 10.5893 6.64413L13.9226 9.97747C14.248 10.3029 14.248 10.8305 13.9226 11.156C13.5972 11.4814 13.0695 11.4814 12.7441 11.156L10.8333 9.24523V16.6778Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'Upload',
};
</script>
