import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type SalePageProductCreateMutationVariables = Types.Exact<{
  input: Types.CreateSalePageProductInput;
}>;

export type SalePageProductCreateMutationResponse = {
  salePageProductCreate?: Types.Maybe<Pick<Types.SalePageProduct, 'id' | 'baseIDs'>>;
};

export const SalePageProductCreateDocument = `
    mutation SalePageProductCreate($input: CreateSalePageProductInput!) {
  salePageProductCreate(input: $input) {
    id
    baseIDs
  }
}
    `;
export const useSalePageProductCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SalePageProductCreateMutationResponse,
    TError,
    SalePageProductCreateMutationVariables,
    TContext
  >,
) =>
  useMutation<SalePageProductCreateMutationResponse, TError, SalePageProductCreateMutationVariables, TContext>(
    ['SalePageProductCreate'],
    (
      variables:
        | SalePageProductCreateMutationVariables
        | ComputedRef<SalePageProductCreateMutationVariables>
        | Ref<SalePageProductCreateMutationVariables>,
    ) =>
      appFetcher<SalePageProductCreateMutationResponse, SalePageProductCreateMutationVariables>(
        SalePageProductCreateDocument,
        variables,
      )(),
    options,
  );
useSalePageProductCreateMutation.getKey = () => ['SalePageProductCreate'];
