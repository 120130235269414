import gpAlignPositionCenterIcon from './svgs/AlignPositionCenter.vue';
import gpAlignPositionTopIcon from './svgs/AlignPositionTop.vue';
import gpCarouselDotInsideIcon from './svgs/CarouselDotInside.vue';
import gpCarouselDotOutsideIcon from './svgs/CarouselDotOutside.vue';
import gpCheckboxIcon from './svgs/Checkbox.vue';
import gpCheckmarkIcon from './svgs/Checkmark.vue';
import gpHeroBannerCenterIcon from './svgs/HeroBannerCenter.vue';
import gpHeroBannerCenterActiveIcon from './svgs/HeroBannerCenterActive.vue';
import gpHeroBannerLeftIcon from './svgs/HeroBannerLeft.vue';
import gpHeroBannerLeftActiveIcon from './svgs/HeroBannerLeftActive.vue';
import gpHeroBannerRightIcon from './svgs/HeroBannerRight.vue';
import gpHeroBannerRightActiveIcon from './svgs/HeroBannerRightActive.vue';
import gpInputCheckboxIcon from './svgs/InputCheckbox.vue';
import gpInputDropdownIcon from './svgs/InputDropdown.vue';
import gpInputEmailIcon from './svgs/InputEmail.vue';
import gpInputHiddenFieldIcon from './svgs/InputHiddenField.vue';
import gpInputRadioButtonIcon from './svgs/InputRadioButton.vue';
import gpInputTextIcon from './svgs/InputText.vue';
import gpInputTextAreaIcon from './svgs/InputTextArea.vue';
import gpImageCircleStyleIcon from './svgs/ImageCircleStyle.vue';
import gpImageComparisonHorizontalIcon from './svgs/ImageComparisonHorizontal.vue';
import gpImageComparisonVerticalIcon from './svgs/ImageComparisonVertical.vue';
import gpImageContainIcon from './svgs/ImageContain.vue';
import gpImageCoverIcon from './svgs/ImageCover.vue';
import gpImageRectangleStyleIcon from './svgs/ImageRectangleStyle.vue';
import gpLinkButtonIcon from './svgs/LinkButton.vue';
import gpNoResultIcon from './svgs/NoResult.vue';
import gpPostPurchaseBlackSquareIcon from './svgs/PostPurchaseBlackSquare.vue';
import gpPostPurchaseGreenSquareIcon from './svgs/PostPurchaseGreenSquare.vue';
import gpPostPurchaseRedSquareIcon from './svgs/PostPurchaseRedSquare.vue';
import gpPostPurchaseYellowSquareIcon from './svgs/PostPurchaseYellowSquare.vue';
import gpProductButtonDotSeparatorIcon from './svgs/ProductButtonDotSeparator.vue';
import gpProductButtonHorizontalLineSeparatorIcon from './svgs/ProductButtonHorizontalLineSeparator.vue';
import gpProductButtonVerticalLineSeparatorIcon from './svgs/ProductButtonVerticalLineSeparator.vue';
import gpProductImageLayoutBottomIcon from './svgs/ProductImageLayoutBottom.vue';
import gpProductImageLayoutBottomInsideIcon from './svgs/ProductImageLayoutBottomInside.vue';
import gpProductImageLayoutFeaturedOnlyIcon from './svgs/ProductImageLayoutFeaturedOnly.vue';
import gpProductImageLayoutLeftIcon from './svgs/ProductImageLayoutLeft.vue';
import gpProductImageLayoutLeftInsideIcon from './svgs/ProductImageLayoutLeftInside.vue';
import gpProductImageLayoutOneColIcon from './svgs/ProductImageLayoutOneCol.vue';
import gpProductImageLayoutRightIcon from './svgs/ProductImageLayoutRight.vue';
import gpProductImageLayoutRightInsideIcon from './svgs/ProductImageLayoutRightInside.vue';
import gpProductImageLayoutTwoColIcon from './svgs/ProductImageLayoutTwoCol.vue';
import gpProductImagePlaceholderIcon from './svgs/ProductImagePlaceholder.vue';
import gpProductLayoutHorizontalIcon from './svgs/ProductLayoutHorizontal.vue';
import gpProductLayoutVerticalIcon from './svgs/ProductLayoutVertical.vue';
import gpSecondaryButtonIcon from './svgs/SecondaryButton.vue';
import gpSlideControlInsideIcon from './svgs/SlideControlInside.vue';
import gpSlideControlOutsideIcon from './svgs/SlideControlOutside.vue';
import gpStatusErrorFilledIcon from './svgs/StatusErrorFilled.vue';

export const icons = {
  'gp-align-position-center': gpAlignPositionCenterIcon,
  'gp-align-position-top': gpAlignPositionTopIcon,
  'gp-carousel-dot-inside': gpCarouselDotInsideIcon,
  'gp-carousel-dot-outside': gpCarouselDotOutsideIcon,
  'gp-checkbox': gpCheckboxIcon,
  'gp-checkmark': gpCheckmarkIcon,
  'gp-hero-banner-center': gpHeroBannerCenterIcon,
  'gp-hero-banner-center-active': gpHeroBannerCenterActiveIcon,
  'gp-hero-banner-left': gpHeroBannerLeftIcon,
  'gp-hero-banner-left-active': gpHeroBannerLeftActiveIcon,
  'gp-hero-banner-right': gpHeroBannerRightIcon,
  'gp-hero-banner-right-active': gpHeroBannerRightActiveIcon,
  'gp-input-checkbox': gpInputCheckboxIcon,
  'gp-input-dropdown': gpInputDropdownIcon,
  'gp-input-email': gpInputEmailIcon,
  'gp-input-hidden-field': gpInputHiddenFieldIcon,
  'gp-input-radio-button': gpInputRadioButtonIcon,
  'gp-input-text': gpInputTextIcon,
  'gp-input-text-area': gpInputTextAreaIcon,
  'gp-image-circle-style': gpImageCircleStyleIcon,
  'gp-image-comparison-horizontal': gpImageComparisonHorizontalIcon,
  'gp-image-comparison-vertical': gpImageComparisonVerticalIcon,
  'gp-image-contain': gpImageContainIcon,
  'gp-image-cover': gpImageCoverIcon,
  'gp-image-rectangle-style': gpImageRectangleStyleIcon,
  'gp-link-button': gpLinkButtonIcon,
  'gp-no-result': gpNoResultIcon,
  'gp-post-purchase-black-square': gpPostPurchaseBlackSquareIcon,
  'gp-post-purchase-green-square': gpPostPurchaseGreenSquareIcon,
  'gp-post-purchase-red-square': gpPostPurchaseRedSquareIcon,
  'gp-post-purchase-yellow-square': gpPostPurchaseYellowSquareIcon,
  'gp-product-button-dot-separator': gpProductButtonDotSeparatorIcon,
  'gp-product-button-horizontal-line-separator': gpProductButtonHorizontalLineSeparatorIcon,
  'gp-product-button-vertical-line-separator': gpProductButtonVerticalLineSeparatorIcon,
  'gp-product-image-layout-bottom': gpProductImageLayoutBottomIcon,
  'gp-product-image-layout-bottom-inside': gpProductImageLayoutBottomInsideIcon,
  'gp-product-image-layout-featured-only': gpProductImageLayoutFeaturedOnlyIcon,
  'gp-product-image-layout-left': gpProductImageLayoutLeftIcon,
  'gp-product-image-layout-left-inside': gpProductImageLayoutLeftInsideIcon,
  'gp-product-image-layout-one-col': gpProductImageLayoutOneColIcon,
  'gp-product-image-layout-right': gpProductImageLayoutRightIcon,
  'gp-product-image-layout-right-inside': gpProductImageLayoutRightInsideIcon,
  'gp-product-image-layout-two-col': gpProductImageLayoutTwoColIcon,
  'gp-product-image-placeholder': gpProductImagePlaceholderIcon,
  'gp-product-layout-horizontal': gpProductLayoutHorizontalIcon,
  'gp-product-layout-vertical': gpProductLayoutVerticalIcon,
  'gp-secondary-button': gpSecondaryButtonIcon,
  'gp-slide-control-inside': gpSlideControlInsideIcon,
  'gp-slide-control-outside': gpSlideControlOutsideIcon,
  'gp-status-error-filled': gpStatusErrorFilledIcon,
};
