import { computed, ref } from 'vue';
import type { PickMultiProductEmit, PickMultiProductProps } from '../type';
import type { PreVariantSelected, Product } from '../../modal/types';
import { isDefined } from '../../../utils/common';
import { FIRST_VIEW_ITEM } from '../constant';

export const useHandlePickMultiProduct = (props: PickMultiProductProps, emit: PickMultiProductEmit) => {
  const productListVisibility = ref<boolean>(false);
  const end = ref(FIRST_VIEW_ITEM);
  const searchValue = ref('');

  const isSalePage = computed(() => {
    return props.pageType === 'GP_FUNNEL_PAGE' && props.tag === 'Product';
  });

  const listSortable = computed(() => {
    if (!props.isPostPurchase) return props.productList;
    const idsSelected = list.value.map((el) => el.id);
    return [
      ...list.value.filter((el) => el.title?.toLowerCase().includes(searchValue.value.toLowerCase())),
      ...(props?.productList || []).filter((el) => !idsSelected.includes(el.id)),
    ];
  });

  const closeModal = () => {
    productListVisibility.value = false;
  };

  const openModal = () => {
    productListVisibility.value = true;
  };

  const setValueSelected = (productList?: Product[]) => {
    emit('controlChange', 'productIds', productList || []);
  };

  const setPreVariantSelected = (value?: PreVariantSelected) => {
    emit('changeVariantSelected', props.id, value);
  };

  const onSearch = (value?: string) => {
    emit('controlOnSearch', props.id, value);
    searchValue.value = value ?? '';
  };

  const cloneList = computed(() => {
    return props.valueProductIds?.map((id) => props.productDisplay?.find((el) => el.id === id)).filter(isDefined);
  });

  const list = computed(() => {
    if (props.valueProductIds && props.valueProductIds.length == 0) {
      return [];
    }
    if (cloneList.value?.length === 0) {
      return [];
    } else if (cloneList.value?.length ?? 0 > end.value) {
      return [...(cloneList.value?.slice(0, end.value) ?? [])].map((v) => ({
        id: v?.id,
        title: v?.title,
        image: v?.image,
        handle: v?.handle,
        options: v?.options,
        variants: v?.variants,
        status: v?.status,
      }));
    } else return [];
  });

  const collectionSelected = computed(() => {
    return (props.collectionList ?? []).find((item) => item?.id === props.valueCollection);
  });

  const handleSelectPickerType = (value: string) => {
    emit('select-picker-type', value);
  };

  // Collection
  const setValueSelectedCollection = (value?: string) => {
    if (value) {
      emit('controlChangeCollection', value);
    }
  };

  const controlChangeRelated = (value: any) => emit('controlChangeRelated', value);
  return {
    isSalePage,
    listSortable,
    closeModal,
    openModal,
    setValueSelected,
    setPreVariantSelected,
    onSearch,
    collectionSelected,
    handleSelectPickerType,
    setValueSelectedCollection,
    controlChangeRelated,
    list,
    productListVisibility,
    cloneList,
  };
};
