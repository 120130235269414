<template>
  <svg width="100%" height="100%" viewBox="0 0 50 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_23922_81011)">
    <circle cx="6" cy="12" r="5.5" stroke="#F9F9F9"/>
    <circle cx="6" cy="12" r="3.5" fill="#F9F9F9"/>
    <rect x="14" width="36" height="6" rx="2" fill="#5E5E5E"/>
    <rect x="14" y="9" width="36" height="6" rx="2" fill="#5E5E5E"/>
    <rect x="14" y="18" width="36" height="6" rx="2" fill="#5E5E5E"/>
  </g>
  <defs>
    <clipPath id="clip0_23922_81011">
      <rect width="50" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {
  name: 'AlignPositionCenter'
};
</script>
